import React, {useEffect, useState, useRef} from "react";
import axios from "axios";
import $ from "jquery";
import DataTable from "datatables.net";
import {Link} from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import {useHistory} from 'react-router';
import {toAbsoluteUrl} from "../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import alertify from "alertifyjs";
import {date_format, datetime_format} from "../Common/DateFormat";
import {API_URL, BACKEND_URL} from '../constants.js';
import PrintIcon from '@material-ui/icons/Print';
import DescriptionIcon from '@material-ui/icons/Description';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import EmailIcon from '@material-ui/icons/Email';
import BeachAccessIcon from '@material-ui/icons/BeachAccess';
import EditIcon from '@material-ui/icons/Edit';

import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import {useTranslation} from "react-i18next";

export function UnregisteredSamples(props) {
  const {t} = useTranslation();
  const [samples, setSamples] = useState([]);
  const [patient, setPatient] = useState(null);
  const [show, setShow] = useState(false);
  const [action, setAction] = useState('patient');
  const history = useHistory();
  const [disabled, setDisabled] = useState(0);
  const [checkedItems, setCheckedItems] = useState({});

  const setCurrentPatient = (patient_id, action) => {
    setPatient(patient_id);

    if (action == 'mail') {
      setDisabled(1);
      axios.post(
        API_URL + 'patient/send-mail',
        {id: patient_id}
      ).then((response) => {
        setDisabled(0);
        if (response.data.status) {
          alertify.success(response.data.message);
        } else {
          alertify.error(response.data.message);
          return null;
        }
      });
    }
  };

  const handleClose = () => setShow(false);


  useEffect(() => {

    axios.get(API_URL + 'patient/get-unregistered-samples').then((response) => {
      if (response.data.status) {

        setSamples(response.data.data);

        $("#samples_table").DataTable();
      } else {
        alertify.error(response.data.message);
        return null;
      }
    })
      .catch((error) => {

        if (error.message === 'Request failed with status code 401') {
          //props.logout();
        }
      });

  }, []);

  const handleCheckboxChange = (event, id) => {
    console.log(id);
    setCheckedItems({
      ...checkedItems,
      [id]: event.target.checked
    });
    console.log(checkedItems);
  };

  useEffect(() => {
    console.log("items: ", checkedItems);
  }, [checkedItems])

  const deleteUnregisteredSamples = () => {
    setDisabled(1);
    axios.post(
      API_URL + 'patient/delete-unregistered-samples',
      {sample_ids: checkedItems}
    ).then((response) => {
      setDisabled(0);
      if (response.data.status) {
        alertify.success(response.data.message);
        window.location.reload();
      } else {
        alertify.error(response.data.message);
        return null;
      }
    });
  }

  const toggleSelectAllPatients = (event) => {
    let selected_samples = {};
    for (let p in samples) {
      selected_samples[samples[p].sample_id] = event.target.checked;
    }

    console.log(selected_samples);

    setCheckedItems(selected_samples);
  }

  return (
    <>
      <Row>
        <Col md={12}>
          <Card>
            <CardHeader title={t("Un Registered Samples")}>
              <CardHeaderToolbar>
                {<Button variant="danger" onClick={deleteUnregisteredSamples} disabled={disabled}>
                  Delete
                  <span
                    dangerouslySetInnerHTML={{__html: disabled ? `<i class='fas fa-spinner fa-spin'></i>` : ``}}/>
                </Button>}
              </CardHeaderToolbar>
            </CardHeader>
            <CardBody style={{overflow: 'auto'}}>
              <table id="samples_table"
                     className="stratprop_datatable table table-striped table-bordered">
                <thead>
                <tr key={'thead'}>
                  <th style={{width: '10px'}}>
                    <input type="checkbox"
                           onChange={(e) => {
                             toggleSelectAllPatients(e)
                           }}/>
                  </th>
                  <th style={{width: '10px'}}>{t("SN.")}</th>
                  <th style={{width: '100px'}}>Actions</th>
                  <th>{t("Sample ID")}</th>
                  <th>{t("Result")}</th>
                </tr>
                </thead>
                <tbody>
                {samples.length > 0 ? (
                  samples.map((sample, index) => (
                    <tr key={index}>
                      <td>
                        <Form.Check
                          key={sample.id}
                          type={'checkbox'}
                          id={`checkbox-${index}`}
                          checked={checkedItems[sample.sample_id] || false}
                          onChange={(e) => {
                            handleCheckboxChange(e, sample.sample_id)
                          }}
                          value={""}
                        />

                      </td>
                      <td>{index + 1}</td>
                      <td>
                        <div>
                          <Link to={`/patient/create/?sample_id=${sample.sample_id}`}
                                className="btn-icon btn-light btn-hover-primary mx-3"
                                title={'Edit Patient'}>
                            <EditIcon color={'primary'}></EditIcon>
                          </Link>
                        </div>
                      </td>
                      <td>{sample.sample_id}</td>
                      <td>
                        <ul>
                          {
                            sample.tests.length > 0 ? (
                              sample.tests.map((row, index) => (
                                <li><strong>{row.test}</strong> : {row.result}</li>
                              ))
                            ) : (
                              ''
                            )
                          }

                        </ul>
                      </td>


                    </tr>
                  ))
                ) : (
                  <tr key={"nopatient"}>
                    <td colSpan={13}>{samples.length}</td>
                  </tr>
                )}
                </tbody>
              </table>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  )
}

