/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import {useLocation} from "react-router";
import {NavLink} from "react-router-dom";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl, checkIsActive} from "../../../../_helpers";
import {makeStyles} from '@material-ui/core/styles';
import DirectionsBusIcon from '@material-ui/icons/DirectionsBus';
import ExtensionIcon from '@material-ui/icons/Extension';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import AssignmentIcon from '@material-ui/icons/Assignment';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications'; // Item Setting
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart'; //Purchase

import GroupAddIcon from '@material-ui/icons/GroupAdd'; //Supplier
import LabelIcon from '@material-ui/icons/Label'; //Item Unit
import LabelImportantIcon from '@material-ui/icons/LabelImportant'; //Item Type
import BlurLinearIcon from '@material-ui/icons/BlurLinear'; // Item Consumption
import BlurOffIcon from '@material-ui/icons/BlurOff'; //Item Wastage
import {check_role} from '../../../../../app/pages/Common/RoleMenuCheck';


const useStyles = makeStyles(theme => ({
    root: {
        color: theme.palette.text.primary,
    },
    icon: {
        margin: theme.spacing(1),
        fontSize: 32,
    },
}));

export function AsideMenuInventoryList({layoutProps}) {
    const classes = useStyles();
    const location = useLocation();
    const getMenuItemActive = (url, hasSubmenu = false) => {
        return checkIsActive(location, url)
            ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
            : "";
    };

    return (
        <>
            {/* begin::Menu Nav */}
            <ul className={`menu-nav ${layoutProps.ulClasses}`}>


                {/*Inventory Setup */}

                <li className="menu-section ">
                    <h4 className="menu-text">Inventory Setup</h4>
                    <i className="menu-icon flaticon-more-v2"></i>
                </li>
                {/*begin::1 Level*/}
                {check_role(['supplier/create', 'supplier/list']) ? (
                    <li
                        className={`menu-item menu-item-submenu ${getMenuItemActive(
                            "/supplier", true
                        )}`}
                        aria-haspopup="true"
                        data-menu-toggle="hover"
                    >
                        <NavLink className="menu-link menu-toggle" to="/supplier">
                    <span className="svg-icon svg-icon-lg">
                          <GroupAddIcon className={classes.icon}/>
                     </span>
                            <span className="menu-text">Supplier</span>
                            <i className="menu-arrow"/>
                        </NavLink>
                        <div className="menu-submenu ">
                            <i className="menu-arrow"/>
                            <ul className="menu-subnav">
                                <li className="menu-item  menu-item-parent" aria-haspopup="true">
                            <span className="menu-link">
                              <span className="menu-text">Supplier</span>
                            </span>
                                </li>

                                {/*begin::2 Level*/}
                                {check_role(['supplier/create']) ? (
                                    <li
                                        className={`menu-item ${getMenuItemActive("/supplier/create")}`}
                                        aria-haspopup="true"
                                    >
                                        <NavLink className="menu-link" to="/supplier/create">
                                            <i className="menu-bullet menu-bullet-dot">
                                                <span/>
                                            </i>
                                            <span className="menu-text">Add New</span>
                                        </NavLink>
                                    </li>
                                ) : ''}
                                {/*end::2 Level*/}

                                {/*begin::2 Level*/}
                                {check_role(['supplier/list']) ? (
                                    <li
                                        className={`menu-item ${getMenuItemActive("/supplier/list")}`}
                                        aria-haspopup="true"
                                    >
                                        <NavLink className="menu-link" to="/supplier/list">
                                            <i className="menu-bullet menu-bullet-dot">
                                                <span/>
                                            </i>
                                            <span className="menu-text">List</span>
                                        </NavLink>
                                    </li>
                                ) : ''}
                                {/*end::2 Level*/}

                            </ul>
                        </div>
                    </li>
                ) : ''}
                {/*end::1 Level*/}

                {/*begin::1 Level*/}
                {check_role(['item-type/create', 'item-type/list']) ? (
                    <li
                        className={`menu-item menu-item-submenu ${getMenuItemActive(
                            "/item-type", true
                        )}`}
                        aria-haspopup="true"
                        data-menu-toggle="hover"
                    >
                        <NavLink className="menu-link menu-toggle" to="/item-type">
                    <span className="svg-icon svg-icon-lg">
                          <LabelImportantIcon className={classes.icon}/>
                     </span>
                            <span className="menu-text">Item Type</span>
                            <i className="menu-arrow"/>
                        </NavLink>
                        <div className="menu-submenu ">
                            <i className="menu-arrow"/>
                            <ul className="menu-subnav">
                                <li className="menu-item  menu-item-parent" aria-haspopup="true">
                            <span className="menu-link">
                              <span className="menu-text">Item Type</span>
                            </span>
                                </li>

                                {/*begin::2 Level*/}
                                {check_role(['item-type/create']) ? (
                                    <li
                                        className={`menu-item ${getMenuItemActive("/item-type/create")}`}
                                        aria-haspopup="true"
                                    >
                                        <NavLink className="menu-link" to="/item-type/create">
                                            <i className="menu-bullet menu-bullet-dot">
                                                <span/>
                                            </i>
                                            <span className="menu-text">Add New</span>
                                        </NavLink>
                                    </li>
                                ) : ''}
                                {/*end::2 Level*/}

                                {/*begin::2 Level*/}
                                {check_role(['item-type/list']) ? (
                                    <li
                                        className={`menu-item ${getMenuItemActive("/item-type/list")}`}
                                        aria-haspopup="true"
                                    >
                                        <NavLink className="menu-link" to="/item-type/list">
                                            <i className="menu-bullet menu-bullet-dot">
                                                <span/>
                                            </i>
                                            <span className="menu-text">List</span>
                                        </NavLink>
                                    </li>
                                ) : ''}
                                {/*end::2 Level*/}

                            </ul>
                        </div>
                    </li>
                ) : ''}
                {/*end::1 Level*/}
                {/*begin::1 Level*/}
                {check_role(['item-unit/create', 'item-unit/list']) ? (
                    <li
                        className={`menu-item menu-item-submenu ${getMenuItemActive(
                            "/item-unit", true
                        )}`}
                        aria-haspopup="true"
                        data-menu-toggle="hover"
                    >
                        <NavLink className="menu-link menu-toggle" to="/item-unit">
                    <span className="svg-icon svg-icon-lg">
                          <LabelIcon className={classes.icon}/>
                     </span>
                            <span className="menu-text">Item Unit</span>
                            <i className="menu-arrow"/>
                        </NavLink>
                        <div className="menu-submenu ">
                            <i className="menu-arrow"/>
                            <ul className="menu-subnav">
                                <li className="menu-item  menu-item-parent" aria-haspopup="true">
                            <span className="menu-link">
                              <span className="menu-text">Item Unit</span>
                            </span>
                                </li>

                                {/*begin::2 Level*/}
                                {check_role(['item-unit/create']) ? (
                                    <li
                                        className={`menu-item ${getMenuItemActive("/item-unit/create")}`}
                                        aria-haspopup="true"
                                    >
                                        <NavLink className="menu-link" to="/item-unit/create">
                                            <i className="menu-bullet menu-bullet-dot">
                                                <span/>
                                            </i>
                                            <span className="menu-text">Add New</span>
                                        </NavLink>
                                    </li>
                                ) : ''}
                                {/*end::2 Level*/}

                                {/*begin::2 Level*/}
                                {check_role(['item-unit/list']) ? (
                                    <li
                                        className={`menu-item ${getMenuItemActive("/item-unit/list")}`}
                                        aria-haspopup="true"
                                    >
                                        <NavLink className="menu-link" to="/item-unit/list">
                                            <i className="menu-bullet menu-bullet-dot">
                                                <span/>
                                            </i>
                                            <span className="menu-text">List</span>
                                        </NavLink>
                                    </li>
                                ) : ''}
                                {/*end::2 Level*/}

                            </ul>
                        </div>
                    </li>
                ) : ''}
                {/*end::1 Level*/}
                {/* Inventory Management */}
                {/* begin::section */}

                <li className="menu-section ">
                    <h4 className="menu-text">Inventory Management</h4>
                    <i className="menu-icon flaticon-more-v2"></i>
                </li>
                {/* end:: section */}
                {/*begin::1 Level*/}
                {check_role(['item/create', 'item/list']) ? (
                    <li
                        className={`menu-item menu-item-submenu ${getMenuItemActive(
                            "/item", true
                        )}`}
                        aria-haspopup="true"
                        data-menu-toggle="hover"
                    >
                        <NavLink className="menu-link menu-toggle" to="/item">
            <span className="svg-icon menu-icon">
              <SettingsApplicationsIcon className={classes.icon}/>
            </span>
                            <span className="menu-text">Item Related</span>
                            <i className="menu-arrow"/>
                        </NavLink>
                        <div className="menu-submenu ">
                            <i className="menu-arrow"/>
                            <ul className="menu-subnav">
                                <li className="menu-item  menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">Item Related</span>
                </span>
                                </li>

                                {/*begin::2 Level*/}
                                {check_role(['item/create']) ? (
                                    <li
                                        className={`menu-item ${getMenuItemActive("/item/create")}`}
                                        aria-haspopup="true"
                                    >
                                        <NavLink className="menu-link" to="/item/create">
                                            <i className="menu-bullet menu-bullet-dot">
                                                <span/>
                                            </i>
                                            <span className="menu-text">Add New</span>
                                        </NavLink>
                                    </li>
                                ) : ''}
                                {/*end::2 Level*/}

                                {/*begin::2 Level*/}
                                {check_role(['item/list']) ? (
                                    <li
                                        className={`menu-item ${getMenuItemActive("/item/list")}`}
                                        aria-haspopup="true"
                                    >
                                        <NavLink className="menu-link" to="/item/list">
                                            <i className="menu-bullet menu-bullet-dot">
                                                <span/>
                                            </i>
                                            <span className="menu-text">List</span>
                                        </NavLink>
                                    </li>
                                ) : ''}
                                {/*end::2 Level*/}

                            </ul>
                        </div>
                    </li>
                ) : ''}
                {/*end::1 Level*/}


                {/* Bus Facilities Pages */}
                {/*begin::1 Level*/}
                {check_role(['purchase/create', 'purchase/list']) ? (
                    <li
                        className={`menu-item menu-item-submenu ${getMenuItemActive(
                            "/purchase", true
                        )}`}
                        aria-haspopup="true"
                        data-menu-toggle="hover"
                    >
                        <NavLink className="menu-link menu-toggle" to="/purchase">
            <span className="svg-icon menu-icon">
              <AddShoppingCartIcon className={classes.icon}/>
            </span>
                            <span className="menu-text">Purchase Related</span>
                            <i className="menu-arrow"/>
                        </NavLink>
                        <div className="menu-submenu ">
                            <i className="menu-arrow"/>
                            <ul className="menu-subnav">
                                <li className="menu-item  menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">Purchase Related</span>
                </span>
                                </li>

                                {/*begin::2 Level*/}
                                {check_role(['purchase/create']) ? (
                                    <li
                                        className={`menu-item ${getMenuItemActive("/purchase/create")}`}
                                        aria-haspopup="true"
                                    >
                                        <NavLink className="menu-link" to="/purchase/create">
                                            <i className="menu-bullet menu-bullet-dot">
                                                <span/>
                                            </i>
                                            <span className="menu-text">Add New</span>
                                        </NavLink>
                                    </li>
                                ) : ''}
                                {/*end::2 Level*/}

                                {/*begin::2 Level*/}
                                {check_role(['purchase/list']) ? (
                                    <li
                                        className={`menu-item ${getMenuItemActive("/purchase/list")}`}
                                        aria-haspopup="true"
                                    >
                                        <NavLink className="menu-link" to="/purchase/list">
                                            <i className="menu-bullet menu-bullet-dot">
                                                <span/>
                                            </i>
                                            <span className="menu-text">List</span>
                                        </NavLink>
                                    </li>
                                ) : ''}
                                {/*end::2 Level*/}

                            </ul>
                        </div>
                    </li>
                ) : ''}
                {/*end::1 Level*/}

                {/* begin::section */}
                <li className="menu-section ">
                    <h4 className="menu-text">Item Usage</h4>
                    <i className="menu-icon flaticon-more-v2"></i>
                </li>
                {/* end:: section */}
                {/*begin::1 Level*/}
                {check_role(['item-consumption/create/consumption', 'item-consumption/list/consumption']) ? (
                    <li
                        className={`menu-item menu-item-submenu ${getMenuItemActive(
                            "/item-consumption/consumption", true
                        )}`}
                        aria-haspopup="true"
                        data-menu-toggle="hover"
                    >
                        <NavLink className="menu-link menu-toggle" to="/item-consumption/consumption">
            <span className="svg-icon menu-icon">
              <BlurLinearIcon className={classes.icon}/>
            </span>
                            <span className="menu-text">Consumption</span>
                            <i className="menu-arrow"/>
                        </NavLink>
                        <div className="menu-submenu ">
                            <i className="menu-arrow"/>
                            <ul className="menu-subnav">
                                <li className="menu-item  menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">Consumption</span>
                </span>
                                </li>

                                {/*begin::2 Level*/}
                                {check_role(['item-consumption/create/consumption']) ? (
                                    <li
                                        className={`menu-item ${getMenuItemActive("/item-consumption/create/consumption")}`}
                                        aria-haspopup="true"
                                    >
                                        <NavLink className="menu-link" to="/item-consumption/create/consumption">
                                            <i className="menu-bullet menu-bullet-dot">
                                                <span/>
                                            </i>
                                            <span className="menu-text">Add New</span>
                                        </NavLink>
                                    </li>
                                ) : ''}
                                {/*end::2 Level*/}

                                {/*begin::2 Level*/}
                                {check_role(['item-consumption/list/consumption']) ? (
                                    <li
                                        className={`menu-item ${getMenuItemActive("/item-consumption/list/consumption")}`}
                                        aria-haspopup="true"
                                    >
                                        <NavLink className="menu-link" to="/item-consumption/list/consumption">
                                            <i className="menu-bullet menu-bullet-dot">
                                                <span/>
                                            </i>
                                            <span className="menu-text">List</span>
                                        </NavLink>
                                    </li>
                                ) : ''}
                                {/*end::2 Level*/}

                            </ul>
                        </div>
                    </li>
                ) : ''}
                {/*end::1 Level*/}
                {/*begin::1 Level*/}
                {check_role(['item-consumption/create/waste', 'item-consumption/list/waste']) ? (
                    <li
                        className={`menu-item menu-item-submenu ${getMenuItemActive(
                            "/item-consumption/waste", true
                        )}`}
                        aria-haspopup="true"
                        data-menu-toggle="hover"
                    >
                        <NavLink className="menu-link menu-toggle" to="/item-consumption/waste">
            <span className="svg-icon menu-icon">
              <BlurOffIcon className={classes.icon}/>
            </span>
                            <span className="menu-text">Waste</span>
                            <i className="menu-arrow"/>
                        </NavLink>
                        <div className="menu-submenu ">
                            <i className="menu-arrow"/>
                            <ul className="menu-subnav">
                                <li className="menu-item  menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">Waste</span>
                </span>
                                </li>

                                {/*begin::2 Level*/}
                                {check_role(['item-consumption/create/waste']) ? (
                                    <li
                                        className={`menu-item ${getMenuItemActive("/item-consumption/create/waste")}`}
                                        aria-haspopup="true"
                                    >
                                        <NavLink className="menu-link" to="/item-consumption/create/waste">
                                            <i className="menu-bullet menu-bullet-dot">
                                                <span/>
                                            </i>
                                            <span className="menu-text">Add New</span>
                                        </NavLink>
                                    </li>
                                ) : ''}
                                {/*end::2 Level*/}

                                {/*begin::2 Level*/}
                                {check_role(['item-consumption/list/waste']) ? (
                                    <li
                                        className={`menu-item ${getMenuItemActive("/item-consumption/list/waste")}`}
                                        aria-haspopup="true"
                                    >
                                        <NavLink className="menu-link" to="/item-consumption/list/waste">
                                            <i className="menu-bullet menu-bullet-dot">
                                                <span/>
                                            </i>
                                            <span className="menu-text">List</span>
                                        </NavLink>
                                    </li>
                                ) : ''}
                                {/*end::2 Level*/}

                            </ul>
                        </div>
                    </li>
                ) : ''}
                {/*end::1 Level*/}

            </ul>

            {/* end::Menu Nav */}
        </>
    );
}
