import React, {useEffect, useState, useRef} from "react";
import axios from "axios";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import alertify from "alertifyjs";
import {API_URL} from "../constants.js";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'


import {
    Card,
    CardBody,
    CardHeader,
    CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

export function EditCollectionCenter(props) {
    const { t } = useTranslation();
    const name = useRef();
    const phone_no1 = useRef();
    const phone_no2 = useRef();
    const address = useRef();
    const latitude = useRef();
    const longitude = useRef();
    const lab_id = useRef();

    const [disabled, setDisabled] = useState(0);
    const [iconDisabled, setIconDisabled] = useState(1);
    const [collectionCenter, setCollectionCenter] = useState({});
    const id = props.match.params.id;
    const [labs, setLabs] = useState([]);
    const [fields, setFields] = useState({
        'lab_id' : ''
    })

    useEffect(() => {
        axios.get(API_URL + 'lab').then((response) => {

            if (response.data.status) {
                setLabs(response.data.data);
            } else if (response.data.code === 404) {
                alertify.error(response.data.message);
                return null;
            }
        });
    }, []);

    useEffect(() => {
        axios.get(API_URL + 'collection-center/' + id).then((response) => {
            if (response.data.status) {
                setCollectionCenter(response.data.data);
                setFields({lab_id: response.data.data.lab_id});

            } else {
                alertify.error(response.data.message);
                return null;
            }
        })
    }, []);

    const updateCollectionCenterHandler = (e) => {
        e.preventDefault();
        setDisabled(1);
        setIconDisabled(0);
        axios.post(API_URL + "collection-center/update",
            {
                id: collectionCenter.id,
                name: name.current.value,
                phone_no1: phone_no1.current.value,
                phone_no2: phone_no2.current.value,
                address: address.current.value,
                latitude: latitude.current.value,
                longitude: longitude.current.value,
                lab_id: lab_id.current.value
            }
        ).then((response) => {
            setDisabled(0);
            setIconDisabled(1);
            if (response.data.status) {
                alertify.success(response.data.message);

                props.history.push("/collection-center/list");
            } else {
                alertify.error(response.data.message);
                return null;
            }
        }).catch((error) => {
            setDisabled(0);
            setIconDisabled(1);
            if (error.message === 'Request failed with status code 401') {
                //props.logout();
            }
        });
    }

    const handleLabChange = (e) => {
        setFields({
            lab_id: e.target.value
        });
    }

    return (
        <Row>
            <Col md={12}>
                <Card>
                    <CardHeader title={t("Edit Collection Center")}>
                        <CardHeaderToolbar>
                            <Link to="/collection-center/list" className="btn btn-primary btn-sm mx-3">
                                {t("Collection Center List")}
                            </Link>
                        </CardHeaderToolbar>
                    </CardHeader>
                    <CardBody>

                        <div className="mt-5">
                            <Form onSubmit={updateCollectionCenterHandler}>
                                <Form.Row>
                                    <Form.Group as={Col}>
                                        <Form.Label>{t("Name")}</Form.Label>
                                        <Form.Control ref={name} placeholder={t("Enter Collection Center Name")}
                                                      defaultValue={collectionCenter.name}/>
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>{t("Phone NO 1")}</Form.Label>
                                        <Form.Control ref={phone_no1} rows={1} placeholder={t("Enter Phone No 1")}
                                                      defaultValue={collectionCenter.phone_no1}/>
                                    </Form.Group>

                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col}>
                                        <Form.Label>{t("Phone No 2")}</Form.Label>
                                        <Form.Control ref={phone_no2} placeholder={t("Enter Phone No 2")}
                                                      defaultValue={collectionCenter.phone_no2}/>
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>{t("Address")}</Form.Label>
                                        <Form.Control ref={address} rows={1} placeholder={t("Enter Address")}
                                                      defaultValue={collectionCenter.address}/>
                                    </Form.Group>

                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col}>
                                        <Form.Label>{t("Latitude")}</Form.Label>
                                        <Form.Control ref={latitude} placeholder="19.2.3.4"
                                                      defaultValue={collectionCenter.latitude}/>
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>{t("Longitude")}</Form.Label>
                                        <Form.Control ref={longitude} rows={1} placeholder="20.3.4.02"
                                                      defaultValue={collectionCenter.longitude}/>
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>

                                    <Form.Group as={Col}>
                                        <Form.Control as="select" ref={lab_id} value={fields.lab_id} onChange={handleLabChange}>
                                            <option value="">{t("Select Lab")}</option>
                                            {labs.map((lab, key) => (
                                                <option key={lab.id} value={lab.id}>
                                                    {lab.name}
                                                </option>
                                            ))}
                                        </Form.Control>
                                    </Form.Group>
                                </Form.Row>
                                <Button variant="primary" className="float-right" type="submit" disabled={disabled}>
                                    {t("Save")}
                                    <span
                                        dangerouslySetInnerHTML={{__html: disabled ? `<i class='fas fa-spinner fa-spin'></i>` : ``}}/>
                                </Button>
                            </Form>

                        </div>

                    </CardBody>
                </Card>
            </Col>
        </Row>
    );
}