import React, {useEffect, useState, useRef} from "react";
import {toAbsoluteUrl} from "../../../_metronic/_helpers";
import axios from "axios";
import {Form, Table, Button, Col, Row} from "react-bootstrap";
import alertify from "alertifyjs";
import {API_URL} from "../constants.js";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {
    Card,
    CardBody,
    CardHeader,
    CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import SVG from "react-inlinesvg";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

export function CreateItemConsumption(props) {
    const [consumptionType, setConsumptionType] =  useState(props.match.params.type.charAt(0).toUpperCase() + props.match.params.type.slice(1));
    const {t} = useTranslation();
    const item_id = useRef();
    const quantity = useRef();
    const date = useRef();
    const lot_number = useRef();
    const notes = useRef();
    const type = useRef();

    const [disabled, setDisabled] = useState(0);
    const [iconDisabled, setIconDisabled] = useState(1);
    const [items, setItems] = useState([]);
    const [itemConmsumptions, setItemConsumptions] = useState([]);
    const [currentItem, setCurrentItem] = useState({});

    useEffect(() => {
        axios.get(API_URL + 'item').then((response) => {
            if (response.data.status) {
                setItems(response.data.data);
            } else if (response.data.code === 404) {
                alertify.error(response.data.message);
                return null;
            }
        });
    }, []);

    const addItemComsumption = (e) => {
        e.preventDefault();
        if (!currentItem.id) {
            alertify.error("Please select an item");
            return false;
        }
        let itemConsumption = {
            id: currentItem.id,
            name: currentItem.name,
            quantity: quantity.current.value,
            date: date.current.value,
            lot_number: lot_number.current.value,
            notes: notes.current.value,
            type: type.current.value,
        }

        setItemConsumptions([...itemConmsumptions, itemConsumption])
    }


    const createItemConsumptionHandler = (e) => {
        console.log(itemConmsumptions);
        e.preventDefault();
        setDisabled(1);
        setIconDisabled(0);
        axios.post(API_URL + "item-consumption/create",
            {
                items: itemConmsumptions,
            }
        ).then((response) => {
            setDisabled(0);
            setIconDisabled(1);
            if (response.data.status) {
                alertify.success(response.data.message);
                props.history.push("/item-consumption/list/"+props.match.params.type);
            } else {
                alertify.error(response.data.message);
                return null;
            }
        }).catch((error) => {
            setDisabled(0);
            setIconDisabled(1);
            if (error.message === 'Request failed with status code 401') {
                //props.logout();
            }
        });
    }

    const handleItemChange = (e) => {
        if (e.target.value == '') {
            setCurrentItem({});
        } else {
            let selectedItem = items.find(item => item.id == e.target.value);

            setCurrentItem(selectedItem);
        }
    }

    const removeItemConsumption = (e) => {
        let arr = itemConmsumptions;
        arr = arr.filter((item, index) => index !== e);
        setItemConsumptions(arr);
    }
    return (
        <Row>
            <Col md={12}>
                <Card>
                    <CardHeader title={t(`Add New ${consumptionType}`)}>
                        <CardHeaderToolbar>
                            <Link to={`/item-consumption/list/${props.match.params.type}`} className="btn btn-primary btn-sm mx-3">
                                {t(`${consumptionType} List`)}
                            </Link>
                        </CardHeaderToolbar>
                    </CardHeader>
                    <CardBody>
                        <Form>
                            <div className="mt-5">
                                <Form.Row>
                                    <Form.Group as={Col}>
                                        <Form.Label>{t("Item")}</Form.Label>
                                        <Form.Control as="select" ref={item_id} onChange={handleItemChange}>
                                            <option value="">{t("Select Item")}</option>
                                            {
                                                items.map((item, index) => (
                                                    <option key={index} value={item.id}>{item.name}</option>
                                                ))
                                            }
                                        </Form.Control>
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>{t("Quantity")}</Form.Label>
                                        <Form.Control ref={quantity} placeholder={t("Quantity")}/>
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col}>
                                        <Form.Label>{t("Date")}</Form.Label>
                                        <Form.Control type={"date"} ref={date}/>
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>{t("Lot Number")}</Form.Label>
                                        <Form.Control ref={lot_number} placeholder={t("Lot Number")}/>
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col}>
                                        <Form.Label>{t("Notes")}</Form.Label>
                                        <Form.Control as={"textarea"} ref={notes} placeholder={t("Notes")}/>
                                    </Form.Group>
                                </Form.Row>
                                <Row className={"text-center"}>
                                    <Col md={6}>
                                        <Button variant="primary" className="float-right"
                                                onClick={addItemComsumption}>
                                            {t("Add To Table")}
                                        </Button>
                                    </Col>
                                </Row>
                            </div>

                            <div className="mt-5">
                                <Row>
                                    <Col md={12}>
                                        <Table striped bordered hover>
                                            <thead>
                                            <tr>
                                                <th>{t("Item")}</th>
                                                <th>{t("Quantity")}</th>
                                                <th>{t("Date")}</th>
                                                <th>{t("Lot Number")}</th>
                                                <th>{t("Notes")}</th>
                                                <th>{t("Type")}</th>
                                                <th>{t("Action")}</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                itemConmsumptions.map((itemConmsumption, index) => (
                                                    <tr key={index}>
                                                        <td>{itemConmsumption.name}</td>
                                                        <td>{itemConmsumption.quantity}</td>
                                                        <td>{itemConmsumption.date}</td>
                                                        <td>{itemConmsumption.lot_number}</td>
                                                        <td>{itemConmsumption.notes}</td>
                                                        <td>{itemConmsumption.type}</td>
                                                        <td>
                                                            <a
                                                                onClick={() => removeItemConsumption(index)}
                                                                className="btn btn-icon btn-light btn-hover-danger btn-sm">
                                                            <span className="svg-icon svg-icon-md svg-icon-danger">
                                                              <SVG
                                                                  src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")}/>
                                                            </span>
                                                            </a>
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                            </tbody>
                                        </Table>
                                    </Col>
                                </Row>
                                <Form.Control
                                    type="hidden"
                                    ref={type}
                                    value={props.match.params.type}
                                    aria-describedby="passwordHelpBlock"
                                />
                                <Button variant="primary" className="float-right" type="submit" disabled={disabled}
                                        onClick={createItemConsumptionHandler}>
                                    {t("Save")}
                                    <span
                                        dangerouslySetInnerHTML={{__html: disabled ? `<i class='fas fa-spinner fa-spin'></i>` : ``}}/>
                                </Button>
                            </div>
                        </Form>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    );

}