import React, {useEffect, useState, useRef} from "react";
import axios from "axios";
import $ from "jquery";
// import DataTable from "datatables.net";
import {Link} from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import {useHistory} from 'react-router';
import {toAbsoluteUrl} from "../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import alertify from "alertifyjs";
import {date_format, datetime_format} from "../Common/DateFormat";

import {API_URL} from '../constants.js';

import {
    Card,
    CardBody,
    CardHeader,
    CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";

export function ReportEdit(props) {
    const mr = useRef();
    const [age, setAge] = useState(0);
    const [message, setMessage] = useState('');

    const [disabled, setDisabled] = useState(0);
    const [iconDisabled, setIconDisabled] = useState(1);
    const [collectionCenters, setCollectionCenters] = useState([]);
    const [patient, setPatient] = useState({
        patient_tests: []
    });
    const [show, setShow] = useState(false);
    const [statusModalShow, setStatusModalShow] = useState(false);
    const [selectedPatientStatus, setSelectedPatientStatus] = useState("0");

    const history = useHistory();

    const handleClose = () => {
        setStatusModalShow(false);
        setShow(false);
    }

    const resultHandler = (patient_test_index, patient_test_detail_index, value) => {
        patient.patient_tests[patient_test_index].patient_test_details[patient_test_detail_index].result = value;
        setPatient(patient);

        patient.patient_tests[patient_test_index].patient_test_details[patient_test_detail_index].test_detail.normal_values_with_trashed.map((normalvalue, ind) => {

            if ((age <= normalvalue.to_age
                && age >= normalvalue.from_age
                && normalvalue.gender == patient.gender)
                ||
                (patient.patient_tests[patient_test_index].patient_test_details[patient_test_detail_index].flag == undefined
                    && normalvalue.gender == 'default')) {

                if (value < parseFloat(normalvalue.minimum_value)) {
                    $('#row' + patient_test_index + '_' + patient_test_detail_index).removeClass('normal-row');
                    patient.patient_tests[patient_test_index].patient_test_details[patient_test_detail_index].flag = "lower";
                    $('#row' + patient_test_index + '_' + patient_test_detail_index).addClass('danger-row');
                } else if (value > parseFloat(normalvalue.maximum_value)) {
                    patient.patient_tests[patient_test_index].patient_test_details[patient_test_detail_index].flag = "high";
                    $('#row' + patient_test_index + '_' + patient_test_detail_index).removeClass('normal-row');
                    $('#row' + patient_test_index + '_' + patient_test_detail_index).addClass('danger-row');
                } else if (value.toLowerCase() == 'negative') {
                    patient.patient_tests[patient_test_index].patient_test_details[patient_test_detail_index].flag = "negative";
                    $('#row' + patient_test_index + '_' + patient_test_detail_index).removeClass('normal-row');
                    $('#row' + patient_test_index + '_' + patient_test_detail_index).addClass('danger-row');
                } else {
                    patient.patient_tests[patient_test_index].patient_test_details[patient_test_detail_index].flag = "normal";
                    $('#row' + patient_test_index + '_' + patient_test_detail_index).removeClass('danger-row');
                    $('#row' + patient_test_index + '_' + patient_test_detail_index).addClass('normal-row');
                }
                setPatient(patient);
            console.log(patient);
            }
        });

    }

    const reportGetDataHandler = (e) => {
        e.preventDefault();
        setDisabled(1);
        setIconDisabled(0);
        axios.post(
            API_URL + 'patient/patient-report-by-mr',
            {
                mr: mr.current.value,
            }
        ).then((response) => {
            setDisabled(0);
            setIconDisabled(1);
            if (response.data.status) {
                if (response.data.data) {
                    setPatient(response.data.data);

                    //Get Age From DOB.
                    var today = new Date();
                    var birthDate = new Date(response.data.data.dob);
                    var age = today.getFullYear() - birthDate.getFullYear();
                    var m = today.getMonth() - birthDate.getMonth();
                    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                        age--;
                    }
                    setAge(age);
                } else {
                    setMessage('Either Wrong Mr.No. Or Report Already Created. You Can Edit by Following Link');
                    setPatient({});
                }

            } else {
                return null;
            }
        })
    }

    const createReportHandler = (e) => {
        e.preventDefault();
        setDisabled(1);
        setIconDisabled(0);
        axios.post(API_URL + "patient/update-report",
            {
                patient: patient,
            }
        ).then((response) => {
            setDisabled(0);
            setIconDisabled(1);
            if (response.data.status) {
                alertify.success(response.data.data);

                //props.history.push("/unit/list");
            } else {
                alertify.error(response.data.message);
                return null;
            }
        }).catch((error) => {
            setDisabled(0);
            setIconDisabled(1);
            if (error.message === 'Request failed with status code 401') {
                //props.logout();
            }
        });
    }

    return (
        <>
            <Row>
                <Col md={12}>
                    <Card>
                        <CardHeader title="Edit Report">
                            <CardHeaderToolbar>
                                <Link to="/patient/create" className="btn btn-primary btn-sm mx-3">
                                    Create Patient
                                </Link>
                            </CardHeaderToolbar>
                        </CardHeader>
                        <div className={"mt-5"}>
                            <Form onSubmit={reportGetDataHandler}>
                                <Row className={"ml-5  mr-5 mb-10 "}>
                                    <Col xs={12} md={4}>
                                        <Form.Label>Mr.No</Form.Label>
                                        <Form.Control ref={mr}
                                                      placeholder="Search By Mr.No"/>
                                    </Col>
                                    <Col xs={12} md={2} style={{marginTop: "20px"}}>
                                        <Button variant="primary" size="lg" className="float-right btn-block"
                                                type="submit"
                                                disabled={disabled}>
                                            Filter
                                            <span
                                                dangerouslySetInnerHTML={{__html: disabled ? `<i class='fas fa-spinner fa-spin'></i>` : ``}}/>
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                        <CardBody>
                            {
                                Object.keys(patient).length > 1 ? (

                                    <>
                                        <Table striped bordered hover>
                                            <tbody>
                                            <tr>
                                                <th>Mr. No</th>
                                                <th>{patient.mr}</th>
                                                <th>Name</th>
                                                <th>{patient.first_name + ' ' + patient.last_name}</th>
                                            </tr>
                                            <tr>
                                                <th>Gender</th>
                                                <th>{patient.gender}</th>
                                                <th>DOB</th>
                                                <th>{date_format(patient.dob)}</th>
                                            </tr>
                                            <tr>
                                                <th>Phone No</th>
                                                <th>{patient.phone_no}</th>
                                                <th>Registeredat</th>
                                                <th>{datetime_format(patient.registered_at)}</th>
                                            </tr>
                                            </tbody>
                                        </Table>
                                        <Row>
                                            <Col md={12} className={"text-center mt-5 mb-5"}><h1>Generate Report</h1>
                                            </Col>
                                        </Row>

                                        {patient.patient_tests.map((test, index) => (
                                            <Row className="text-center" key={index}>
                                                <Col md={12} className={"test-name"}><h3>{test.test.name}</h3></Col>
                                                <Col md={12}>
                                                    <Table striped bordered hover>
                                                        <thead>
                                                        <tr variant="dark">
                                                            <th>Title</th>
                                                            <th>Unit</th>
                                                            <th>Result</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {test.patient_test_details.map((detail, element) => (

                                                            <tr
                                                                className={
                                                                        detail.remarks == 'lower' || detail.remarks == 'higher' ||detail.remarks == 'negative'
                                                                    ? 'danger-row' : 'normal-row'
                                                                }
                                                                key={element} id={"row" + index + "_" + element}>
                                                                <td>{detail.name}</td>
                                                                <td>{detail.unit_id}</td>
                                                                <td>
                                                                    <Form.Control placeholder="Enter Results"
                                                                                  defaultValue={detail.result}
                                                                                  onKeyDown={(e) => resultHandler(index, element, e.target.value)}
                                                                                  onKeyUp={(e) => resultHandler(index, element, e.target.value)}
                                                                                  onKeyPress={(e) => resultHandler(index, element, e.target.value)}/>
                                                                </td>
                                                            </tr>
                                                        ))}

                                                        </tbody>
                                                    </Table>
                                                </Col>
                                            </Row>
                                        ))}

                                        <div className={"mt-5"}>
                                            <Form onSubmit={createReportHandler}>
                                                <Row className={"ml-5  mr-5 mb-10 "}>
                                                    <Col xs={12} md={12} style={{marginTop: "15px"}}>
                                                        <Button variant="primary" size="lg"
                                                                className="float-right btn-block"
                                                                type="submit"
                                                                disabled={disabled}>
                                                            Save
                                                            <span
                                                                dangerouslySetInnerHTML={{__html: disabled ? `<i class='fas fa-spinner fa-spin'></i>` : ``}}/>
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        </div>
                                    </>
                                ) : (
                                    <h3>{message}</h3>
                                )}
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    )
}