/*Patient Node*/
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import {useLocation} from "react-router";
import {NavLink} from "react-router-dom";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl, checkIsActive} from "../../../../_helpers";
import {makeStyles} from '@material-ui/core/styles';
import AirportShuttleIcon from '@material-ui/icons/AirportShuttle';
import ExtensionIcon from '@material-ui/icons/Extension';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import PeopleIcon from '@material-ui/icons/People';
import HelpIcon from '@material-ui/icons/Help';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import EventNoteIcon from '@material-ui/icons/EventNote'; //Patient Reporting
import AccessibleIcon from '@material-ui/icons/Accessible'; //Patient
import {check_role} from '../../../../../app/pages/Common/RoleMenuCheck';


const useStyles = makeStyles(theme => ({
    root: {
        color: theme.palette.text.primary,
    },
    icon: {
        margin: theme.spacing(1),
        fontSize: 32,
    },
}));

export function AsideMenuPatientList({layoutProps}) {
    const classes = useStyles();
    const location = useLocation();
    const getMenuItemActive = (url, hasSubmenu = false) => {
        return checkIsActive(location, url)
            ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
            : "";
    };
    /*CHECK FOR ROLE AND MENU TO SHOW NODES*/
    /*const menus = JSON.parse(localStorage.getItem('menus'));

    function checkRole(menuslist) {
        return menus.some(function (el) {
            if (menuslist.indexOf(el.slug) !== -1) {
                return true;
            }
        });
    }*/
    // console.log(checkRole(['unit/create','unit/list']));
    return (
        <>
            {/* begin::Menu Nav */}
            <ul className={`menu-nav ${layoutProps.ulClasses}`}>


                {/* Patient Management */}
                {/* begin::section */}
                <li className="menu-section ">
                    <h4 className="menu-text">Patient Management</h4>
                    <i className="menu-icon flaticon-more-v2"></i>
                </li>
                {/* end:: section */}

                {/* Agents Type Pages */}
                {/*begin::1 Level*/}
                {check_role(['patient/create', 'patient/list', 'patient/filter']) ? (
                    <li
                        className={`menu-item menu-item-submenu ${getMenuItemActive(
                            "/patient", true
                        )}`}
                        aria-haspopup="true"
                        data-menu-toggle="hover"
                    >
                        <NavLink className="menu-link menu-toggle" to="/patient">
            <span className="svg-icon menu-icon">
              <AccessibleIcon className={classes.icon}/>
            </span>
                            <span className="menu-text">Patient</span>
                            <i className="menu-arrow"/>
                        </NavLink>
                        <div className="menu-submenu ">
                            <i className="menu-arrow"/>
                            <ul className="menu-subnav">
                                <li className="menu-item  menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">Patient</span>
                </span>
                                </li>

                                {/*begin::2 Level*/}
                                {check_role(['patient/create']) ? (
                                    <li
                                        className={`menu-item ${getMenuItemActive("/patient/create")}`}
                                        aria-haspopup="true"
                                    >
                                        <NavLink className="menu-link" to="/patient/create">
                                            <i className="menu-bullet menu-bullet-dot">
                                                <span/>
                                            </i>
                                            <span className="menu-text">Add New</span>
                                        </NavLink>
                                    </li>
                                ) : ''}
                                {/*end::2 Level*/}

                                {/*begin::2 Level*/}
                                {check_role(['patient/list']) ? (
                                    <li
                                        className={`menu-item ${getMenuItemActive("/patient/list")}`}
                                        aria-haspopup="true"
                                    >
                                        <NavLink className="menu-link" to="/patient/list">
                                            <i className="menu-bullet menu-bullet-dot">
                                                <span/>
                                            </i>
                                            <span className="menu-text">List</span>
                                        </NavLink>
                                    </li>
                                ) : ''}
                                {/*end::2 Level*/}
                                {/*begin::2 Level*/}
                                {check_role(['patient/filter']) ? (
                                    <li
                                        className={`menu-item ${getMenuItemActive("/patient/filter")}`}
                                        aria-haspopup="true"
                                    >
                                        <NavLink className="menu-link" to="/patient/filter">
                                            <i className="menu-bullet menu-bullet-dot">
                                                <span/>
                                            </i>
                                            <span className="menu-text">Filter</span>
                                        </NavLink>
                                    </li>
                                ) : ''}
                                {/*end::2 Level*/}

                            </ul>
                        </div>
                    </li>
                ) : ''}
                {/*end::1 Level*/}
                <li className="menu-section ">
                    <h4 className="menu-text">Patient Reporting</h4>
                    <i className="menu-icon flaticon-more-v2"></i>
                </li>
                {/* User Pages */}
                {/*begin::1 Level*/}
                {check_role(['report-create', 'report-edit', 'report-generate']) ? (
                    <li
                        className={`menu-item menu-item-submenu ${getMenuItemActive(
                            "/bus-type", true
                        )}`}
                        aria-haspopup="true"
                        data-menu-toggle="hover"
                    >
                        <NavLink className="menu-link menu-toggle" to="/report">
                    <span className="svg-icon menu-icon">
                    <EventNoteIcon className={classes.icon}/>
                    </span>
                            <span className="menu-text">Report Area</span>
                            <i className="menu-arrow"/>
                        </NavLink>
                        <div className="menu-submenu ">
                            <i className="menu-arrow"/>
                            <ul className="menu-subnav">
                                <li className="menu-item  menu-item-parent" aria-haspopup="true">
                    <span className="menu-link">
                    <span className="menu-text">Report Area</span>
                    </span>
                                </li>
                                {/*begin::2 Level*/}
                                {check_role(['report-create']) ? (
                                    <li
                                        className={`menu-item ${getMenuItemActive("/report-create")}`}
                                        aria-haspopup="true"
                                    >
                                        <NavLink className="menu-link" to="/report-create">
                                            <i className="menu-bullet menu-bullet-dot">
                                                <span/>
                                            </i>
                                            <span className="menu-text">Create Report</span>
                                        </NavLink>
                                    </li>
                                ) : ''}
                                {/*end::2 Level*/}
                                {/*begin::2 Level*/}
                                {check_role(['report-edit']) ? (
                                    <li
                                        className={`menu-item ${getMenuItemActive("/report-edit")}`}
                                        aria-haspopup="true"
                                    >
                                        <NavLink className="menu-link" to="/report-edit">
                                            <i className="menu-bullet menu-bullet-dot">
                                                <span/>
                                            </i>
                                            <span className="menu-text">Edit Report</span>
                                        </NavLink>
                                    </li>
                                ) : ''}
                                {/*end::2 Level*/}
                                {/*begin::2 Level*/}
                                {check_role(['report-generate']) ? (
                                    <li
                                        className={`menu-item ${getMenuItemActive("/report-generate")}`}
                                        aria-haspopup="true"
                                    >
                                        <NavLink className="menu-link" to="/report-generate">
                                            <i className="menu-bullet menu-bullet-dot">
                                                <span/>
                                            </i>
                                            <span className="menu-text">Generate Report</span>
                                        </NavLink>
                                    </li>
                                ) : ''}
                                {/*end::2 Level*/}

                            </ul>
                        </div>
                    </li>
                ) : ''}
                {/*end::1 Level*/}


            </ul>

            {/* end::Menu Nav */}
        </>
    );
}
