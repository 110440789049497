import React, {useEffect, useState, useRef} from "react";
import axios from "axios";
import $ from "jquery";
import DataTable from "datatables.net";
import {Link} from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import {useHistory} from 'react-router';
import {toAbsoluteUrl} from "../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import alertify from "alertifyjs";
import {date_format, datetime_format} from "../Common/DateFormat";
import {API_URL, BACKEND_URL} from '../constants.js';
import PrintIcon from '@material-ui/icons/Print';
import DescriptionIcon from '@material-ui/icons/Description';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import EditIcon from '@material-ui/icons/Edit';
import BeachAccessIcon from '@material-ui/icons/BeachAccess';
import Form from "react-bootstrap/Form";

import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import {useTranslation} from "react-i18next";
import {forEach} from "react-bootstrap/cjs/ElementChildren";

export function CreateImmunoReportCsv(props) {
  const {t} = useTranslation();
  const sample_ids_textarea = useRef();
  const [patients, setPatients] = useState([]);
  const [patient, setPatient] = useState(null);
  const [show, setShow] = useState(false);
  const [action, setAction] = useState('patient');
  const [checkedItems, setCheckedItems] = useState({});
  const [barcodeFile, setBarcodeFile] = useState('');

  const [disabled, setDisabled] = useState(0);
  const [iconDisabled, setIconDisabled] = useState(1);

  const history = useHistory()

  const setCurrentPatient = (patient, action) => {
    setPatient(patient);
    setAction(action);
    setShow(true);
  };

  const handleClose = () => setShow(false);

  const uploadCsvFile = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('barcode_file', barcodeFile, 'sample_ids_textarea', sample_ids_textarea);

      axios.post(API_URL + "patient/create-immuno-report-csv",
      formData, {
        'content-type': 'multipart/form-data'
      }).then((response) => {
      if (response.data.status) {
        alertify.success("Reports Created Successfully");
      } else {
        alertify.error(response.data.message);
        return null;
      }
    }).catch((error) => {
      if (error.message === 'Request failed with status code 401') {
        //props.logout();
      }
    });
  }

  const handleBarcodeFile = (e) => {
    setBarcodeFile(e.target.files[0]);
  }

  return (
    <>
      <Row>
        <Col md={12}>
          <Card>
            <CardHeader title={t("Upload Immuno CSV Results")}>
              <CardHeaderToolbar>

              </CardHeaderToolbar>
            </CardHeader>
            <CardBody style={{overflow: 'auto'}}>
              <Form onSubmit={uploadCsvFile}>
                <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label>{t("Choose Csv")}</Form.Label>
                    <Form.Control type={"file"} onChange={handleBarcodeFile}/>
                  </Form.Group>
                  <Form.Group as={Col}>

                  </Form.Group>
                </Form.Row>
                <Button variant="primary" className="float-right" type="submit"
                        disabled={disabled}>
                  {t("Save")}
                  <span
                    dangerouslySetInnerHTML={{__html: disabled ? `<i class='fas fa-spinner fa-spin'></i>` : ``}}/>
                </Button>

              </Form>
            </CardBody>
          </Card>

        </Col>
      </Row>
    </>
  )
}

