import React, {useEffect, useState, useRef} from "react";
import {toAbsoluteUrl} from "../../../_metronic/_helpers";
import axios from "axios";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import alertify from "alertifyjs";
import {API_URL} from "../constants.js";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {
    Card,
    CardBody,
    CardHeader,
    CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import {Link} from "react-router-dom";
import {Table} from "react-bootstrap";
import SVG from "react-inlinesvg";
import {useTranslation} from "react-i18next";

export function TestEdit(props) {
    const { t } = useTranslation();

    const id = props.match.params.id;
    const name = useRef();
    const short_name = useRef();
    const turn_around_time = useRef();
    const amount = useRef();
    const sample_type_id = useRef();
    const test_category_id = useRef();
    const booking_list = useRef();
    const background = useRef();
    const clinical_indicators = useRef();
    const special_handling = useRef();
    const patient_preparation = useRef();
    const note = useRef();
    const test_detail_name = useRef();
    const test_detail_unit_id = useRef();
    const test_detail_default_result = useRef();
    const test_detail_order = useRef();

    const [testData, setTestData] = useState([]);
    const [sampleTypes, setSampleTypes] = useState([]);
    const [testCategories, setTestCategories] = useState([]);
    const [units, setUnits] = useState([]);
    const [sampleType, setSampleType] = useState();
    const [testCategory, setTestCategory] = useState();
    const [bookingList, setBookingList] = useState();

    const initialNormalValue = {
        "gender": "male",
        "from_age": 0,
        "to_age": 0,
        "minimum_value": 0,
        "maximum_value": 0
    };
    const [normalValues, setNormalValues] = useState([initialNormalValue]);

    const [testDetails, setTestDetails] = useState([]);

    const [disabled, setDisabled] = useState(0);
    const [iconDisabled, setIconDisabled] = useState(1);

    const fromAgeHandler = (e, value) => {
        normalValues[e].from_age = value;
        setNormalValues(normalValues);
    }

    const toAgeHandler = (e, value) => {
        normalValues[e].to_age = value;
        setNormalValues(normalValues);
    }

    const minimumValueHandler = (e, value) => {
        normalValues[e].minimum_value = value;
        setNormalValues(normalValues);
    }

    const maximumValueHandler = (e, value) => {
        normalValues[e].maximum_value = value;
        setNormalValues(normalValues);
    }

    const labelValueHandler = (e, value) => {
        normalValues[e].label = value;
        setNormalValues(normalValues);
    }

    const genderChangeHandler = (e, value) => {
        normalValues[e].gender = value;
        setNormalValues(normalValues);
    }

    const sampleTypeHandler = (e) => {
        setSampleType(e.target.value);
    }

    const testCategoryHandler = (e) => {
        setTestCategory(e.target.value);
    }

    const bookingListHandler = (e) => {
        setBookingList(e.target.value);
    }

    useEffect(() => {
        axios.get(API_URL + 'test/' + id).then((response) => {
            if (response.data.status) {
                setSampleTypes(response.data.data.sampleTypes);
                setTestCategories(response.data.data.testCategories);
                setUnits(response.data.data.units);
                setTestData(response.data.data.test);
                setSampleType(response.data.data.test.sample_type_id);
                setTestCategory(response.data.data.test.test_category_id);
                setBookingList(response.data.data.test.booking_list);

                setTestDetails(response.data.data.test.details);

            } else {
                alertify.error(response.data.message);
                return null;
            }
        })

    }, []);

    const addMoreNormalValue = (element) => {
        setNormalValues([...normalValues, initialNormalValue]);
    }

    const addToTableHandler = (e) => {

        let test_to_add = {
            "name": test_detail_name.current.value,
            "unit_id": test_detail_unit_id.current.value,
            "default_result": test_detail_default_result.current.value,
            "order": test_detail_order.current.value,
            "normal_values": normalValues
        }
        setTestDetails([...testDetails, test_to_add]);
    }

    const removeTest = (index) => {
        let testDetail = testDetails;
        setTestDetails(testDetail => testDetail.filter((elem, ind) => ind !== index));
    }

    const removeNormalValue = (index) => {
        let normalValues = normalValues;
        setNormalValues(normalValues => normalValues.filter((elem, ind) => ind !== index));
    }

    const updateTestHandler = (e) => {
        e.preventDefault();
        setDisabled(1);
        setIconDisabled(0);
        axios.post(API_URL + "test/update",
            {
                id: id,
                name: name.current.value,
                short_name: short_name.current.value,
                turn_around_time: turn_around_time.current.value,
                amount: amount.current.value,
                sample_type_id: sample_type_id.current.value,
                test_category_id: test_category_id.current.value,
                background: background.current.value,
                clinical_indicators: clinical_indicators.current.value,
                special_handling: special_handling.current.value,
                patient_preparation: patient_preparation.current.value,
                note: note.current.value,
                booking_list: booking_list.current.value,
                test_details: testDetails
            }
        ).then((response) => {
            setDisabled(0);
            setIconDisabled(1);
            if (response.data.status) {
                alertify.success(response.data.message);

                props.history.push("/test/list");
            } else {
                alertify.error(response.data.message);
                return null;
            }
        }).catch((error) => {
            setDisabled(0);
            setIconDisabled(1);
            if (error.message === 'Request failed with status code 401') {
                //props.logout();
            }
        });
    }
    return (
        <>
            <Row>
                <Col md={12}>
                    <Card>
                        <CardHeader title={t("Add New Test")}>
                            <CardHeaderToolbar>
                                <Link to="/test/list" className="btn btn-primary btn-sm mx-3">
                                    {t("Test List")}
                                </Link>
                            </CardHeaderToolbar>
                        </CardHeader>
                        <CardBody>

                            <div className="mt-5">
                                <Form onSubmit={updateTestHandler}>
                                    <Form.Row>
                                        <Form.Group as={Col}>
                                            <Form.Label>{t("Name")}</Form.Label>
                                            <Form.Control ref={name} defaultValue={testData.name}
                                                          placeholder={t("Enter Test Name")}/>
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Form.Label>{t("Short Name")}</Form.Label>
                                            <Form.Control ref={short_name} defaultValue={testData.short_name}
                                                          placeholder={t("Enter Short Name")}/>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col}>
                                            <Form.Label>{t("Turnaround Time")}</Form.Label>
                                            <Form.Control ref={turn_around_time}
                                                          defaultValue={testData.turn_around_time}
                                                          placeholder={t("Enter Turnaround Time")}/>
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Form.Label>{t("Amount")}</Form.Label>
                                            <Form.Control type={"number"} ref={amount} defaultValue={testData.amount}
                                                          placeholder={t("Enter Amount")}/>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col}>
                                            <Form.Label>{t("Sample Type")}</Form.Label>
                                            <Form.Control as="select" ref={sample_type_id} value={sampleType}
                                                          onChange={sampleTypeHandler}>
                                                {sampleTypes.length > 0 ? (
                                                    sampleTypes.map((sampletype, index) => (
                                                        <option key={index}
                                                                value={sampletype.id}>{sampletype.name}</option>
                                                    ))
                                                ) : (
                                                    <option value={"0"}>{t("No Sample Type")}</option>
                                                )}
                                            </Form.Control>
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Form.Label>{t("Test Category")}</Form.Label>
                                            <Form.Control as="select" ref={test_category_id} value={testCategory}
                                                          onChange={testCategoryHandler}>
                                                {testCategories.length > 0 ? (
                                                    testCategories.map((testcategory, index) => (
                                                        <option key={index}
                                                                value={testcategory.id}>{testcategory.name}</option>
                                                    ))
                                                ) : (
                                                    <option value={"0"}>{t("No Sample Type")}</option>
                                                )}
                                            </Form.Control>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col}>
                                            <Form.Label>{t("Background")}</Form.Label>
                                            <Form.Control as="textarea" ref={background}
                                                          defaultValue={testData.background} rows={3}/>
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Form.Label>{t("Clinical Indicators")}</Form.Label>
                                            <Form.Control as="textarea" ref={clinical_indicators}
                                                          defaultValue={testData.clinical_indicators} rows={3}/>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col}>
                                            <Form.Label>{t("Special Handling")}</Form.Label>
                                            <Form.Control as="textarea" ref={special_handling}
                                                          defaultValue={testData.special_handling} rows={3}/>
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Form.Label>{t("Patient Preparation")}</Form.Label>
                                            <Form.Control as="textarea" ref={patient_preparation}
                                                          defaultValue={testData.patient_preparation} rows={3}/>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col}>
                                            <Form.Label>{t("Note")}</Form.Label>
                                            <Form.Control as="textarea" ref={note} defaultValue={testData.note}
                                                          rows={3}/>
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Form.Label>{t("Booking List")}</Form.Label>
                                            <Form.Control as="select" ref={booking_list} value={bookingList}
                                                          onChange={bookingListHandler}>
                                                <option value={"1"}>{t("Yes")}</option>
                                                <option value={"0"}>{t("No")}</option>

                                            </Form.Control>
                                        </Form.Group>
                                    </Form.Row>

                                    <Row>
                                        <Col md={6}>
                                            <Table striped bordered hover>
                                                <thead>
                                                <tr>
                                                    <th>{t("Detail Name")}</th>
                                                    <th>{t("Unit")}</th>
                                                    <th>{t("Default Result")}</th>
                                                    <th>{t("Order")}</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr>
                                                    <td>
                                                        <Form.Control ref={test_detail_name}
                                                                      placeholder={t("Enter Detail Name")}/>
                                                    </td>
                                                    <td>
                                                        <Form.Control as="select" ref={test_detail_unit_id}>
                                                            {units.length > 0 ? (
                                                                units.map((unit, index) => (
                                                                    <option key={index}
                                                                            value={unit.id}>{unit.name}</option>
                                                                ))
                                                            ) : (
                                                                ""
                                                            )}

                                                        </Form.Control>
                                                    </td>
                                                    <td>
                                                        <Form.Control ref={test_detail_default_result}
                                                                      placeholder={t("Enter Default Value")}/>
                                                    </td>
                                                    <td>
                                                        <Form.Control ref={test_detail_order} type={"number"}
                                                                      placeholder={t("Enter Detail Name")}/>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={"4"}>
                                                        <a onClick={addToTableHandler}>
                                                            <Button variant="primary">
                                                                {t("ADD TO TABLE")}
                                                            </Button>
                                                        </a>
                                                    </td>
                                                </tr>
                                                </tbody>

                                            </Table>
                                        </Col>
                                        <Col md={6}>
                                            <Table striped bordered hover>
                                                <tbody>
                                                <tr>
                                                    <td>{t("Gender")}</td>
                                                    <td>{t("From Age")}</td>
                                                    <td>{t("To Age")}</td>
                                                    <td>{t("Min Value")}</td>
                                                    <td>{t("Max Value")}</td>
                                                    <td>{t("Label")}</td>
                                                    <td>{t("Action")}</td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="6">
                                                        <a onClick={() => addMoreNormalValue(0)}>
                                                            <Button variant="primary">Add more</Button></a>
                                                    </td>
                                                </tr>
                                                {normalValues.length > 0 ? (
                                                        normalValues.map((normalValue, index) => (
                                                            <tr>
                                                                <td>
                                                                    <Form.Control as="select"
                                                                                  defaultValue={normalValue.gender}
                                                                                  onChange={(e) => genderChangeHandler(index, e.target.value)}>
                                                                        <option value="male">{t("Male")}</option>
                                                                        <option value="female">{t("Female")}</option>
                                                                        <option value="na">{t("Not Answered")}</option>
                                                                        <option value="default">{t("Default")}</option>
                                                                    </Form.Control>
                                                                </td>
                                                                <td>
                                                                    <Form.Control type={"number"}
                                                                                  defaultValue={normalValue.from_age}
                                                                                  placeholder={t("From Age")}
                                                                                  onKeyDown={(e) => fromAgeHandler(index, e.target.value)}
                                                                                  onKeyUp={(e) => fromAgeHandler(index, e.target.value)}
                                                                                  onKeyPress={(e) => fromAgeHandler(index, e.target.value)}/>
                                                                </td>
                                                                <td>
                                                                    <Form.Control type={"number"}
                                                                                  defaultValue={normalValue.to_age}
                                                                                  placeholder={t("To Age")}
                                                                                  onKeyDown={(e) => toAgeHandler(index, e.target.value)}
                                                                                  onKeyUp={(e) => toAgeHandler(index, e.target.value)}
                                                                                  onKeyPress={(e) => toAgeHandler(index, e.target.value)}/>
                                                                </td>
                                                                <td>
                                                                    <Form.Control type={"number"}
                                                                                  defaultValue={normalValue.minimum_value}
                                                                                  placeholder={t("Min")}
                                                                                  onKeyDown={(e) => minimumValueHandler(index, e.target.value)}
                                                                                  onKeyUp={(e) => minimumValueHandler(index, e.target.value)}
                                                                                  onKeyPress={(e) => minimumValueHandler(index, e.target.value)}/>
                                                                </td>
                                                                <td>
                                                                    <Form.Control type={"number"}
                                                                                  defaultValue={normalValue.maximum_value}
                                                                                  placeholder={t("Max")}
                                                                                  onKeyDown={(e) => maximumValueHandler(index, e.target.value)}
                                                                                  onKeyUp={(e) => maximumValueHandler(index, e.target.value)}
                                                                                  onKeyPress={(e) => maximumValueHandler(index, e.target.value)}/>
                                                                </td>
                                                                <td>
                                                                    <Form.Control type={"text"}
                                                                                  defaultValue={normalValue.label}
                                                                                  placeholder={t("Label")}
                                                                                  onKeyDown={(e) => labelValueHandler(index, e.target.value)}
                                                                                  onKeyUp={(e) => labelValueHandler(index, e.target.value)}
                                                                                  onKeyPress={(e) => labelValueHandler(index, e.target.value)}/>
                                                                </td>
                                                                <td>
                                                                    <a
                                                                        onClick={() => removeNormalValue(index)}
                                                                        className="btn btn-icon btn-light btn-hover-danger btn-sm">
                                                            <span className="svg-icon svg-icon-md svg-icon-danger">
                                                              <SVG
                                                                  src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")}/>
                                                            </span>
                                                                    </a>
                                                                </td>
                                                            </tr>
                                                        ))
                                                    )
                                                    :
                                                    (
                                                        ""
                                                    )}

                                                </tbody>
                                            </Table>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12}>
                                            <Table striped bordered hover>
                                                <thead>
                                                <tr>
                                                    <th>{t("Test Detail Name")}</th>
                                                    <th>{t("Unit")}</th>
                                                    <th>{t("Default Value")}</th>
                                                    <th>{t("Order")}</th>
                                                    <th>{t("Ranges")}</th>
                                                    <th>{t("Action")}</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {testDetails.length > 0 ? (
                                                    testDetails.map((testDetail, index) => (
                                                        <tr>
                                                            <td>{testDetail.name}</td>
                                                            <td>{units.find(unit => unit.id == testDetail.unit_id).name}</td>
                                                            <td>{testDetail.default_result}</td>
                                                            <td>{testDetail.order}</td>
                                                            <td>
                                                                <Table striped bordered hover variant="dark">
                                                                    <tbody>
                                                                    <tr>
                                                                        <td>{t("Gender")}</td>
                                                                        <td>{t("Age")}</td>
                                                                        <td>{t("Normal Values")}</td>
                                                                        <td>{t("Label")}</td>
                                                                    </tr>
                                                                    {testDetail.normal_values.map((normal_value, index) => (
                                                                        <tr>
                                                                            <td>{normal_value.gender}</td>
                                                                            <td>{normal_value.from_age} - {normal_value.to_age}</td>
                                                                            <td>{normal_value.minimum_value} - {normal_value.maximum_value}</td>
                                                                            <td>{normal_value.label}</td>
                                                                        </tr>
                                                                    ))}
                                                                    </tbody>
                                                                </Table>
                                                            </td>
                                                            <td>
                                                                <a
                                                                    onClick={() => removeTest(index)}
                                                                    className="btn btn-icon btn-light btn-hover-danger btn-sm">
                                                            <span className="svg-icon svg-icon-md svg-icon-danger">
                                                              <SVG
                                                                  src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")}/>
                                                            </span>
                                                                </a>
                                                            </td>
                                                        </tr>
                                                    ))

                                                ) : ("")}
                                                </tbody>
                                            </Table>
                                        </Col>
                                    </Row>

                                    <Button variant="primary" className="float-right" type="submit"
                                            disabled={disabled}>
                                        {t("Save")}
                                        <span
                                            dangerouslySetInnerHTML={{__html: disabled ? `<i class='fas fa-spinner fa-spin'></i>` : ``}}/>
                                    </Button>
                                </Form>

                            </div>

                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <
            />
            )
            ;
            }