import React, {useEffect, useState, useRef} from "react";
import axios from "axios";
import $ from "jquery";
import DataTable from "datatables.net";
import {Link} from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import {useHistory} from 'react-router';
import {toAbsoluteUrl} from "../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import alertify from "alertifyjs";
import {date_format, datetime_format} from "../Common/DateFormat";

import {API_URL} from '../constants.js';

import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import {useTranslation} from "react-i18next";

export function TestDetailNormalRangesList(props) {
  const {t} = useTranslation();
  const [normalRanges, setNormalRanges] = useState([]);
  const [testDetail, setTestDetail] = useState(-1);
  const [testObject, setTestObject] = useState(-1);
  const [show, setShow] = useState(false);
  const [showShowHideModal, setShowShowHideModal] = useState(false);
  const history = useHistory();
  const [disabled, setDisabled] = useState(0);
  const [iconDisabled, setIconDisabled] = useState(1);

  const setCurrentTest = (test, type) => {
    // setTest(test);
    setTestObject(testDetail.find(t => t.id == test));
    if (type == 'delete') {
      setShow(true);
    } else {
      setShowShowHideModal(true);
    }
  };

  const handleClose = () => {
    setShow(false);
    setShowShowHideModal(false);
  }

  const deleteTest = () => {
    setDisabled(1);
    setIconDisabled(0);
    axios.post(
      API_URL + 'test/' + test + '/delete'
    ).then((response) => {
      if (response.data.status) {
        history.go(0);
        setShow(false);
        alertify.success(response.data.message);
      } else {
        setDisabled(0);
        setIconDisabled(1);
        alertify.error(response.data.message);
        return null;
      }
    }).catch((error) => {
      setDisabled(0);
      setIconDisabled(1);
      alertify.error("Something went wrong. Try again!");
    });
  }

  const updateLowText = (index, text) => {
    normalRanges[index].low_text = text;
    setNormalRanges(normalRanges);
  }

  const updateHighText = (index, text) => {
    normalRanges[index].high_text = text;
    setNormalRanges(normalRanges);
  }

  const updateNormalRangeField = (index, value, field) => {
    normalRanges[index][field] = value;
    setNormalRanges(normalRanges);
  }

  const updateNormalRange = (index) => {
    setDisabled(1);
    setIconDisabled(0);
    axios.post(
      API_URL + 'test/update-normal-range', normalRanges[index]
    ).then((response) => {
      if (response.data.status) {
        // history.go(0);
        setShow(false);
        alertify.success(response.data.message);
      } else {
        setDisabled(0);
        setIconDisabled(1);
        alertify.error(response.data.message);
        return null;
      }
    }).catch((error) => {
      setDisabled(0);
      setIconDisabled(1);
      alertify.error("Something went wrong. Try again!");
    });
  }

  useEffect(() => {

    axios.get(API_URL + 'test/normal-ranges').then((response) => {
      if (response.data.status) {

        setNormalRanges(response.data.data);

        $("#test_table").DataTable();
      } else {
        alertify.error(response.data.message);
        return null;
      }
    }).catch((error) => {
      alertify.error("Something went wrong. Try again!");
    });

  }, []);


  return (
    <>
      <Row>
        <Col md={12}>
          <Card>
            <CardHeader title={t("Normal Ranges List")}>
              <CardHeaderToolbar>

              </CardHeaderToolbar>
            </CardHeader>
            <CardBody style={{overflow: 'auto'}}>

              <table id="test_table" className="stratprop_datatable table table-bordered table-striped">
                <thead>
                <tr>
                  <th>{t("SN.")}</th>
                  <th>{t("Sub Test / Test")}</th>
                  <th>{t("Range")}</th>
                  <th>{t("Text")}</th>
                  <th>{t("Actions")}</th>
                </tr>
                </thead>
                <tbody>
                {normalRanges.length > 0 ? (
                  normalRanges.map((normal_range, index) => (
                    <tr key={normal_range.id}>
                      <td>{index + 1}</td>
                      <td>
                        <strong>{normal_range.test_detail.name}</strong>
                        <br/>
                        <span>{normal_range.test_detail.test.name}</span>
                      </td>
                      <td>
                        <Form.Row>
                          <Form.Group as={Col}>
                            <Form.Label>{t("Label")}</Form.Label>
                            <Form.Control defaultValue={normal_range.label}
                                          onKeyUp={(e) => updateNormalRangeField(index, e.target.value, 'label')}/>
                          </Form.Group>
                          <Form.Group as={Col}>
                            <Form.Label>{t("From Age")}</Form.Label>
                            <Form.Control defaultValue={normal_range.from_age}
                                          onKeyUp={(e) => updateNormalRangeField(index, e.target.value, 'from_age')}/>
                          </Form.Group>
                          <Form.Group as={Col}>
                            <Form.Label>{t("To Age")}</Form.Label>
                            <Form.Control defaultValue={normal_range.to_age}
                                          onKeyUp={(e) => updateNormalRangeField(index, e.target.value, 'to_age')}
                            />
                          </Form.Group>
                        </Form.Row>
                        <hr/>
                        <Form.Row>
                          <Form.Group as={Col}>
                            <Form.Label>{t("Gender")}</Form.Label>
                            <Form.Control as="select"
                                          defaultValue={normal_range.gender}
                                          onChange={(e) => updateNormalRangeField(index, e.target.value, 'gender')}>
                              <option value="male">{t("Male")}</option>
                              <option value="female">{t("Female")}</option>
                              <option value="na">{t("Not Answered")}</option>
                              <option value="default">{t("Default")}</option>
                            </Form.Control>

                          </Form.Group>

                          <Form.Group as={Col}>
                            <Form.Label>{t("Borderline Type")}</Form.Label>
                            <Form.Control as="select"
                                          defaultValue={normal_range.borderline_type}
                                          onChange={(e) => updateNormalRangeField(index, e.target.value, 'borderline_type')}>
                              <option value="">{t("No")}</option>
                              <option value="low">{t("Low")}</option>
                              <option value="high">{t("High")}</option>
                            </Form.Control>
                          </Form.Group>
                        </Form.Row>
                        <Form.Row>
                          <Form.Group as={Col}>
                            <Form.Label>{t("Borderline")}</Form.Label>
                            <Form.Control defaultValue={normal_range.borderline_value}
                                          onKeyUp={(e) => updateNormalRangeField(index, e.target.value, 'borderline_value')}/>
                          </Form.Group>
                          <Form.Group as={Col}>
                            <Form.Label>{t("High")}</Form.Label>
                            <Form.Control defaultValue={normal_range.maximum_value}
                                          onKeyUp={(e) => updateNormalRangeField(index, e.target.value, 'maximum_value')}/>
                          </Form.Group>
                          <Form.Group as={Col}>
                            <Form.Label>{t("Low")}</Form.Label>
                            <Form.Control defaultValue={normal_range.minimum_value}
                                          onKeyUp={(e) => updateNormalRangeField(index, e.target.value, 'minimum_value')}
                            />
                          </Form.Group>
                        </Form.Row>


                      </td>

                      <td>
                        <Form.Row>
                          <Form.Group as={Col}>
                            <Form.Label>{t("Low")}</Form.Label>
                            <Form.Control as="textarea"
                                          rows={12}
                                          cols={60}
                                          defaultValue={normal_range.low_text}
                                          onKeyUp={(e) => updateNormalRangeField(index, e.target.value, 'low_text')}/>
                          </Form.Group>
                          <Form.Group as={Col}>
                            <Form.Label>{t("High")}</Form.Label>
                            <Form.Control as="textarea"
                                          rows={12}
                                          cols={60}
                                          defaultValue={normal_range.high_text}
                                          onKeyUp={(e) => updateNormalRangeField(index, e.target.value, 'high_text')}/>

                          </Form.Group>
                          <Form.Group as={Col}>
                            <Form.Label>{t("Borderline")}</Form.Label>
                            <Form.Control as="textarea"
                                          rows={12}
                                          cols={60}
                                          defaultValue={normal_range.borderline_text}
                                          onKeyUp={(e) => updateNormalRangeField(index, e.target.value, 'borderline_text')}/>
                          </Form.Group>
                          <Form.Group as={Col}>
                            <Form.Label>{t("Normal")}</Form.Label>
                            <Form.Control as="textarea"
                                          rows={12}
                                          cols={60}
                                          defaultValue={normal_range.normal_text}
                                          onKeyUp={(e) => updateNormalRangeField(index, e.target.value, 'normal_text')}/>
                          </Form.Group>
                        </Form.Row>
                      </td>
                      <td>
                        <a
                          onClick={() => updateNormalRange(index)}
                          key={"edit-test-" + normal_range.id}
                          className="btn btn-primary btn-hover-primary btn-sm mr-2">
                          Save
                        </a>
                      </td>

                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={8}>{t("No Test")}</td>
                  </tr>
                )}
                </tbody>
              </table>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  )
}