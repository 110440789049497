import React, {useEffect, useState, useRef} from "react";
import axios from "axios";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import alertify from "alertifyjs";
import {API_URL} from "../constants.js";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'


import {
    Card,
    CardBody,
    CardHeader,
    CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

export function EditLab(props) {
    const { t } = useTranslation();
    const name = useRef();
    const short_name = useRef();
    const phone_no1 = useRef();
    const phone_no2 = useRef();
    const address = useRef();
    const slogan = useRef();
    const certificate_no = useRef();

    const [disabled, setDisabled] = useState(0);
    const [iconDisabled, setIconDisabled] = useState(1);
    const [lab, setLab] = useState({});
    const id = props.match.params.id;
    const [logo, setLogo] = useState('');
    const [logoUrl, setLogoUrl] = useState('');
    const [header, setHeader] = useState('');
    const [headerUrl, setHeaderUrl] = useState('');
    const [footer, setFooter] = useState('');
    const [footerUrl, setFooterUrl] = useState('');

    const handleLogo = (e) => {
        setLogo(e.target.files[0]);
        setLogoUrl("false");
    }
    const handleHeader = (e) => {
        setHeader(e.target.files[0]);
        setHeaderUrl("false");
    }
    const handleFooter = (e) => {
        setFooter(e.target.files[0]);
        setFooterUrl("false");
    }

    useEffect(() => {
        axios.get(API_URL + 'lab/' + id).then((response) => {
            if (response.data.status) {
                setLab(response.data.data);
                console.log(response.data.data.logo_url);
                setLogoUrl(response.data.data.logo_url);
                setHeaderUrl(response.data.data.header_url);
                setFooterUrl(response.data.data.footer_url);
            } else {
                alertify.error(response.data.message);
                return null;
            }
        })
    }, []);


    const updateLabHandler = (e) => {

        e.preventDefault();
        const formData = new FormData();
        formData.append('name', name.current.value);
        formData.append('short_name', short_name.current.value);
        formData.append('phone_no1', phone_no1.current.value);
        formData.append('phone_no2', phone_no2.current.value);
        formData.append('address', address.current.value);
        formData.append('slogan', slogan.current.value);
        formData.append('certificate_no', certificate_no.current.value);
        formData.append('logo', logo);
        formData.append('header', header);
        formData.append('footer', footer);
        formData.append('id',1);

        axios.post(API_URL + "lab/update",
            formData,
            {
                'content-type': 'multipart/form-data'
            }
        )
            .then((response) => {
                if (response.data.status) {
                    alertify.success(response.data.message);


                } else {
                    alertify.error(response.data.message);
                    return null;
                }
            })
            .catch((error) => {

                if (error.message === 'Request failed with status code 401') {
                    //props.logout();
                }
            });
    }


    return (
        <Row>
            <Col md={12}>
                <Card>
                    <CardHeader title={t("Lab Setup")}>

                    </CardHeader>
                    <CardBody>

                        <div className="mt-5">
                            <Form onSubmit={updateLabHandler}>
                                <Form.Row>
                                    <Form.Group as={Col}>
                                        <Form.Label>{t("Name")}</Form.Label>
                                        <Form.Control ref={name} placeholder={t("Enter Lab Name")}
                                                      defaultValue={lab.name}/>
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>{t("Short Name")}</Form.Label>
                                        <Form.Control ref={short_name} rows={1} placeholder={t("Enter Short Name")}
                                                      defaultValue={lab.short_name}/>
                                    </Form.Group>

                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col}>
                                        <Form.Label>{t("Phone No 1")}</Form.Label>
                                        <Form.Control ref={phone_no1} placeholder={t("Enter Phone No 1")}
                                                      defaultValue={lab.phone_no1}/>
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>Phone No 2</Form.Label>
                                        <Form.Control ref={phone_no2} rows={1} placeholder={t("Enter Phone No 2")}
                                                      defaultValue={lab.phone_no2}/>
                                    </Form.Group>

                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col}>
                                        <Form.Label>{t("Address")}</Form.Label>
                                        <Form.Control ref={address} rows={1} placeholder={t("Enter Address")}
                                                      defaultValue={lab.address}/>
                                    </Form.Group>

                                    <Form.Group as={Col}>
                                        <Form.Label>{t("Slogan")}</Form.Label>
                                        <Form.Control ref={slogan} rows={1} placeholder={t("Enter Slogan")}
                                                      defaultValue={lab.slogan}/>
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col}>
                                        <Form.Label>{t("Certificate No")}</Form.Label>
                                        <Form.Control ref={certificate_no} rows={1} placeholder={t("Enter Certificate No")}
                                                      defaultValue={lab.certificate_no}/>
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                    </Form.Group>

                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col}>
                                        <Form.Label>{t("Logo")}</Form.Label>
                                        <Row>
                                            <Col>
                                                <Form.Control type="file" accept="image/gif, image/jpeg, image/png"   onChange={handleLogo}/>
                                                <img style={{maxHeight:'250px',width:'100%'}} src={logo? URL.createObjectURL(logo) : null} alt={logo? logo.name : null}/>
                                                <img style={{maxHeight:'250px',width:'100%'}} src={logoUrl}/>
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>{t("Header")}</Form.Label>
                                        <Row>
                                            <Col>
                                                <Form.Control  type="file" accept="image/gif, image/jpeg, image/png" onChange={handleHeader}/>
                                                <img style={{maxHeight:'250px',width:'100%'}}  src={header? URL.createObjectURL(header) : null} alt={header? header.name : null}/>
                                                <img style={{maxHeight:'250px',width:'100%'}} src={headerUrl}/>
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>{t("Footer")}</Form.Label>
                                        <Row>
                                            <Col>
                                                <Form.Control type="file" accept="image/gif, image/jpeg, image/png" onChange={handleFooter}/>
                                                <img style={{maxHeight:'250px',width:'100%'}}  src={footer? URL.createObjectURL(footer) : null} alt={footer? footer.name : null}/>
                                                <img style={{maxHeight:'250px',width:'100%'}} src={footerUrl}/>
                                            </Col>
                                        </Row>
                                    </Form.Group>

                                </Form.Row>

                                <Button variant="primary" className="float-right" type="submit" disabled={disabled}>
                                    Save
                                    <span
                                        dangerouslySetInnerHTML={{__html: disabled ? `<i class='fas fa-spinner fa-spin'></i>` : ``}}/>
                                </Button>
                            </Form>

                        </div>

                    </CardBody>
                </Card>
            </Col>
        </Row>
    );
}