import React, {useEffect, useState, useRef} from "react";
import axios from "axios";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import alertify from "alertifyjs";
import {API_URL} from "../constants.js";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {
    Card,
    CardBody,
    CardHeader,
    CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

export function CreateUser(props) {
    const { t } = useTranslation();
    const first_name = useRef();
    const last_name = useRef();
    const email = useRef();
    const password = useRef();
    const username = useRef();
    const address = useRef();
    const phone = useRef();
    const notes = useRef();
    const collection_center_id = useRef();
    const role_id = useRef();

    const [disabled, setDisabled] = useState(0);
    const [iconDisabled, setIconDisabled] = useState(1);
    const [collectionCenters, setCollectionCenters] = useState([]);
    const [roles, setRoles] = useState([]);

    useEffect(() => {
        axios.get(API_URL + 'collection-center').then((response) => {

            if (response.data.status) {
                setCollectionCenters(response.data.data);
            } else if (response.data.code === 404) {
                alertify.error(response.data.message);
                return null;
            }
        });
    }, []);

    useEffect(() => {
        axios.get(API_URL + 'role').then((response) => {

            if (response.data.status) {
                setRoles(response.data.data);
            } else if (response.data.code === 404) {
                alertify.error(response.data.message);
                return null;
            }
        });
    }, []);

    const createUserHandler = (e) => {
        e.preventDefault();
        setDisabled(1);
        setIconDisabled(0);
        axios.post(API_URL + "user/create",
            {
                first_name: first_name.current.value,
                last_name: last_name.current.value,
                email: email.current.value,
                password: password.current.value,
                username: username.current.value,
                address: address.current.value,
                phone: phone.current.value,
                notes: notes.current.value,
                collection_center_id: collection_center_id.current.value,
                role_id: role_id.current.value,
            }
        ).then((response) => {
            setDisabled(0);
            setIconDisabled(1);
            if (response.data.status) {
                alertify.success(response.data.message);

                props.history.push("/user/list");
            } else {
                alertify.error('Please fill form correctly');
                return null;
            }
        }).catch((error) => {
            setDisabled(0);
            setIconDisabled(1);
            if (error.message === 'Request failed with status code 401') {
                //props.logout();
            }
        });
    }
    return (
        <>
            <Row>
                <Col md={12}>
                    <Card>
                        <CardHeader title={t("Add New User")}>
                            <CardHeaderToolbar>
                                <Link to="/user/list" className="btn btn-primary btn-sm mx-3">
                                    {t("User List")}
                                </Link>
                            </CardHeaderToolbar>
                        </CardHeader>
                        <CardBody>

                            <div className="mt-5">
                                <Form onSubmit={createUserHandler}>
                                    <Form.Row>
                                        <Form.Group as={Col}>
                                            <Form.Label>{t("First Name")}</Form.Label>
                                            <Form.Control ref={first_name} placeholder={t("Enter First Name")}/>
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Form.Label>{t("Last Name")}</Form.Label>
                                            <Form.Control ref={last_name} rows={1} placeholder={t("Enter Last Name")}/>
                                        </Form.Group>

                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col}>
                                            <Form.Label>{t("Collection Centers")}</Form.Label>
                                            <Form.Control as="select" ref={collection_center_id}>
                                                {collectionCenters.map((collectionCenters, key) => (
                                                    <option key={collectionCenters.id} value={collectionCenters.id}>
                                                        {collectionCenters.name}
                                                    </option>
                                                ))}
                                            </Form.Control>
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Form.Label>{t("Role")}</Form.Label>
                                            <Form.Control as="select" ref={role_id}>
                                                {roles.map((role, key) => (
                                                    <option key={role.id} value={role.id}>
                                                        {role.name}
                                                    </option>
                                                ))}
                                            </Form.Control>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col}>
                                            <Form.Label>{t("Email")}</Form.Label>
                                            <Form.Control ref={email} placeholder={t("Enter Email")}/>
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Form.Label>{t("Phone")}</Form.Label>
                                            <Form.Control ref={phone} rows={1} placeholder={t("Enter Phone")}/>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col}>
                                            <Form.Label>{t("User Name")}</Form.Label>
                                            <Form.Control ref={username} rows={1} placeholder={t("Enter User Name")}/>
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Form.Label>{t("Password")}</Form.Label>
                                            <Form.Control type={'password'} ref={password} rows={1} placeholder={t("Enter Password")}/>
                                        </Form.Group>

                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col}>
                                            <Form.Label>{t("Address")}</Form.Label>
                                            <Form.Control ref={address} rows={1} placeholder={t("Enter Address")}/>
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Form.Label>{t("Notes")}</Form.Label>
                                            <Form.Control ref={notes} rows={1} placeholder={t("Enter Notes")}/>
                                        </Form.Group>
                                    </Form.Row>

                                    <Button variant="primary" className="float-right" type="submit" disabled={disabled}>
                                            {t("Save")}
                                        <span
                                            dangerouslySetInnerHTML={{__html: disabled ? `<i class='fas fa-spinner fa-spin'></i>` : ``}}/>
                                    </Button>
                                </Form>

                            </div>

                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    );
}