import {Link} from "react-router-dom";
import React from "react";

export const translationEn = {
  "Projects Per Day (Last 7 )": "Projects Per Day (Last 7 )",
  "Projects Per Month (Last 12 Months)": "Projects Per Day (Last 7 Days)",
  "Total Users": "Total Users",
  "Total Projects": "Total Projects",
  "Total portfolios": "Total portfolios",
  "Total Agents": "Total Agents",
  "Add New Antibiotic": "Add New Antibiotic",
  "Antibiotic List": "Antibiotic List",
  "Title": "Title",
  "Enter Antibiotic Title": "Enter Antibiotic Title",
  "Save": "Save",
  "Name": "Name",
  "No Antibiotic": "No Antibiotic",
  "Edit Antibiotic": "Edit Antibiotic",
  "Live Chat": "Live Chat",
  "Live Chat": "Live Chat",
  "No Message": "No Message",
  "Type here": "Type here",
  "Add New Collection Center": "Add New Collection Center",
  "Collection Center List": "Collection Center List",
  "Enter Collection Center Name": "Enter Collection Center Name",
  "Phone NO 1": "Phone NO 1",
  "Enter Phone No 1": "Enter Phone No 1",
  "Enter Phone No": "Enter Phone No",
  "Phone No 2": "Phone No 2",
  "Enter Phone No 2": "Enter Phone No 2",
  "Address": "Address",
  "Enter Address": "Enter Address",
  "Latitude": "Latitude",
  "Longitude": "Longitude",
  "Select Lab": "Select Lab",
  "Lab": "Lab",
  "Delete Collection Center": "Delete Collection Center",
  "Are you sure you want to Delete?": "Are you sure you want to Delete?",
  "Close": "Close",
  "Confirm": "Confirm",
  "Edit Collection Center": "Edit Collection Center",
  "SN": "SN",
  "Email": "Email",
  "Phone": "Phone",
  "Message": "Message",
  "No Contact": "No Contact",
  "Add New Equity & Cashflow": "Add New Equity & Cashflow",
  "Date": "Date",
  "Enter date": "Enter date",
  "Pdf": "Pdf",
  "Equity Amount": "Equity Amount",
  "Enter equity": "Enter equity",
  "Casflow": "Casflow",
  "Enter cashflow": "Enter cashflow",
  "Equity & Cashflow": "Equity & Cashflow",
  "Add New": "Add New",
  "Equity": "Equity",
  "Last Udpate": "Last Udpate",
  "Set Target": "Set Target",
  "Equity Target": "Equity Target",
  "Casflow Target": "Casflow Target",
  "Files": "Files",
  "Equities": "Equities",
  "Amount": "Amount",
  "Added By": "Added By",
  "Actions": "Actions",
  "No Equity": "No Equity",
  "Notes": "Notes",
  "Delete Task": "Delete Task",
  "Save Changes": "Save Changes",
  "Add New File": "Add New File",
  "File": "File",
  "Add Item Consumption": "Add Item Consumption",
  "Patients List": "Patients List",
  "Item": "Item",
  "Select Item": "Select Item",
  "Quantity": "Quantity",
  "Lot Number": "Lot Number",
  "Type": "Type",
  "Add": "Add",
  "Add New Item": "Add New Item",
  "Item List": "Item List",
  "Price": "Price",
  "Enter Item Name": "Enter Item Name",
  "Enter Price": "Enter Price",
  "Max Stock": "Max Stock",
  "Min Stock": "Min Stock",
  "Pieces": "Pieces",
  "Item Unit": "Item Unit",
  "Item Type": "Item Type",
  "Select Item Unit": "Select Item Unit",
  "Select Item Type": "Select Item Type",
  "No Item": "No Item",
  "Add New Item Type": "Add New Item Type",
  "Item Type List": "Item Type List",
  "Enter Notes": "Enter Notes",
  "Enter Item Type Name": "Enter Item Type Name",
  "Create Item Type": "Create Item Type",
  "Enter Item Type Name": "Enter Item Type Name",
  "Enter Item Type Notes": "Enter Item Type Notes",
  "Add New Item Unit": "Add New Item Unit",
  "Item Unit List": "Item Unit List",
  "Enter Item Unit": "Enter Item Unit",
  "Create Item Unit": "Create Item Unit",
  "Edit Item Unit": "Edit Item Unit",
  "Add New Patient": "Add New Patient",
  "First Name": "First Name",
  "Last Name": "Last Name",
  "Guardian Name": "Guardian Name",
  "Gender": "Gender",
  "Male": "Male",
  "Female": "Female",
  "Not Answered": "Not Answered",
  "Date Of Birth": "Date Of Birth",
  "Marital Status": "Marital Status",
  "Married": "Married",
  "Single": "Single",
  "Widow": "Widow",
  "Divorced": "Divorced",
  "Registration Date": "Receipt date and time",
  "Collection Date": "Collection date and time",
  "References": "Requested clinician name",
  "No Reference": "No Requested clinician",
  "Collection Center": "Collection Center",
  "No Collection Center": "No Collection Center",
  "Test Name": "Test Name",
  "Sample": "Sample",
  "Turnaround Time": "Turnaround Time",
  "Amount": "Amount",
  "Net Amount": "Net Amount",
  "Paid": "Paid",
  "Due Amount": "Due Amount",
  "Mr.No": "Mr.No",
  "Status": "Status",
  "Delete Patient / Report": "Delete Patient / Report",
  "Add New Unit": "Add New Unit",
  "Patient List": "Patient List",
  "Mr. Number": "Mr. Number",
  "Enter Unit Title": "Enter Unit Title",
  "Select a Supplier": "Select a Supplier",
  "Item": "Article",
  "Purchase List": "Purchase List",
  "No Purchase": "No Purchase",
  "Supplier": "Supplier",
  "Add New Referral": "Add New Referral",
  "Reference List": "Requested clinician List",
  "Enter Referral Name": "Enter Referral Name",
  "Referral Rate": "Referral Rate",
  "Delete Reference": "Delete Requested clinician",
  "Enter Reference Name": "Enter Requested clinician Name",
  "Enter Reference Phone No": "Enter Requested clinician Phone No",
  "Enter Reference Notes": "Enter Requested clinician Notes",
  "Add New Report Remarks": "Add New Report Remarks",
  "Report Remarks List": "Report Remarks List",
  "Enter Report Remarks": "Enter Report Remarks",
  "Create Report Remarks": "Create Report Remarks",
  "No Report Remarks": "No Report Remarks",
  "Delete Report Remarks": "Delete Report Remarks",
  "Edit Report Remarks": "Edit Report Remarks",
  "Add New Role": "Add New Role",
  "Role List": "Role List",
  "Enter Role Name": "Enter Role Name",
  "Create Role": "Create Role",
  "No Role": "No Role",
  "Edit Role": "Edit Role",
  "Delete Role": "Delete Role",
  "Add New Sample Type": "Add New Sample Type",
  "Sample Type List": "Sample Type List",
  "Blood": "Blood",
  "Enter Sample Type Blood": "Enter Sample Type Blood",
  "Create Sample Type": "Create Sample Type",
  "No Sample Type": "No Sample Type",
  "Delete Sample Type": "Delete Sample Type",
  "Add New Supplier": "Add New Supplier",
  "Supplier List": "Supplier List",
  "Tax No": "Tax No",
  "Enter Tax No": "Enter Tax No",
  "Enter Supplier Name": "Enter Supplier Name",
  "Create Supplier": "Create Supplier",
  "No Supplier": "No Supplier",
  "Delete Supplier": "Delete Supplier",




















}