import React, {useEffect, useState, useRef} from "react";
import axios from "axios";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import alertify from "alertifyjs";
import {API_URL} from "../constants.js";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'


import {
    Card,
    CardBody,
    CardHeader,
    CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

export function EditTransaction(props) {
    const {t} = useTranslation();
    const [transactionType, setTransactionType] = useState(props.match.params.type.charAt(0).toUpperCase() + props.match.params.type.slice(1));
    const date = useRef();
    const notes = useRef();
    const amount = useRef();
    const transaction_category_id = useRef();
    const [disabled, setDisabled] = useState(0);
    const [iconDisabled, setIconDisabled] = useState(1);
    const [transaction, setTransaction] = useState({});
    const [transactionCategorys, setTransactionCategorys] = useState([]);
    const id = props.match.params.id;

    const [fieldType, setFieldsType] = useState({
        'transaction_category_id': ''
    })


    useEffect(() => {
        axios.get(API_URL + 'transactions/' + id).then((response) => {
            if (response.data.status) {
                setTransaction(response.data.data);
                setFieldsType({transaction_category_id: response.data.data.transaction_category_id});
            } else {
                alertify.error(response.data.message);
                return null;
            }
        })
    }, []);

    useEffect(() => {
        axios.get(API_URL + 'transaction-category/' + props.match.params.type).then((response) => {

            if (response.data.status) {
                setTransactionCategorys(response.data.data);
            } else if (response.data.code === 404) {
                alertify.error(response.data.message);
                return null;
            }
        });
    }, []);


    const updateTransactionHandler = (e) => {
        e.preventDefault();
        setDisabled(1);
        setIconDisabled(0);
        axios.post(API_URL + "transaction/update",
            {
                id: transaction.id,
                date: date.current.value,
                amount: amount.current.value,
                notes: notes.current.value,
                transaction_category_id: transaction_category_id.current.value,
            }
        ).then((response) => {
            setDisabled(0);
            setIconDisabled(1);
            if (response.data.status) {
                alertify.success(response.data.message);

                props.history.push("/transaction/list/" + props.match.params.type);
            } else {
                alertify.error(response.data.message);
                return null;
            }
        }).catch((error) => {
            setDisabled(0);
            setIconDisabled(1);
            if (error.message === 'Request failed with status code 401') {
                //props.logout();
            }
        });
    }

    const handletypeChange = (e) => {
        setFieldsType({
            transaction_category_id: e.target.value
        });
    }


    return (
        <Row>
            <Col md={12}>
                <Card>
                    <CardHeader title={t("Edit Transaction")}>
                        <CardHeaderToolbar>
                            <Link to={`/transaction/list/${props.match.params.type}`}
                                  className="btn btn-primary btn-sm mx-3">
                                {transactionType} List
                            </Link>
                        </CardHeaderToolbar>
                    </CardHeader>
                    <CardBody>

                        <div className="mt-5">
                            <Form onSubmit={updateTransactionHandler}>
                                <Form.Row>
                                    <Form.Group className={'col-12 col-md-6'}>
                                        <Form.Label>{transactionType} Category</Form.Label>
                                        <Form.Control as="select" ref={transaction_category_id}
                                                      value={fieldType.transaction_category_id}
                                                      onChange={handletypeChange}>
                                            <option value="">Select Transaction Category</option>
                                            {transactionCategorys.map((transactionCategory, key) => (
                                                <option key={transactionCategory.id} value={transactionCategory.id}>
                                                    {transactionCategory.name}
                                                </option>
                                            ))}
                                        </Form.Control>
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group className={'col-12 col-md-6'}>
                                        <Form.Label>{t("Name")}</Form.Label>
                                        <Form.Control type={"date"} ref={date}
                                                      defaultValue={transaction.date}/>
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group className={'col-12 col-md-6'}>
                                        <Form.Label>{t("Amount")}</Form.Label>
                                        <Form.Control type={'number'} ref={amount}
                                                      placeholder={t("Enter Amount")}
                                                      defaultValue={transaction.amount}/>
                                    </Form.Group>
                                </Form.Row>

                                <Form.Row>
                                    <Form.Group className={'col-12 col-md-6'}>
                                        <Form.Label>{t("Notes")}</Form.Label>
                                        <Form.Control as="textarea" ref={notes}
                                                      placeholder={t("Enter Transaction Notes")}
                                                      rows={5}
                                                      defaultValue={transaction.notes}/>
                                    </Form.Group>
                                </Form.Row>
                                <Button variant="primary" className="float-right" type="submit" disabled={disabled}>
                                    {t("Save")}
                                    <span
                                        dangerouslySetInnerHTML={{__html: disabled ? `<i class='fas fa-spinner fa-spin'></i>` : ``}}/>
                                </Button>
                            </Form>

                        </div>

                    </CardBody>
                </Card>
            </Col>
        </Row>
    );
}