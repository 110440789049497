import React, {useEffect, useState, useRef} from "react";
import {toAbsoluteUrl} from "../../../_metronic/_helpers";
import axios from "axios";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import alertify from "alertifyjs";
import {API_URL} from "../constants.js";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {
    Card,
    CardBody,
    CardHeader,
    CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import {Link} from "react-router-dom";
import {AutoSuggest} from "react-autosuggestions";
import {Table} from "react-bootstrap";
import SVG from "react-inlinesvg";
import {useTranslation} from "react-i18next";

export function PurchaseEdit(props) {
    const { t } = useTranslation();
    const id = props.match.params.id;
    const date = useRef();
    const supplier_id = useRef();
    const notes = useRef();
    const quantity = useRef();
    const expiry_date = useRef();
    const amount = useRef();
    const lot_number = useRef();

    const [disabled, setDisabled] = useState(0);
    const [iconDisabled, setIconDisabled] = useState(1);
    const [suppliers, setSuppliers] = useState([]);
    const [options, setOptions] = useState(["initialvalue"]);
    const [letters, setLetters] = useState('');
    const [items, setItems] = useState([]);
    const [selectedItem, setSelectedItem] = useState([]);
    const [purchase, setPurchase] = useState([]);
    const [supplierDropdown, setSupplierDropdown] = useState(0);

    const updatePurchaseHandler = (e) => {

        e.preventDefault();
        if (!items.length) {
            alertify.error("Please choose atleast one item");
            return false;
        }
        setDisabled(1);
        setIconDisabled(0);
        axios.post(API_URL + "purchase/update",
            {
                id: id,
                date: date.current.value,
                supplier_id: supplier_id.current.value,
                notes: notes.current.value,
                purchase_detail: items
            }
        ).then((response) => {
            setDisabled(0);
            setIconDisabled(1);
            if (response.data.status) {
                alertify.success(response.data.message);

                //props.history.push("/unit/list");
            } else {
                alertify.error(response.data.message);
                return null;
            }
        }).catch((error) => {
            setDisabled(0);
            setIconDisabled(1);
            if (error.message === 'Request failed with status code 401') {
                //props.logout();
            }
        });
    }

    const addToTableHandler = (e) => {
        let temp = selectedItem;
        temp.expiry_date = expiry_date.current.value;
        temp.quantity = quantity.current.value;
        temp.amount = amount.current.value;
        temp.total_quantity = parseFloat(quantity.current.value) * parseFloat(temp.pieces);
        temp.lot_number = lot_number.current.value;
        expiry_date.current.value = '';
        quantity.current.value = '';
        amount.current.value = '';
        lot_number.current.value = '';
        document.getElementById("Item-input").focus();

        if (typeof temp.name !== 'undefined' && temp.quantity != '') {
            setSelectedItem([]);
            setItems([...items, temp]);
            setLetters('');
        }

    }

    const handleSupplierChange = (e) => {
        setSupplierDropdown(e.target.value);
    }

    const handleAutoComplete = (letters) => {
        setLetters(letters);
        if (letters != '') {

            axios.post(API_URL + "item/search", {
                name: letters,
            }).then((response) => {
                // console.log(response.data);
                if (response.data.data.length > 0) {
                    var names = [];
                    response.data.data.map((item) => {
                        names.push(item.name);
                    });
                    setOptions(names);

                    var selectedIndex = names.indexOf(letters);
                    if (selectedIndex != -1) {

                        var selectedItem = {
                            id: response.data.data[selectedIndex].id,
                            name: response.data.data[selectedIndex].name,
                            price: response.data.data[selectedIndex].price,
                            pieces: response.data.data[selectedIndex].pieces
                        }
                        setSelectedItem(selectedItem);
                    }
                }
            }).catch((error) => {
                console.log('Nothing found');
            });

        }
    }

    const removeItem = (index) => {
        let arr = items;
        arr = arr.filter((item) => item.name !== index);
        setItems(arr);
    }

    useEffect(() => {

        axios.get(API_URL + 'supplier').then((response) => {
            if (response.data.status) {
                setSuppliers(response.data.data);

            } else {
                alertify.error(response.data.message);
                return null;
            }
        }).catch((error) => {
            alertify.error("Something went wrong. Try again!");
        });

    }, []);

    useEffect(() => {

        axios.get(API_URL + 'purchase/' + id).then((response) => {
           // console.log(response.data);
            if (response.data.status) {
                setPurchase(response.data.data.purchase);
                setSupplierDropdown(response.data.data.purchase.supplier_id);

                var existingPurchaseDetail = response.data.data.purchase.details;
                var existingPurchaseDetailArray = [];
                existingPurchaseDetail.map((detail, index) => {
                    let detailObj = {
                        id: detail.item_id,
                        name: detail.item.name,
                        expiry_date: detail.expiry_date,
                        quantity: detail.quantity,
                        total_quantity: detail.total_quantity,
                        amount: detail.amount,
                        lot_number: detail.lot_number
                    }
                    existingPurchaseDetailArray.push(detailObj);

                });
                setItems(existingPurchaseDetailArray);
                // setItems(response.data.data.purchase.details);

            } else {
                alertify.error(response.data.message);
                return null;
            }
        }).catch((error) => {
            alertify.error("Something went wrong. Try again!");
        });

    }, []);

    return (
        <>
            <Row>
                <Col md={12}>
                    <Card>
                        <CardHeader title={t("Edit Purchase")}>
                            <CardHeaderToolbar>
                                <Link to="/purchase/list" className="btn btn-primary btn-sm mx-3">
                                    {t("Purchase List")}
                                </Link>
                            </CardHeaderToolbar>
                        </CardHeader>
                        <CardBody>
                            <Form onSubmit={updatePurchaseHandler}>
                                <div className="mt-5">
                                    <Form.Row>
                                        <Form.Group as={Col}>
                                            <Form.Label>{t("Date")}</Form.Label>
                                            <Form.Control type={"date"} ref={date} placeholder={t("Enter Date")}  defaultValue={purchase.date}/>
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Form.Label>{t("Select a Supplier")}</Form.Label>
                                            <Form.Control as="select" ref={supplier_id} value={supplierDropdown} onChange={handleSupplierChange}>
                                                {suppliers.map((supplier, index) => (
                                                    <option key={index} value={supplier.id}>{supplier.name}</option>
                                                ))}
                                            </Form.Control>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col}>
                                            <Form.Label>{t("Note")}</Form.Label>
                                            <Form.Control as={"textarea"} ref={notes} defaultValue={purchase.notes}/>
                                        </Form.Group>
                                    </Form.Row>
                                    <Row style={{padding: "20px", backgroundColor: "#0e4e91"}}>
                                        <Col md={3}>
                                            <AutoSuggest
                                                name="Item"
                                                handleChange={(e) => {
                                                    handleAutoComplete(e);
                                                }}
                                                options={options}
                                                value={letters}
                                            />
                                        </Col>
                                        <Col md={2}>
                                            <Form.Label>{t("Quantity")}</Form.Label>
                                            <Form.Control type={"number"} ref={quantity}/>
                                        </Col>
                                        <Col md={2}>
                                            <Form.Label>{t("Amount")}</Form.Label>
                                            <Form.Control type={"number"} ref={amount}/>
                                        </Col>
                                        <Col md={2}>
                                            <Form.Label>{t("Allotment Number")}</Form.Label>
                                            <Form.Control ref={lot_number}/>
                                        </Col>
                                        <Col md={2}>
                                            <Form.Label>{t("Expiry")}</Form.Label>
                                            <Form.Control type={"date"} ref={expiry_date}/>
                                        </Col>
                                        <Col md={1} style={{marginTop: "24px"}}>

                                            <a onClick={addToTableHandler}>
                                                <Button variant="primary">
                                                    {t("ADD TO TABLE")}
                                                </Button>
                                            </a>
                                        </Col>
                                    </Row>
                                </div>
                                <Row>
                                    <Col md={12}>
                                        <Table striped bordered hover>
                                            <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>{t("Item")}</th>
                                                <th>{t("Quantity")}</th>
                                                <th>{t("Total Quanity")}</th>
                                                <th>{t("Amount")}</th>
                                                <th>{t("Allotment Number")}</th>
                                                <th>{t("Expiry")}</th>
                                                <th>{t("Action")}</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {items.map((item, index) => (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{item.name}</td>
                                                    <td>{item.quantity}</td>
                                                    <td>{item.total_quantity}</td>
                                                    <td>{item.amount}</td>
                                                    <td>{item.lot_number}</td>
                                                    <td>{item.expiry_date}</td>
                                                    <td><a
                                                        onClick={() => removeItem(item.name)}
                                                        className="btn btn-icon btn-light btn-hover-danger btn-sm">
													<span className="svg-icon svg-icon-md svg-icon-danger">
													  <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")}/>
													</span>
                                                    </a></td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </Table>
                                    </Col>
                                </Row>
                                <Button variant="primary" className="float-right" type="submit" disabled={disabled}>
                                    {t("Save")}
                                    <span
                                        dangerouslySetInnerHTML={{__html: disabled ? `<i class='fas fa-spinner fa-spin'></i>` : ``}}/>
                                </Button>
                            </Form>

                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    );
}