export const translationFr = {
  "Projects Per Day (Last 7 )": "Projets par jour (7 derniers)",
  "Projects Per Month (Last 12 Months)": "Projets par mois (12 derniers mois)",
  "Total Users": "Nombre total d'utilisateurs",
  "Total Projects": "Nombre total de projets",
  "Total portfolios": "Portefeuilles totaux",
  "Total Agents": "Nombre total d'agents",
  "Add New Antibiotic": "Ajouter un nouvel antibiotique",
  "Antibiotic List": "Liste des antibiotiques",
  "Title": "Titre",
  "Enter Antibiotic Title": "Entrez le titre de l'antibiotique",
  "Save": "Sauvegarder",
  "Create Antibiotic": "Créer un antibiotique",
  "Name": "NOM",
  "No Antibiotic": "Pas d'antibiotique",
  "Edit Antibiotic": "Modifier l'antibiotique",
  "No Message": "Pas de message",
  "Type here": "Écrivez ici",
  "Add New Collection Center": "Ajouter un nouveau centre de collecte",
  "Collection Center List": "Liste des centres de collecte",
  "Enter Collection Center Name": "Entrez le nom du centre de collecte",
  "Phone NO 1": "Téléphone NO 1",
  "Phone No": "Téléphone NO",
  "Enter Phone No 1": "Entrez le numéro de téléphone 1",
  "Phone No 2": "Téléphone NO 2",
  "Enter Phone No 2": "Entrez le numéro de téléphone 2",
  "Address": "Adresse",
  "Enter Address": "Entrer l'adresse",
  "Latitude": "Latitude",
  "Longitude": "Longitude",
  "Select Lab": "Sélectionnez le laboratoire",
  "Lab": "laboratoire",
  "Lab": "laboratoire",
  "Delete Collection Center": "Supprimer le centre de collecte",
  "Are you sure you want to Delete?": "Etes-vous sûr que vous voulez supprimer?",
  "Close": "Fermer",
  "Confirm": "Confirmer",
  "Edit Collection Center": "Modifier le centre de collecter",
  "SN": "SN",
  "Email": "E-mail",
  "Phone": "Téléphoner",
  "Message": "Un message",
  "No Contact": "Pas de contact",
  "Add New Equity & Cashflow": "Ajouter de nouveaux capitaux propres et flux de trésorerie",
  "Date": "Date",
  "Enter date": "Entrer la date",
  "Pdf": "PDF",
  "Equity Amount": "Montant des capitaux propres",
  "Enter equity": "Saisir les capitaux propres",
  "Casflow": "Des flux de trésorerie",
  "Enter cashflow": "Saisir la trésorerie",
  "Equity & Cashflow": "Capitaux propres et flux de trésorerie",
  "Add New": "Ajouter un nouveau",
  "Equity": "Équité",
  "Last Udpate": "Dernière mise à jour",
  "Set Target": "Choisir la cible",
  "Equity Target": "Cible d'équité",
  "Casflow Target": "Objectif de flux de trésorerie",
  "Files": "Des dossiers",
  "Equities": "Actions",
  "Amount": "Montante",
  "Added By": "Ajouté par",
  "Actions": "Actions",
  "No Equity": "Pas d'équité",
  "Notes": "Remarques",
  "Delete Task": "Supprimer la tâche",
  "Save Changes": "Sauvegarder les modifications",
  "Add New File": "Ajouter un nouveau fichier",
  "File": "Déposer",
  "Add Item Consumption": "Ajouter la consommation d'articles",
  "Patients List": "Liste des patients",
  "Item": "Article",
  "Select Item": "Sélectionnez l'élément",
  "Lot Number": "Numéro de lot",
  "Type": "Taper",
  "Add": "Ajouter",
  "Quantity": "Quantité",
  "Add New Item": "Ajoute un nouvel objet",
  "Item List": "Liste des articles",
  "Price": "Prix",
  "Enter Price": "Entreprise",
  "Enter Item Name": "Entrez le nom de l'article",
  "Max Stock": "Stock maximum",
  "Min Stock": "Stock minimum",
  "Pieces": "Pièces",
  "Pieces": "Pièces",
  "Item Unit": "Article Unité",
  "Item Type": "Type d'élément",
  "Select Item Unit": "Sélectionnez l'unité d'article",
  "Select Item Type": "Sélectionnez le type d'article",
  "No Item": "Pas d'objet",
  "Add New Item Type": "Ajouter un nouveau type d'élément",
  "Item Type List": "Liste des types d'articles",
  "Enter Notes": "Saisir des notes",
  "Enter Item Type Name": "Entrez le nom du type d'élément",
  "Create Item Type": "Créer un type d'élément",
  "Enter Item Type Name": "Entrez le nom du type d'élément",
  "Enter Item Type Notes": "Saisir les notes sur le type d'article",
  "Add New Item Unit": "Ajouter un nouvel élément",
  "Item Unit List": "Liste des unités d'article",
  "Enter Item Unit": "Entrer l'unité de l'article",
  "Create Item Unit": "Créer une unité d'article",
  "Edit Item Unit": "Modifier l'unité de l'article",
  "Add New Patient": "Ajouter un nouveau patient",
  "First Name": "Prénom",
  "Last Name": "Nom de famille",
  "Guardian Name": "Nom du gardien",
  "Gender": "Genre",
  "Male": "Mâle",
  "Female": "Femelle",
  "Not Answered": "Non répondu",
  "Date Of Birth": "Date de naissance",
  "Marital Status": "État civil",
  "Married": "Mariée",
  "Single": "Seule",
  "Widow": "Veuve",
  "Divorced": "Divorcée",
  "Registration Date": "Date d'inscription",
  "Collection Date": "Date de collecte",
  "References": "Les références",
  "No Reference": "Aucune référence",
  "Collection Center": "Centre de collecte",
  "No Collection Center": "Pas de centre de collecte",
  "Test Name": "Nom du test",
  "Sample": "Goûter",
  "Turnaround Time": "Délai d'exécution",
  "Amount": "Montant",
  "Net Amount": "Montant net",
  "Paid": "Payé",
  "Due Amount": "Montant dû",
  "Mr.No": "Monsieur Non",
  "Status": "Statut",
  "Delete Patient / Report": "Supprimer le patient/le rapport",
  "Add New Unit": "Ajouter une nouvelle unité",
  "Patient List": "Liste des patients",
  "Mr. Number": "Monsieur Numéro",
  "Enter Unit Title": "Entrez le titre de l'unité",
  "Select a Supplier": "Sélectionnez un fournisseurr",
  "Item": "Item",
  "Purchase List": "Liste d'achat",
  "No Purchase": "Pas d'achate",
  "Supplier": "Fournisseuse",
  "Add New Referral": "Ajouter une nouvelle référence",
  "Reference List": "Liste de référence",
  "Enter Referral Name": "Entrez le nom de la référence",
  "Referral Rate": "Taux de référence",
  "Delete Reference": "Supprimer la référence",
  "Enter Reference Name": "Entrez le nom de la référence",
  "Enter Reference Phone No": "Entrez le numéro de téléphone de référence",
  "Enter Reference Notes": "Saisir les notes de référence",
  "Add New Report Remarks": "Ajouter de nouvelles remarques sur le rapport",
  "Report Remarks List": "Liste des remarques sur le rapport",
  "Enter Report Remarks": "Saisir les remarques du rapport",
  "Create Report Remarks": "Créer des remarques de rapport",
  "No Report Remarks": "Aucune remarque sur le rapport",
  "Delete Report Remarks": "Supprimer les remarques sur le rapport",
  "Edit Report Remarks": "Modifier les remarques du rapport",
  "Add New Role": "Ajouter un nouveau rôle",
  "Role List": "Liste des rôles",
  "Enter Role Name": "Entrez le nom du rôle",
  "Create Role": "Créer un rôle",
  "No Role": "Aucun rôle",
  "Edit Role": "Modifier le rôle",
  "Delete Role": "Supprimer le rôle",
  "Add New Sample Type": "Ajouter un nouveau type d'échantillon",
  "Sample Type List": "Exemple de liste de types",
  "Blood": "Du sang",
  "Enter Sample Type Blood": "Saisir le type d'échantillon Sang",
  "Create Sample Type": "Créer un type d'échantillon",
  "No Sample Type": "Aucun type d'échantillon",
  "Delete Sample Type": "Supprimer le type d'échantillon",
  "Add New Supplier": "Ajouter un nouveau fournisseur",
  "Supplier List": "Liste des fournisseurs",
  "Tax No": "Impôt non",
  "Enter Tax No": "Entrez le numéro de taxe",
  "Enter Supplier Name": "Entrez le nom du fournisseur",
  "Create Supplier": "Créer un fournisseur",
  "No Supplier": "Aucun fournisseur",
  "Delete Supplier": "Supprimer le fournisseur",



}