import React, {useEffect, useState, useRef} from "react";
import axios from "axios";
import $ from "jquery";
import DataTable from "datatables.net";
import {Link} from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import {useHistory} from 'react-router';
import {toAbsoluteUrl} from "../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import alertify from "alertifyjs";
import {date_format, datetime_format} from "../Common/DateFormat";
import PrintIcon from '@material-ui/icons/Print';
import DescriptionIcon from '@material-ui/icons/Description';
import {API_URL, BACKEND_URL} from '../constants.js';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import BeachAccessIcon from '@material-ui/icons/BeachAccess';
import EmailIcon from '@material-ui/icons/Email';

import {
    Card,
    CardBody,
    CardHeader,
    CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import {useTranslation} from "react-i18next";

export function DeletedPatientFilter(props) {
    const {t} = useTranslation();
    const mr = useRef();
    const sample_id = useRef();
    const name = useRef();
    const phone_no = useRef();
    const collection_center_id = useRef();
    const booking = useRef();
    const status = useRef();
    const from_date = useRef();
    const to_date = useRef();
    const changedStatus = useRef();
    const status_created_at = useRef();
    const [reportStatuses, setReportStatuses] = useState([]);
    const notes = useRef();

    const [disabled, setDisabled] = useState(0);
    const [iconDisabled, setIconDisabled] = useState(1);
    const [patients, setPatients] = useState([]);
    const [collectionCenters, setCollectionCenters] = useState([]);
    const [patient, setPatient] = useState(null);
    const [show, setShow] = useState(false);
    const [statusModalShow, setStatusModalShow] = useState(false);
    const [selectedPatientStatus, setSelectedPatientStatus] = useState("0");
    const history = useHistory();
    const [checkedItems, setCheckedItems] = useState({});

    const setCurrentPatient = (patient_id, action) => {
        setPatient(patient_id);
        if (action == 'delete') {
            setShow(true);
        } else if (action == 'status') {
            let patient = patients.find(patient => patient.id == patient_id);
            setSelectedPatientStatus(patient.status);
            setStatusModalShow(true);
        } else if (action == 'mail') {
            setDisabled(1);
            axios.post(
                API_URL + 'patient/send-mail',
                {id: patient_id}
            ).then((response) => {
                setDisabled(0);
                if (response.data.status) {
                    alertify.success(response.data.message);
                } else {
                    alertify.error(response.data.message);
                    return null;
                }
            });
        }
    };

    const handleClose = () => {
        setStatusModalShow(false);
        setShow(false);
    }

    const deletePatient = (e) => {
        e.preventDefault();
        axios.post(
            API_URL + 'patient/' + patient + '/delete',
            {id: patient}
        ).then((response) => {
            if (response.data.status) {
                history.go(0);
                setShow(false);
                alertify.success(response.data.message);
            } else {
                alertify.error(response.data.message);
                return null;
            }
        })
    }

    const patientFilterHandler = (e) => {
        e.preventDefault();
        setDisabled(1);
        setIconDisabled(0);
        axios.post(
            API_URL + 'patient/deleted-patients-filter',
            {
                mr: mr.current.value,
                sample_id: sample_id.current.value,
                name: name.current.value,
                phone_no: phone_no.current.value,
                from_date: from_date.current.value,
                to_date: to_date.current.value,
                collection_center_id: collection_center_id.current.value,
                booking: booking.current.value,
                report_status_id: status.current.value
            }
        ).then((response) => {
            setDisabled(0);
            setIconDisabled(1);
            console.log(response.data.data.patients);
            if (response.data.status) {
                setPatients(response.data.data.patients);
                $("#patient_table").DataTable();
            } else {
                return null;
            }
        })
    }

    const changeStatusHandler = (e) => {
        e.preventDefault();
        setDisabled(1);
        setIconDisabled(0);
        axios.post(
            API_URL + 'patient/change-status',
            {
                id: patient,
                report_status_id: changedStatus.current.value,
                status_created_at: status_created_at.current.value,
                notes: notes.current.value
            }
        ).then((response) => {
            setDisabled(0);
            setIconDisabled(1);
            if (response.data.status) {
                history.go(0);
                setShow(false);
                setStatusModalShow(false);
                alertify.success(response.data.message);
            } else {
                alertify.error(response.data.message);
                return null;
            }
        })
    }

    const selectedPatientStatusHandler = (e) => {
        setSelectedPatientStatus(e.target.value);
    }

    useEffect(() => {
        axios.get(API_URL + 'report-status').then((response) => {
            if (response.data.status) {
                setReportStatuses(response.data.data);
            }
        });
    }, []);

    const handleCheckboxChange = (event, id) => {
        setCheckedItems({
            ...checkedItems,
            [id]: event.target.checked
        });

    };

    const undoBulkDelete = () => {
        let mr_arr = [];
        for (let i in checkedItems) {
            if (checkedItems[i] == true) {
                mr_arr.push(i);
            }
        }
        console.log(mr_arr);
        axios.post(
            API_URL + 'patient/restore-bulk-patients',
            {mrs: mr_arr}
        ).then((response) => {
            alertify.success(response.data.message);
            // history.push(`/report-create/` + mr_arr.join(','));
        });
    };


    return (
        <>
            <Row>
                <Col md={12}>
                    <Card>
                        <CardHeader title="Deleted Patients List">
                            <CardHeaderToolbar>
                                <Link to="/patient/create" className="btn btn-primary btn-sm mx-3">
                                    Create Patient
                                </Link>
                            </CardHeaderToolbar>
                        </CardHeader>
                        <div className={"mt-5"}>
                            <Form onSubmit={patientFilterHandler}>
                                <Row className={"ml-5  mr-5 mb-10 "}>
                                    <Col xs={12} md={4}>
                                        <Form.Label>Mr.No</Form.Label>
                                        <Form.Control ref={mr} placeholder="Search By Mr.No"/>
                                    </Col>
                                    <Col xs={12} md={4}>
                                        <Form.Label>Collection Center</Form.Label>
                                        <Form.Control as="select" ref={collection_center_id}>
                                            <option value="0" key={0}>Choose...</option>

                                            {collectionCenters.map((collection_center, index) => (
                                                <option key={index}
                                                        value={collection_center.id}>{collection_center.name}</option>
                                            ))}

                                        </Form.Control>
                                    </Col>
                                    <Col xs={12} md={4}>
                                        <Form.Label>Name</Form.Label>
                                        <Form.Control ref={name} placeholder="Search by Name"/>
                                    </Col>
                                    <Col xs={12} md={4}>
                                        <Form.Label>Phone No</Form.Label>
                                        <Form.Control ref={phone_no} placeholder="Search by Phone"/>
                                    </Col>
                                    <Col xs={12} md={4}>
                                        <Form.Label>Booking</Form.Label>
                                        <Form.Control as="select" ref={booking}>
                                            <option value="0" key={0}>Choose...</option>
                                            <option value="inlab" key={"inlab"}>Inlab</option>
                                            <option value="online" key={"online"}>Online</option>
                                        </Form.Control>
                                    </Col>
                                    <Col xs={12} md={4}>
                                        <Form.Label>Status</Form.Label>
                                        <Form.Control as="select" ref={status}>
                                            <option value="0" key={"0"}>Choose...</option>
                                            {
                                                reportStatuses.map(status => (
                                                    <option value={status.id} key={status.id}>{status.name}</option>
                                                ))
                                            }

                                        </Form.Control>
                                    </Col>
                                    <Col xs={12} md={4}>
                                        <Form.Label>From Date</Form.Label>
                                        <Form.Control type={"date"} ref={from_date} placeholder="Search by From Date"/>
                                    </Col>
                                    <Col xs={12} md={4}>
                                        <Form.Label>To Date</Form.Label>
                                        <Form.Control type={"date"} ref={to_date} placeholder="Search by To Date"/>
                                    </Col>
                                    <Col xs={12} md={4}>
                                        <Form.Label>Sample ID</Form.Label>
                                        <Form.Control ref={sample_id} placeholder="Search By Sample ID"/>
                                    </Col>

                                </Row>
                                <Row>
                                    <Col xs={3}></Col>
                                    <Col xs={6}>
                                        <Button variant="primary" size="lg" className="float-right btn-block"
                                                type="submit"
                                                disabled={disabled}>
                                            Filter
                                            <span
                                                dangerouslySetInnerHTML={{__html: disabled ? `<i class='fas fa-spinner fa-spin'></i>` : ``}}/>
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </div>

                        <CardBody style={{overflow: 'auto'}}>
                            <div>
                                <Button variant="success" onClick={undoBulkDelete}>
                                    Restore Selected Patients
                                </Button>
                            </div>
                            <table id="patient_table"
                                   className="stratprop_datatable table table-striped table-bordered">
                                <thead>
                                <tr>
                                    <th>SN.</th>
                                    <th>Actions</th>
                                    <th>Mr.No</th>
                                    <th>Sample ID</th>
                                    <th>First Name</th>
                                    <th>Last Name</th>
                                    <th>Passport</th>
                                    <th>Registered at</th>
                                    {/*<th>Collection Time</th>*/}
                                    <th>Gender</th>
                                    <th>DOB</th>
                                    {/*<th>Phone No</th>*/}
                                    <th>Email</th>
                                    <th>Status</th>

                                </tr>
                                </thead>
                                <tbody>
                                {patients.length > 0 ? (
                                    patients.map((patient, index) => (
                                        <tr key={index + 1}>
                                            <td>{index + 1}</td>
                                            <td>
                                                <Form.Check
                                                    key={patient.mr}
                                                    type={'checkbox'}
                                                    id={`checkbox-${index}`}
                                                    checked={checkedItems[patient.mr] || false}
                                                    onChange={(e) => {
                                                        handleCheckboxChange(e, patient.mr)
                                                    }}
                                                    value={""}
                                                />
                                            </td>
                                            {/*<td>
                                                <div>
                                                    <Link to={`/patient/edit/${patient.id}`}
                                                          className="btn-icon btn-light btn-hover-primary mx-3"
                                                          title={'Edit Patient'}>
                                                        <EditIcon color={'primary'}></EditIcon>
                                                    </Link>
                                                    <a href={`${BACKEND_URL}patient-detail/${patient.mr}`}
                                                       target={'_blank'}
                                                       className="btn-icon btn-hover-warning mx-3"
                                                       title={"View Patient Detail"}>
                                                        <BeachAccessIcon color="primary"></BeachAccessIcon>
                                                    </a>
                                                    <a href={`${BACKEND_URL}invoice-print/${patient.mr}`}
                                                       target={'_blank'}
                                                       className="btn-icon btn-hover-warning mx-3"
                                                       title={"Print Invoice"}>
                                                        <PrintIcon color="primary"></PrintIcon>
                                                    </a>
                                                    <a href={`${BACKEND_URL}report-print/${patient.mr}`}
                                                       target={'_blank'}
                                                       className="btn-icon btn-hover-warning mx-3"
                                                       title={"Print Report"}>
                                                        <DescriptionIcon color="primary"></DescriptionIcon>
                                                    </a>

                                                    <a
                                                        onClick={() => setCurrentPatient(patient.id, 'status')}
                                                        key={index + "del"}
                                                        className="btn-icon btn-hover-info mx-3"
                                                        title={'Change Status'}>
                                                        <AutorenewIcon color={'secondary'}></AutorenewIcon>
                                                    </a>
                                                    <a
                                                        onClick={() => setCurrentPatient(patient.id, 'delete')}
                                                        key={index}
                                                        className="btn-icon btn-light btn-hover-danger mx-3"
                                                        title={'Delete Patient'}>
                                                        <DeleteForeverIcon color={'action'}></DeleteForeverIcon>
                                                    </a>
                                                    <a
                                                        disabled={disabled}
                                                        title={"Send Email"}
                                                        onClick={() => setCurrentPatient(patient.id, 'mail')}
                                                        key={index}
                                                        className="btn-icon btn-light btn-hover-danger mx-3">
                                                        <EmailIcon color="secondary"></EmailIcon>
                                                        <span
                                                            dangerouslySetInnerHTML={{__html: disabled ? `<i class='fas fa-spinner fa-spin'></i>` : ``}}/>
                                                    </a>
                                                </div>
                                            </td>*/}
                                            <td>{patient.mr}</td>
                                            <td>{patient.sample_id}</td>
                                            <td>{patient.first_name}</td>
                                            <td>{patient.last_name}</td>
                                            <td>{patient.passport_number}</td>
                                            <td>{(patient.registered_at) ? datetime_format(patient.registered_at) : ""}</td>
                                            {/*<td>{(patient.collection_datetime) ? datetime_format(patient.collection_datetime) : ""}</td>*/}
                                            <td>{patient.gender}</td>
                                            <td>{date_format(patient.dob)}</td>
                                            {/*<td>{patient.phone_no}</td>*/}
                                            <td>{patient.email}</td>
                                            <td>{patient.report_status.name}</td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan={13}>No Patient</td>
                                    </tr>
                                )}
                                </tbody>
                            </table>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <
                Modal
                show={show}
                onHide={handleClose}>
                < Modal.Header
                    closeButton>
                    < Modal.Title> Delete
                        Patient < /Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to Delete?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={deletePatient}>
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={statusModalShow} onHide={handleClose}>
                <Form onSubmit={changeStatusHandler}>
                    <Modal.Header closeButton>
                        <Modal.Title>Change Status</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className={"mt-5"}>
                            <Row className={"mb-10 "}>
                                <Col xs={12}>
                                    <Form.Label>Status</Form.Label>
                                    <Form.Control as="select" ref={changedStatus} value={selectedPatientStatus}
                                                  onChange={selectedPatientStatusHandler}>
                                        <option value="0" key={"0"}>Choose...</option>
                                        {
                                            reportStatuses.map(status => (
                                                <option value={status.id} key={status.id}>{status.name}</option>
                                            ))
                                        }

                                    </Form.Control>

                                    <Form.Label>{t("Action Performed At")}</Form.Label>
                                    <Form.Control type={"datetime-local"} ref={status_created_at}
                                                  placeholder={t("Action Performed At")}/>

                                </Col>
                                <Col xs={12}>
                                    <Form.Label>Notes</Form.Label>
                                    <Form.Control as="textarea" ref={notes}></Form.Control>

                                </Col>
                            </Row>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="primary" disabled={disabled} type="submit">
                            Change Status
                            <span
                                dangerouslySetInnerHTML={{__html: disabled ? `<i class='fas fa-spinner fa-spin'></i>` : ``}}/>
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
)
}