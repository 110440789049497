export const date_format = (dateString) => {
    var date = new Date(dateString);
    return ((date.getMonth() > 8) ? (date.getMonth() + 1)
        : ('0' + (date.getMonth() + 1))) + '-' + ((date.getDate() > 9) ? date.getDate()
        : ('0' + date.getDate())) + '-' + date.getFullYear();
}

 export const datetime_format =  (datetimeString) => {
    var allDate = datetimeString.split(' ');
    var thisDate = allDate[0].split('-');
    var thisTime = allDate[1].split(':');
    var newDate = [thisDate[2],thisDate[1],thisDate[0] ].join("-");

    var suffix = thisTime[0] >= 12 ? "PM":"AM";
    var hour = thisTime[0] > 12 ? thisTime[0] - 12 : thisTime[0];
    var hour =hour < 10 ? "0" + hour : hour;
    var min = thisTime[1] ;
    var sec = thisTime[2] ;
    var newTime = hour + ':' + min + ' ' + suffix;

    return newDate + ' ' + newTime;
}

export const datetime_local = (datetimeString) => {
    return new Date(new Date(datetimeString).toString().split('GMT')[0]+' UTC').toISOString().split('.')[0];
    // return new Date(new Date(datetimeString).toString().split('GMT')[0]+' UTC').toISOString().split('.')[0];
}

// export default date_format;