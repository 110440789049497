import React, {useEffect, useState, useRef} from "react";
import axios from "axios";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import alertify from "alertifyjs";
import {API_URL} from "../constants.js";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'


import {
    Card,
    CardBody,
    CardHeader,
    CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

export function MenuEdit(props) {
    const { t } = useTranslation();
    const title = useRef();
    const slug = useRef();
    const icon = useRef();
    const type = useRef();
    const [disabled, setDisabled] = useState(0);
    const [iconDisabled, setIconDisabled] = useState(1);
    const [menu, setMenu] = useState({});
    const id = props.match.params.id;

    useEffect(() => {
        axios.get(API_URL + 'menu/' + id).then((response) => {
            if (response.data.status) {
                setMenu(response.data.data);

            } else {
                alertify.error(response.data.message);
                return null;
            }
        })
    }, []);

    const updateMenuHandler = (e) => {
        e.preventDefault();
        setDisabled(1);
        setIconDisabled(0);
        axios.post(API_URL + "menu/update",
            {
                id: menu.id,
                title: title.current.value,
                slug: slug.current.value,
                icon: icon.current.value,
                type: type.current.value,
            }
        ).then((response) => {
            setDisabled(0);
            setIconDisabled(1);
            if (response.data.status) {
                alertify.success(response.data.message);

                props.history.push("/menu/list");
            } else {
                alertify.error(response.data.message);
                return null;
            }
        }).catch((error) => {
            setDisabled(0);
            setIconDisabled(1);
            if (error.message === 'Request failed with status code 401') {
                //props.logout();
            }
        });
    }
    return (
        <Row>
            <Col md={12}>
                <Card>
                    <CardHeader title={t("Edit Menu")}>
                        <CardHeaderToolbar>
                            <Link to="/menu/list" className="btn btn-primary btn-sm mx-3">
                                {t("Menu List")}
                            </Link>
                        </CardHeaderToolbar>
                    </CardHeader>
                    <CardBody>

                        <div className="mt-5">
                            <Form onSubmit={updateMenuHandler}>
                                <Form.Row>
                                    <Form.Group as={Col}>
                                        <Form.Label>{t("Title")}</Form.Label>
                                        <Form.Control ref={title} placeholder={t("Enter Menu Title")}
                                                      defaultValue={menu.title}/>
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col}>
                                        <Form.Label>{t("Slug")}</Form.Label>
                                        <Form.Control ref={slug} placeholder={t("Enter Menu Slug")} defaultValue={menu.slug}/>
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col}>
                                        <Form.Label>{t("Icon")}</Form.Label>
                                        <Form.Control ref={icon} placeholder={t("Enter Menu Icon")} defaultValue={menu.icon}/>
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col}>
                                        <Form.Label>{t("Type")}</Form.Label>
                                        <Form.Control ref={type} placeholder={t("Enter Menu Type")} defaultValue={menu.type}/>
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                    </Form.Group>
                                </Form.Row>

                                <Button variant="primary" className="float-right" type="submit" disabled={disabled}>
                                    {t("Save")}
                                    <span
                                        dangerouslySetInnerHTML={{__html: disabled ? `<i class='fas fa-spinner fa-spin'></i>` : ``}}/>
                                </Button>
                            </Form>

                        </div>

                    </CardBody>
                </Card>
            </Col>
        </Row>
    );
}