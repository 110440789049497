import React, {useEffect, useState, useRef} from "react";
import {toAbsoluteUrl} from "../../../_metronic/_helpers";
import axios from "axios";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import alertify from "alertifyjs";
import {API_URL, BACKEND_URL} from "../constants.js";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {
    Card,
    CardBody,
    CardHeader,
    CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import {Link} from "react-router-dom";

export function TestCount(props) {
    const from_date = useRef();
    const to_date = useRef();
    const test_id = useRef();
    const remarks = useRef();

    const [tests, seTests] = useState([]);
    const [selectedTests, setSelectedTests] = useState([]);
    const [sortBy, setSortBy] = useState('date');

    const [disabled, setDisabled] = useState(0);
    const [iconDisabled, setIconDisabled] = useState(1);

    const generateReportHandler = (e) => {
        e.preventDefault();
        var queryString = '?from_date=' + from_date.current.value + '&to_date=' + to_date.current.value +'&sortby='+sortBy+ '&test_id=' + selectedTests +'&remarks='+remarks.current.value;
        window.open(`${BACKEND_URL}test-count-summary${queryString}`);
    }

    useEffect(() => {
        axios.get(API_URL + 'test').then((response) => {
            if (response.data.status) {
                seTests(response.data.data);
            }
        });
    }, []);


    const testClickHandler= (e)=>{
        if(e.target.checked){
            setSelectedTests([...selectedTests,e.target.value]);
        }
        else{
            let checkedTests = selectedTests.filter(status=> status!=e.target.value);
            setSelectedTests(checkedTests);
        }
    }


    return (
        <>
            <Row>
                <Col md={12}>
                    <Card>
                        <CardHeader title="Test Count Summary">
                            <CardHeaderToolbar>
                            </CardHeaderToolbar>
                        </CardHeader>
                        <CardBody>

                            <div className="mt-5">
                                <Form onSubmit={generateReportHandler}>
                                    <Form.Row>
                                        <Col md={5}>
                                            <Form.Label>From Date</Form.Label>
                                            <Form.Control type={'date'} ref={from_date} placeholder="Enter From Date"/>
                                        </Col>
                                        <Col md={5}>
                                            <Form.Label>To Date</Form.Label>
                                            <Form.Control type={'date'} ref={to_date} placeholder="Enter To Date"/>
                                        </Col>
                                    </Form.Row>

                                    <Form.Row>
                                        <Col md={10}>
                                            <Form.Label>Remarks</Form.Label>
                                            <Form.Control as={'textarea'} ref={remarks} placeholder="Enter remarks to be printed on report (Optional)"/>
                                        </Col>
                                    </Form.Row>
                                    <Form.Row>
                                        <Col md={5} style={{maxHeight:'500px', overflowY:'auto'}}>
                                            <Form.Label>Select Tests <small>(Leave Empty for All's Reporting)</small></Form.Label>
                                            <br/>
                                            {
                                                tests.map(test => (
                                                    <Form.Check
                                                        key={test.id}
                                                        type={'checkbox'}
                                                        id={`default`}
                                                        value={test.id}
                                                        onClick={(e)=>testClickHandler(e)}
                                                        label={`${test.name}`}
                                                    />
                                                ))
                                            }
                                        </Col>
                                    </Form.Row>

                                    <hr/>
                                    <Form.Row>
                                        <Col md={5}>
                                            <Form.Label>Sort Report By</Form.Label>
                                            <Form.Check
                                                type={'radio'}
                                                name={'sortby'}
                                                label={`Test Count Date Wise Report`}
                                                defaultChecked={true}
                                                onClick={(e)=>{setSortBy('date')}}
                                            />
                                            <Form.Check
                                                type={'radio'}
                                                name={'sortby'}
                                                label={`Test Count Test Wise Report`}
                                                onClick={(e)=>{setSortBy('test')}}
                                            />

                                        </Col>
                                    </Form.Row>

                                    <Button variant="primary" className="float-right" type="submit" disabled={disabled}>
                                        Populate Report
                                        <span
                                            dangerouslySetInnerHTML={{__html: disabled ? `<i class='fas fa-spinner fa-spin'></i>` : ``}}/>
                                    </Button>
                                </Form>

                            </div>

                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    );
}