/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import {useLocation} from "react-router";
import {NavLink} from "react-router-dom";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl, checkIsActive} from "../../../../_helpers";
import {makeStyles} from '@material-ui/core/styles';
import AirportShuttleIcon from '@material-ui/icons/AirportShuttle';
import ExtensionIcon from '@material-ui/icons/Extension';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import PeopleIcon from '@material-ui/icons/People';
import HelpIcon from '@material-ui/icons/Help';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import AccessibleIcon from '@material-ui/icons/Accessible';// Patient
import {check_role} from '../../../../../app/pages/Common/RoleMenuCheck';

const useStyles = makeStyles(theme => ({
    root: {
        color: theme.palette.text.primary,
    },
    icon: {
        margin: theme.spacing(1),
        fontSize: 32,
    },
}));

export function AsideMenuReportList({layoutProps}) {
    const classes = useStyles();
    const location = useLocation();
    const getMenuItemActive = (url, hasSubmenu = false) => {
        return checkIsActive(location, url)
            ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
            : "";
    };

    return (
        <>
            {/* begin::Menu Nav */}
            <ul className={`menu-nav ${layoutProps.ulClasses}`}>

                {/* Report */}
                {/* begin::section */}
                <li className="menu-section ">
                    <h4 className="menu-text">Reports & Summaries</h4>
                    <i className="menu-icon flaticon-more-v2"></i>
                </li>
                {/* end:: section */}

                {/* Faq Pages */}
                {/*begin::1 Level*/}
                {check_role(['summary/patient', 'summary/reference', 'summary/status', 'summary/due-amount', 'summary/test-count', 'summary/rate-list', 'summary/patient-activity-log', 'patient-excel']) ? (
                    <li
                        className={`menu-item menu-item-submenu ${getMenuItemActive(
                            "/faq", true
                        )}`}
                        aria-haspopup="true"
                        data-menu-toggle="hover"
                    >
                        <NavLink className="menu-link menu-toggle" to="/faq">
            <span className="svg-icon menu-icon">
              <AccessibleIcon className={classes.icon}/>
            </span>
                            <span className="menu-text">Patient Summaries</span>
                            <i className="menu-arrow"/>
                        </NavLink>
                        <div className="menu-submenu ">
                            <i className="menu-arrow"/>
                            <ul className="menu-subnav">
                                <li className="menu-item  menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">Patient Summaries</span>
                </span>
                                </li>

                                {/*begin::2 Level*/}
                                {check_role(['summary/patient']) ? (
                                    <li
                                        className={`menu-item ${getMenuItemActive("/summary/patient")}`}
                                        aria-haspopup="true"
                                    >
                                        <NavLink className="menu-link" to="/summary/patient">
                                            <i className="menu-bullet menu-bullet-dot">
                                                <span/>
                                            </i>
                                            <span className="menu-text">Patient Summary</span>
                                        </NavLink>
                                    </li>
                                ) : ''}
                                {/*end::2 Level*/}

                                {/*begin::2 Level*/}
                                {check_role(['summary/reference']) ? (
                                    <li
                                        className={`menu-item ${getMenuItemActive("/summary/reference")}`}
                                        aria-haspopup="true"
                                    >
                                        <NavLink className="menu-link" to="/summary/reference">
                                            <i className="menu-bullet menu-bullet-dot">
                                                <span/>
                                            </i>
                                            <span className="menu-text">Reference Summary</span>
                                        </NavLink>
                                    </li>
                                ) : ''}
                                {/*end::2 Level*/}

                                {/*begin::2 Level*/}
                                {check_role(['summary/status']) ? (
                                    <li
                                        className={`menu-item ${getMenuItemActive("/summary/status")}`}
                                        aria-haspopup="true"
                                    >
                                        <NavLink className="menu-link" to="/summary/status">
                                            <i className="menu-bullet menu-bullet-dot">
                                                <span/>
                                            </i>
                                            <span className="menu-text">Report Status Summary</span>
                                        </NavLink>
                                    </li>
                                ) : ''}
                                {/*end::2 Level*/}
                                {/*begin::2 Level*/}
                                {check_role(['summary/due-amount']) ? (
                                    <li
                                        className={`menu-item ${getMenuItemActive("/summary/due-amount")}`}
                                        aria-haspopup="true"
                                    >
                                        <NavLink className="menu-link" to="/summary/due-amount">
                                            <i className="menu-bullet menu-bullet-dot">
                                                <span/>
                                            </i>
                                            <span className="menu-text">Due Amount Summary</span>
                                        </NavLink>
                                    </li>
                                ) : ''}
                                {/*end::2 Level*/}
                                {/*begin::2 Level*/}
                                {check_role(['summary/test-count']) ? (
                                    <li
                                        className={`menu-item ${getMenuItemActive("/summary/test-count")}`}
                                        aria-haspopup="true"
                                    >
                                        <NavLink className="menu-link" to="/summary/test-count">
                                            <i className="menu-bullet menu-bullet-dot">
                                                <span/>
                                            </i>
                                            <span className="menu-text">Test Count Summary</span>
                                        </NavLink>
                                    </li>
                                ) : ''}
                                {/*end::2 Level*/}
                                {/*begin::2 Level*/}
                                {check_role(['summary/rate-list']) ? (
                                    <li
                                        className={`menu-item ${getMenuItemActive("/summary/rate-list")}`}
                                        aria-haspopup="true"
                                    >
                                        <NavLink className="menu-link" to="/summary/rate-list">
                                            <i className="menu-bullet menu-bullet-dot">
                                                <span/>
                                            </i>
                                            <span className="menu-text">Lab Rate List</span>
                                        </NavLink>
                                    </li>
                                ) : ''}
                                {/*end::2 Level*/}
                                {/*begin::2 Level*/}
                                {check_role(['summary/patient-activity-log']) ? (
                                    <li
                                        className={`menu-item ${getMenuItemActive("/summary/patient-activity-log")}`}
                                        aria-haspopup="true"
                                    >
                                        <NavLink className="menu-link" to="/summary/patient-activity-log">
                                            <i className="menu-bullet menu-bullet-dot">
                                                <span/>
                                            </i>
                                            <span className="menu-text">Patient Activity Log</span>
                                        </NavLink>
                                    </li>
                                ) : ''}
                                {check_role(['summary/patient-excel']) ? (
                                <li
                                    className={`menu-item ${getMenuItemActive("/summary/patient-excel")}`}
                                    aria-haspopup="true"
                                >
                                    <NavLink className="menu-link" to="/summary/patient-excel">
                                        <i className="menu-bullet menu-bullet-dot">
                                            <span/>
                                        </i>
                                        <span className="menu-text">Patient Excel Download</span>
                                    </NavLink>
                                </li>
                                ) : ''}
                                {/*end::2 Level*/}

                            </ul>
                        </div>
                    </li>
                ) : ''}
                {/*end::1 Level*/}

                {/* Purchase Summaries Pages */}
                {/*begin::1 Level*/}
                {check_role(['summary/purchase-report', 'summary/purchase-summary']) ? (
                    <li
                        className={`menu-item menu-item-submenu ${getMenuItemActive(
                            "/contact", true
                        )}`}
                        aria-haspopup="true"
                        data-menu-toggle="hover"
                    >
                        <NavLink className="menu-link menu-toggle" to="/contact">
            <span className="svg-icon menu-icon">
              <ContactMailIcon className={classes.icon}/>
            </span>
                            <span className="menu-text">Purchase Summaries</span>
                            <i className="menu-arrow"/>
                        </NavLink>
                        <div className="menu-submenu ">
                            <i className="menu-arrow"/>
                            <ul className="menu-subnav">
                                <li className="menu-item  menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">Purchase Summaries</span>
                </span>
                                </li>

                                {/*begin::2 Level*/}
                                {check_role(['summary/purchase-report']) ? (
                                    <li
                                        className={`menu-item ${getMenuItemActive("/summary/purchase-report")}`}
                                        aria-haspopup="true"
                                    >
                                        <NavLink className="menu-link" to="/summary/purchase-report">
                                            <i className="menu-bullet menu-bullet-dot">
                                                <span/>
                                            </i>
                                            <span className="menu-text">Purchase Report</span>
                                        </NavLink>
                                    </li>
                                ) : ''}
                                {/*end::2 Level*/}
                                {/*begin::2 Level*/}
                                {check_role(['summary/purchase-summary']) ? (
                                    <li
                                        className={`menu-item ${getMenuItemActive("/summary/purchase-summary")}`}
                                        aria-haspopup="true"
                                    >
                                        <NavLink className="menu-link" to="/summary/purchase-summary">
                                            <i className="menu-bullet menu-bullet-dot">
                                                <span/>
                                            </i>
                                            <span className="menu-text">Purchase Summary</span>
                                        </NavLink>
                                    </li>
                                ) : ''}
                                {/*end::2 Level*/}

                            </ul>
                        </div>
                    </li>
                ) : ''}
                {/*end::1 Level*/}

                {/*begin::1 Level*/}
                {check_role(['summary/income-report', 'summary/expense-report']) ? (
                    <li
                        className={`menu-item menu-item-submenu ${getMenuItemActive(
                            "/contact", true
                        )}`}
                        aria-haspopup="true"
                        data-menu-toggle="hover"
                    >
                        <NavLink className="menu-link menu-toggle" to="/summary">
            <span className="svg-icon menu-icon">
              <ContactMailIcon className={classes.icon}/>
            </span>
                            <span className="menu-text">Accounts Summaries</span>
                            <i className="menu-arrow"/>
                        </NavLink>
                        <div className="menu-submenu ">
                            <i className="menu-arrow"/>
                            <ul className="menu-subnav">
                                <li className="menu-item  menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">Accounts Summaries</span>
                </span>
                                </li>

                                {/*begin::2 Level*/}
                                {check_role(['summary/income-report']) ? (
                                    <li
                                        className={`menu-item ${getMenuItemActive("/summary/income-report")}`}
                                        aria-haspopup="true"
                                    >
                                        <NavLink className="menu-link" to="/summary/income-report">
                                            <i className="menu-bullet menu-bullet-dot">
                                                <span/>
                                            </i>
                                            <span className="menu-text">Income Report</span>
                                        </NavLink>
                                    </li>
                                ) : ''}
                                {/*end::2 Level*/}
                                {/*begin::2 Level*/}
                                {check_role(['summary/expense-report']) ? (
                                    <li
                                        className={`menu-item ${getMenuItemActive("/summary/expense-report")}`}
                                        aria-haspopup="true"
                                    >
                                        <NavLink className="menu-link" to="/summary/expense-report">
                                            <i className="menu-bullet menu-bullet-dot">
                                                <span/>
                                            </i>
                                            <span className="menu-text">Expense Report</span>
                                        </NavLink>
                                    </li>
                                ) : ''}
                                {/*end::2 Level*/}

                            </ul>
                        </div>
                    </li>
                ) : ''}
                {/*end::1 Level*/}
            </ul>

            {/* end::Menu Nav */}
        </>
    );
}
