import React, {useEffect, useState, useRef} from "react";
import {toAbsoluteUrl} from "../../../_metronic/_helpers";
import axios from "axios";
import Form from "react-bootstrap/Form";
import Table from 'react-bootstrap/Table';
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import alertify from "alertifyjs";
import {API_URL, BACKEND_URL} from "../constants.js";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {
    Card,
    CardBody,
    CardHeader,
    CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import {Link} from "react-router-dom";

export function ReportGenerate(props) {
    const [defaultMr, setDefaultMr] = useState(props.match.params.mr)
    const mr = useRef();
    const [disabled, setDisabled] = useState(0);
    const [iconDisabled, setIconDisabled] = useState(1);
    const [message, setMessage] = useState('');
    const [header, setHeader] = useState(true);
    const [footer, setFooter] = useState(true);
    const [electronicallyVerified, setElectronicallyVerified] = useState(true);

    const [testList, setTestList] = useState([]);

    const [patient, setPatient] = useState({
        patient_tests: []
    });
    const [remarks, setRemarks] = useState([]);

    const reportGetDataHandler = (e) => {
        setTestList([]);
        e.preventDefault();
        setDisabled(1);
        setIconDisabled(0);
        axios.post(
            API_URL + 'patient/patient-report-by-mr',
            {
                mr: mr.current.value,
            }
        ).then((response) => {
            setDisabled(0);
            setIconDisabled(1);
            if (response.data.status) {
                if (response.data.data) {
                    setPatient(response.data.data);
                    let updatedTestList = [];
                    response.data.data.patient_tests.map((test, index) => {
                        var test = {
                            patient_test_index: index,
                            test_id: test.test.id,
                            patient_test_id: test.id,
                            test_name: test.test.name,
                            isChecked: true,
                            report_remarks_id: test.report_remarks_id
                        };
                        updatedTestList.push(test);
                    });
                    setTestList(updatedTestList);

                } else {
                    alertify.error("No record found for this Mr.No.");
                    setMessage('Either Wrong Mr.No. Or Report Not Created.');
                    setPatient({});
                }

            } else {
                return null;
            }
        })
    }

    /*    useEffect(() => {
            console.log(testList);
        }, [testList]);*/

    useEffect(() => {

        axios.get(API_URL + 'report-remarks').then((response) => {
            if (response.data.status) {
                setRemarks(response.data.data);
            }
        }).catch((error) => {
            alertify.error("Something went wrong. Try again!");
        });

    }, []);

    const handleHeaderOnChange = () => {
        setHeader(!header);
    };

    const handleFooterOnChange = () => {
        setFooter(!footer);
    };

    const handleElectronicallyVerifiedOnChange = () => {
        setElectronicallyVerified(!electronicallyVerified);
    };

    const handleOnChange = (index) => {
        let updatedTest = [...testList];
        updatedTest[index].isChecked = !updatedTest[index].isChecked;
        setTestList(updatedTest);
    }

    const handleRemarksOnChange = (index, value) => {
        let updatedTest = [...testList];
        updatedTest[index].report_remarks_id = value;
        setTestList(updatedTest);
    }

    const handleSubmit = () => {

        const tests = testList.filter(item => item.isChecked);
        var queryString = patient.mr + "?header=" + header + "&footer=" + footer + "&electronically_verified=" + electronicallyVerified + "&tests=";
        tests.map(test => {
            queryString += test.patient_test_id + '_' + test.report_remarks_id + ',';
        });
        queryString = queryString.slice(0, -1);
        window.open(`${BACKEND_URL}report-print/${queryString}`);

        /*props.history.push({
            pathname: '/report',
            mr: patient.mr,
            tests: testList, // your data array of objects,
            header: header,
            footer: footer
        });*/

    }

    return (
        <>
            <Row>
                <Col md={12}>
                    <Card>
                        <CardHeader title="Generate Report">
                            <CardHeaderToolbar>
                                <Link to="/report-create" className="btn btn-primary btn-sm mx-3">
                                    Create Report
                                </Link>
                            </CardHeaderToolbar>
                        </CardHeader>
                        <CardBody>
                            <div className={"mt-5"}>
                                <Form onSubmit={reportGetDataHandler}>
                                    <Row className={"ml-5  mr-5 mb-10 "}>
                                        <Col xs={12} md={4}>
                                            <Form.Label>Mr.No</Form.Label>
                                            <Form.Control ref={mr} defaultValue={defaultMr}
                                                          placeholder="Search By Mr.No"/>
                                        </Col>
                                        <Col xs={12} md={2} style={{marginTop: "20px"}}>
                                            <Button variant="primary" size="lg" className="float-right btn-block"
                                                    type="submit"
                                                    disabled={disabled}>
                                                Filter
                                                <span
                                                    dangerouslySetInnerHTML={{__html: disabled ? `<i class='fas fa-spinner fa-spin'></i>` : ``}}/>
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </div>
                            {testList.length > 0 ? (
                                <>
                                    <Table bordered hover>
                                        <thead>
                                            <tr style={{background:'#cecece'}}>
                                                <th>Choose Tests</th>
                                                <th>Report Remarks</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            testList.map((test, index) => (
                                                <tr key={index}>
                                                    <td>
                                                        <Form.Check
                                                            type={"checkbox"}
                                                            checked={testList[index].isChecked}
                                                            onChange={() => handleOnChange(index)}
                                                            label={test.test_name}
                                                        />
                                                    </td>
                                                    <td>
                                                        <Form.Control as="select"
                                                                      id={"reportremarks[" + test.test_id + "]"}
                                                                      onChange={(e) => handleRemarksOnChange(index, e.target.value)}
                                                                      value={test.report_remarks_id}>
                                                            <option value={'0'}>Choose a remarks</option>
                                                            {remarks.map((remark, index) => (
                                                                <option key={index}
                                                                        value={remark.id}>{remark.name}</option>
                                                            ))}
                                                        </Form.Control>
                                                    </td>
                                                </tr>
                                            ))

                                        }
                                        </tbody>
                                    </Table>

                                    <Row>
                                        <Col xs={12} className={"mt-3"}>
                                            <Form.Check
                                                type={"checkbox"}
                                                name="header"
                                                value="header"
                                                checked={header}
                                                onChange={handleHeaderOnChange}
                                                label={"Print Header"}
                                            />
                                        </Col>
                                        <Col xs={12} className={"mt-3"}>
                                            <Form.Check
                                                type={"checkbox"}
                                                name="footer"
                                                value="footer"
                                                checked={footer}
                                                onChange={handleFooterOnChange}
                                                label={"Print Footer"}
                                            />
                                        </Col>
                                        <Col xs={12} className={"mt-3"}>
                                            <Form.Check
                                                type={"checkbox"}
                                                name="electronicallyVerified"
                                                value="electronicallyVerified"
                                                checked={electronicallyVerified}
                                                onChange={handleElectronicallyVerifiedOnChange}
                                                label={"Print Electornically Verified"}
                                            />
                                        </Col>
                                    </Row>

                                    <Row className={"ml-5  mr-5 mb-10 "}>
                                        <Col xs={12} md={2} style={{marginTop: "20px"}}>
                                            <Button variant="primary" size="lg" className="float-right btn-block"
                                                    onClick={handleSubmit}
                                                    disabled={disabled}>
                                                Generate
                                                <span
                                                    dangerouslySetInnerHTML={{__html: disabled ? `<i class='fas fa-spinner fa-spin'></i>` : ``}}/>
                                            </Button>
                                        </Col>
                                    </Row>

                                </>
                            ) : ('')
                            }
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    );
}
