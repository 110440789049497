import React, {useEffect, useState, useRef} from "react";
import axios from "axios";
import $ from "jquery";
// import DataTable from "datatables.net";
import {Link} from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import {useHistory} from 'react-router';
import {toAbsoluteUrl} from "../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import alertify from "alertifyjs";
import {date_format, datetime_format} from "../Common/DateFormat";

import {API_URL} from '../constants.js';

import {
    Card,
    CardBody,
    CardHeader,
    CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";

export function ReportCreate(props) {
    const mr = useRef();
    const [age, setAge] = useState(0);
    const [message, setMessage] = useState('');

    const [disabled, setDisabled] = useState(0);
    const [iconDisabled, setIconDisabled] = useState(1);
    const [collectionCenters, setCollectionCenters] = useState([]);
    const [patient, setPatient] = useState({
        patient_tests: []
    });
    const [show, setShow] = useState(false);
    const [statusModalShow, setStatusModalShow] = useState(false);
    const [selectedPatientStatus, setSelectedPatientStatus] = useState("0");
    const [remarks, setRemarks] = useState([]);
    const [checkedItems, setCheckedItems] = useState({});

    const mr_number = props.match.params.mr_number.split(",");

    const history = useHistory();

    const handleClose = () => {
        setStatusModalShow(false);
        setShow(false);
    }

    const resultHandler = (patient_test_index, test_id, test_detail_id, value) => {
        patient.patient_tests[patient_test_index].test.details.map((detail, index) => {

            if (detail.id == test_detail_id && detail.test_id == test_id) {
                patient.patient_tests[patient_test_index].test.details[index].default_result = value;
                setPatient(patient);

                //Check Age, Gender for Normal Values
                detail.normal_values.map((normalvalue, ind) => {



                    /*if ((age <= normalvalue.to_age
                        && age >= normalvalue.from_age
                        && normalvalue.gender == patient.gender)
                        ||
                        (patient.patient_tests[patient_test_index].test.details[index].flag == undefined
                            && normalvalue.gender == 'default'))*/
                    if (1) {


                        if (value < parseFloat(normalvalue.minimum_value)) {
                            $('#row' + test_detail_id).removeClass('normal-row');
                            patient.patient_tests[patient_test_index].test.details[index].flag = "lower";
                            $('#row' + test_detail_id).addClass('danger-row');
                        } else if (value > parseFloat(normalvalue.maximum_value)) {
                            patient.patient_tests[patient_test_index].test.details[index].flag = "high";
                            $('#row' + test_detail_id).removeClass('normal-row');
                            $('#row' + test_detail_id).addClass('danger-row');
                        } else if (value.toLowerCase() == 'negative') {
                            patient.patient_tests[patient_test_index].test.details[index].flag = "negative";
                            $('#row' + test_detail_id).removeClass('normal-row');
                            $('#row' + test_detail_id).addClass('danger-row');
                        } else {
                            patient.patient_tests[patient_test_index].test.details[index].flag = "normal";
                            $('#row' + test_detail_id).removeClass('danger-row');
                            $('#row' + test_detail_id).addClass('normal-row');
                        }
                        setPatient(patient);
                    }
                });

            }
        });
    }

    const reportGetDataHandler = (e) => {
        e.preventDefault();
        setDisabled(1);
        setIconDisabled(0);
        axios.post(
            API_URL + 'patient/patient-by-mr',
            {
                mr: mr.current.value,
            }
        ).then((response) => {
            setDisabled(0);
            setIconDisabled(1);
            if (response.data.status) {
                if (response.data.data) {
                    setPatient(response.data.data);

                    //Get Age From DOB.
                    var today = new Date();
                    var birthDate = new Date(response.data.data.dob);
                    var age = today.getFullYear() - birthDate.getFullYear();
                    var m = today.getMonth() - birthDate.getMonth();
                    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                        age--;
                    }
                    setAge(age);
                } else {
                    setMessage('Either Wrong Mr.No. Or Report Already Created. You Can Edit by Following Link');
                    setPatient({});
                }

            } else {
                return null;
            }
        })
    }

    const createReportHandler = (e) => {
        e.preventDefault();
        setDisabled(1);
        setIconDisabled(0);
        axios.post(API_URL + "patient/create-report",
            {
                patient: patient,
                mr_list: mr_number
            }
        ).then((response) => {
            setDisabled(0);
            setIconDisabled(1);
            if (response.data.status) {
                alertify.success(response.data.data);

                props.history.push(`/patients-without-report`);
            } else {
                alertify.error(response.data.message);
                return null;
            }
        }).catch((error) => {
            setDisabled(0);
            setIconDisabled(1);
            if (error.message === 'Request failed with status code 401') {
                //props.logout();
            }
        });
    }

    useEffect(() => {

        /*
        * Get Report Remarks
        * */
        axios.get(API_URL + 'report-remarks').then((response) => {
            if (response.data.status) {
                setRemarks(response.data.data);
            }
        }).catch((error) => {
            alertify.error("Something went wrong. Try again!");
        });

        /*
        *    Get patient by MR No
        * */
        axios.post(
            API_URL + 'patient/patient-by-mr',
            {
                mr: mr.current.value,
            }
        ).then((response) => {
            setDisabled(0);
            setIconDisabled(1);
            if (response.data.status) {
                if (response.data.data) {
                    setPatient(response.data.data);

                    //Get Age From DOB.
                    var today = new Date();
                    var birthDate = new Date(response.data.data.dob);
                    var age = today.getFullYear() - birthDate.getFullYear();
                    var m = today.getMonth() - birthDate.getMonth();
                    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                        age--;
                    }
                    setAge(age);
                } else {
                    setMessage('Either Wrong Mr.No. Or Report Already Created. You Can Edit by Following Link');
                    setPatient({});
                }

            } else {
                return null;
            }
        });

    }, []);

    const handleRemarksOnChange = (index, value) => {
        const cloned = patient;
        cloned.patient_tests[index].report_remarks_id = value;
        setPatient(cloned);
    }

    return (
        <>
            <Row>
                <Col md={12}>
                    <Card>
                        <CardHeader title="Create Report">
                            <CardHeaderToolbar>
                                <Link to="/report-generate" className="btn btn-primary btn-sm mx-3">
                                    Generate Report
                                </Link>
                            </CardHeaderToolbar>
                        </CardHeader>
                        <div className={"mt-5"}>
                            <Form onSubmit={reportGetDataHandler}>
                                <Row className={"ml-5  mr-5 mb-10 "}>
                                    <Col xs={12} md={4}>
                                        <Form.Label>Mr.No: </Form.Label>
                                        {mr_number.join(", ")}
                                        <Form.Control ref={mr} className={"d-none"}
                                                      placeholder="Search By Mr.No" defaultValue={mr_number[0]}/>
                                    </Col>
                                    <Col xs={12} md={2} style={{marginTop: "20px"}}>
                                        <Button variant="primary" size="lg" className="float-right btn-block d-none"
                                                type="submit"
                                                disabled={disabled}>
                                            Filter
                                            <span
                                                dangerouslySetInnerHTML={{__html: disabled ? `<i class='fas fa-spinner fa-spin'></i>` : ``}}/>
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </div>

                        <CardBody>
                            {
                                Object.keys(patient).length > 1 ? (

                                    <>
                                        {mr_number.length == 1 ? (
                                            <Table striped bordered hover>
                                                <tbody>
                                                <tr>
                                                    <th>Sample ID</th>
                                                    <th>{patient.sample_id}</th>
                                                    <th>Name</th>
                                                    <th>{patient.first_name + ' ' + patient.last_name}</th>
                                                </tr>
                                                <tr>
                                                    <th>Gender</th>
                                                    <th>{patient.gender}</th>
                                                    <th>DOB</th>
                                                    <th>{date_format(patient.dob)}</th>
                                                </tr>
                                                <tr>
                                                    <th>Phone No</th>
                                                    <th>{patient.phone_no}</th>
                                                    <th>Collected At</th>
                                                    <th>{(patient.collection_datetime) ? datetime_format(patient.collection_datetime) : ""}</th>
                                                </tr>
                                                </tbody>
                                            </Table>) : ("")}
                                        <Row>
                                            <Col md={12} className={"text-center mt-5 mb-5"}><h1>Generate Report</h1>
                                            </Col>
                                        </Row>

                                        {
                                            patient.patient_tests.map((test, index) => (
                                                <Row className="text-center" key={index}>
                                                    <Col md={12} className={"test-name"}><h3>{test.test.name}</h3></Col>
                                                    <Col md={12}>
                                                        <Table striped bordered hover>
                                                            <thead>
                                                            <tr variant="dark">
                                                                <th>Title</th>
                                                                <th>Unit</th>
                                                                <th>Result</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            {
                                                                test.test.details.map((detail, element) => (
                                                                    <tr key={element} id={"row" + detail.id}>
                                                                        <td>{detail.name}</td>
                                                                        <td>{detail.unit.name}</td>

                                                                        <td>
                                                                            {
                                                                                test.test.results_entry_mode == 'input' ? (
                                                                                    <Form.Control placeholder="Enter Results"
                                                                                                  defaultValue={detail.default_result}
                                                                                                  onKeyDown={(e) => resultHandler(index, detail.test_id, detail.id, e.target.value)}
                                                                                                  onKeyUp={(e) => resultHandler(index, detail.test_id, detail.id, e.target.value)}
                                                                                                  onKeyPress={(e) => resultHandler(index, detail.test_id, detail.id, e.target.value)}/>
                                                                                ) : (
                                                                                    <Form.Control
                                                                                        placeholder="Enter Results"
                                                                                        defaultValue="Negative"
                                                                                        as="select"
                                                                                        onChange={(e) => resultHandler(index, detail.test_id, detail.id, e.target.value)}
                                                                                    >
                                                                                        <option
                                                                                            value={'Inclusive - please repeat'}>Inclusive
                                                                                            - please repeat
                                                                                        </option>
                                                                                        <option
                                                                                            value={'Positive'}>Positive
                                                                                        </option>
                                                                                        <option
                                                                                            value={'Negative'}>Negative
                                                                                        </option>
                                                                                    </Form.Control>
                                                                                )
                                                                            }

                                                                        </td>
                                                                    </tr>
                                                                ))}

                                                            <tr style={{background: '#ace178'}}>
                                                                <td>
                                                                    Choose Remarks (Optional)
                                                                </td>
                                                                <td colSpan={2}>
                                                                    <Form.Control as="select"
                                                                                  onChange={(e) => handleRemarksOnChange(index, e.target.value)}
                                                                    >
                                                                        <option value={'0'}>Choose a remarks</option>
                                                                        {remarks.map((remark, index) => (
                                                                            <option key={index}
                                                                                    value={remark.id}>{remark.name}</option>
                                                                        ))}
                                                                    </Form.Control>
                                                                </td>
                                                            </tr>
                                                            </tbody>
                                                        </Table>
                                                    </Col>
                                                </Row>
                                            ))}

                                        <div className={"mt-5"}>
                                            <Form onSubmit={createReportHandler}>
                                                <Row className={"ml-5  mr-5 mb-10 "}>
                                                    <Col xs={12} md={12} style={{marginTop: "15px"}}>
                                                        <Button variant="primary" size="lg"
                                                                className="float-right btn-block"
                                                                type="submit"
                                                                disabled={disabled}>
                                                            Save
                                                            <span
                                                                dangerouslySetInnerHTML={{__html: disabled ? `<i class='fas fa-spinner fa-spin'></i>` : ``}}/>
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        </div>
                                    </>
                                ) : (
                                    <h3>{message}</h3>
                                )}
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    )
}