import React, {useEffect, useState, useRef} from "react";
import axios from "axios";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import alertify from "alertifyjs";
import {API_URL} from "../constants.js";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {
    Card,
    CardBody,
    CardHeader,
    CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

export function CreateAntibiotic(props) {
    const name = useRef();
    const [disabled, setDisabled] = useState(0);
    const [iconDisabled, setIconDisabled] = useState(1);
    const { t } = useTranslation();
    const createAntibioticHandler = (e) => {
        e.preventDefault();
        setDisabled(1);
        setIconDisabled(0);
        axios.post(API_URL + "antibiotic/create",
            {
                name: name.current.value,
            }
        ).then((response) => {
            setDisabled(0);
            setIconDisabled(1);
            if (response.data.status) {
                alertify.success(response.data.message);

                props.history.push("/antibiotic/list");
            } else {
                alertify.error(response.data.message);
                return null;
            }
        }).catch((error) => {
            setDisabled(0);
            setIconDisabled(1);
            if (error.message === 'Request failed with status code 401') {
                //props.logout();
            }
        });
    }
    return (
        <>
            <Row>
                <Col md={12}>
                    <Card>
                        <CardHeader title={t("Add New Antibiotic")}>
                            <CardHeaderToolbar>
                                <Link to="/antibiotic/list" className="btn btn-primary btn-sm mx-3">
                                    {t("Antibiotic List")}
                                </Link>
                            </CardHeaderToolbar>
                        </CardHeader>
                        <CardBody>

                            <div className="mt-5">
                                <Form onSubmit={createAntibioticHandler}>
                                    <Form.Row>
                                        <Form.Group as={Col}>
                                            <Form.Label>{t("Title")}</Form.Label>
                                            <Form.Control ref={name} placeholder={t("Enter Antibiotic Title")}/>
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                        </Form.Group>
                                    </Form.Row>
                                    <Button variant="primary" className="float-right" type="submit" disabled={disabled}>
                                        {t("Save")}
                                        <span
                                            dangerouslySetInnerHTML={{__html: disabled ? `<i class='fas fa-spinner fa-spin'></i>` : ``}}/>
                                    </Button>
                                </Form>

                            </div>

                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    );
}