import React, {useEffect, useState, useRef} from "react";
import axios from "axios";
import $ from "jquery";
import DataTable from "datatables.net";
import {Link} from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import {useHistory} from 'react-router';
import {toAbsoluteUrl} from "../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import alertify from "alertifyjs";
import {date_format, datetime_format} from "../Common/DateFormat";
import {API_URL, BACKEND_URL} from '../constants.js';
import PrintIcon from '@material-ui/icons/Print';
import DescriptionIcon from '@material-ui/icons/Description';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import EditIcon from '@material-ui/icons/Edit';
import BeachAccessIcon from '@material-ui/icons/BeachAccess';

import {
    Card,
    CardBody,
    CardHeader,
    CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import {useTranslation} from "react-i18next";

export function PatientList(props) {
    const { t } = useTranslation();
    const [patients, setPatients] = useState([]);
    const [patient, setPatient] = useState(null);
    const [show, setShow] = useState(false);
    const [action, setAction] = useState('patient');
    const history = useHistory()

    const setCurrentPatient = (patient, action) => {
        setPatient(patient);
        setAction(action);
        setShow(true);
    };

    const handleClose = () => setShow(false);

    const deletePatient = () => {
        axios.post(
            API_URL + 'patient/delete',
            {
                id: patient,
                action: action
            }
        ).then((response) => {
            if (response.data.status) {
                history.go(0);
                setShow(false);
                alertify.success(response.data.message);
            } else {
                alertify.error(response.data.message);
                return null;
            }
        })
    }

    useEffect(() => {

        axios.get(API_URL + 'patient').then((response) => {
            if (response.data.status) {

                setPatients(response.data.data.patients);

                 $("#patient_table").DataTable();
            } else {
                alertify.error(response.data.message);
                return null;
            }
        })
            .catch((error) => {

                if (error.message === 'Request failed with status code 401') {
                    //props.logout();
                }
            });

    }, []);

    return (
        <>
            <Row>
                <Col md={12}>
                    <Card>
                        <CardHeader title={t("Patients List")}>
                            <CardHeaderToolbar>
                                <Link to="/patient/create" className="btn btn-primary btn-sm mx-3">
                                    {t("Create Patient")}
                                </Link>
                            </CardHeaderToolbar>
                        </CardHeader>
                        <CardBody style={{overflow:'auto'}}>
                            <table id="patient_table" className="stratprop_datatable table table-striped table-bordered">
                                <thead>
                                <tr key={'thead'}>
                                    <th>{t("SN.")}</th>
                                    <th>{t("Actions")}</th>
                                    <th>{t("Mr.No")}</th>
                                    <th>{t("First Name")}</th>
                                    <th>{t("Last Name")}</th>
                                    <th>{t("Passport")}</th>
                                    <th>{t("Collection At")}</th>
                                    {/*<th>{t("Collection Time")}</th>*/}
                                    <th>{t("Gender")}</th>
                                    <th>{t("D.O.B")}</th>
                                    {/*<th>{t("Phone")}</th>*/}
                                    <th>{t("Email")}</th>
                                    <th>{t("Status")}</th>
                                </tr>
                                </thead>
                                <tbody>
                                {patients.length > 0 ? (
                                    patients.map((patient, index) => (
                                        <tr key={index + 1}>
                                            <td>{index + 1}</td>
                                            <td>
                                                <div>
                                                    <Link to={`/patient/edit/${patient.id}`}
                                                          title={"Edit Patient"}
                                                          className="btn-icon btn-light btn-hover-primary mx-3">
                                                        <EditIcon color="primary"></EditIcon>

                                                    </Link>
                                                    <a href={`${BACKEND_URL}patient-detail/${patient.mr}`}
                                                       target={'_blank'}
                                                       className="btn-icon btn-hover-warning mx-3" title={"View Patient Detail"}>
                                                        <BeachAccessIcon  color="primary"></BeachAccessIcon>
                                                    </a>
                                                    <a href={`${BACKEND_URL}invoice-print/${patient.mr}`}
                                                       target={'_blank'}
                                                       className="btn-icon btn-hover-warning mx-3" title={"Print Invoice"}>
                                                        <PrintIcon  color="primary"></PrintIcon>
                                                    </a>
                                                    <a href={`${BACKEND_URL}report-print/${patient.mr}`}
                                                       target={'_blank'}
                                                       className="btn-icon btn-hover-warning mx-3" title={"Print Report"}>
                                                        <DescriptionIcon  color="primary"></DescriptionIcon>
                                                    </a>
                                                    <a  title={"Delete Patient Record"}
                                                        onClick={() => setCurrentPatient(patient.id, 'patient')}
                                                        key={index}
                                                        className="btn-icon btn-light btn-hover-danger mx-3">
                                                        <DeleteForeverIcon  color="secondary"></DeleteForeverIcon>

                                                    </a>
                                                    <a
                                                        title={"Delete Patient Report"}
                                                        onClick={() => setCurrentPatient(patient.id, 'report')}
                                                        key={index}
                                                        className="btn-icon btn-light btn-hover-danger mx-3">
                                                        <HighlightOffIcon  color="secondary"></HighlightOffIcon>
                                                    </a>

                                                </div>
                                            </td>
                                            <td>{patient.mr}</td>
                                            <td>{patient.first_name }</td>
                                            <td>{patient.last_name}</td>
                                            <td>{patient.passport_number}</td>
                                            {/*<td>{(patient.registered_at) ? datetime_format(patient.registered_at) : ""}</td>*/}
                                            <td>{(patient.collection_datetime) ? datetime_format(patient.collection_datetime) : ""}</td>
                                            <td>{patient.gender}</td>
                                            <td>{patient.dob ? date_format(patient.dob)
                                                    : ''}</td>
                                            {/*<td>{patient.phone_no}</td>*/}
                                            <td>{patient.email}</td>
                                            <td>{patient.report_status.name}</td>

                                        </tr>
                                    ))
                                ) : (
                                    <tr key={"nopatient"}>
                                        <td colSpan={13}>No Patient</td>
                                    </tr>
                                )}
                                </tbody>
                            </table>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{t("Delete Patient / Report")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{t("Are you sure you want to Delete?")}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {t("Close")}
                    </Button>
                    <Button variant="primary" onClick={deletePatient}>
                        {t("Confirm")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

