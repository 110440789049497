import React from 'react';
import Pdf from "react-to-pdf";
const ref = React.createRef();

const HatePdf = (props) => {
    const options = {
        orientation: 'p',
        unit: 'px',
        format: 'a4',
    };
    return (
        <>
            <Pdf targetRef={ref} filename="post.pdf" options={options} x={.5} y={.5} scale={0.8}>
                {({ toPdf }) => <button onClick={toPdf}>Save PDF</button>}
            </Pdf>
            <div className="Post" ref={ref}>
                <div  style={{height:'297mm'}}>
                <h1>You are good one</h1>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Atque inventore maiores modi, pariatur rem sequi voluptatem! Alias beatae consectetur deserunt esse hic libero, modi quae qui, reiciendis saepe sapiente sequi.</p>
                </div>
                <div  style={{height:'297mm'}}>
                    <h1>You are good two</h1>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Atque inventore maiores modi, pariatur rem sequi voluptatem! Alias beatae consectetur deserunt esse hic libero, modi quae qui, reiciendis saepe sapiente sequi.</p>
                </div>
                <div  style={{height:'297mm'}}>
                    <h1>You are good three</h1>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Atque inventore maiores modi, pariatur rem sequi voluptatem! Alias beatae consectetur deserunt esse hic libero, modi quae qui, reiciendis saepe sapiente sequi.</p>
                </div>
            </div>


        </>
    );
}

export default HatePdf;