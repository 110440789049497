import React, {useEffect, useState, useRef} from "react";
import axios from "axios";
import $ from "jquery";
import DataTable from "datatables.net";
import {Link} from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import {useHistory} from 'react-router';
import {toAbsoluteUrl} from "../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import alertify from "alertifyjs";
import {date_format, datetime_format} from "../Common/DateFormat";

import {API_URL} from '../constants.js';

import {
    Card,
    CardBody,
    CardHeader,
    CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {useTranslation} from "react-i18next";

export function ItemConsumptionList(props) {
    const [consumptionType, setConsumptionType] =  useState(props.match.params.type.charAt(0).toUpperCase() + props.match.params.type.slice(1));
    const { t } = useTranslation();
    const [itemConsumptions, setItemConsumptions] = useState([]);
    const [itemConsumption, setItemConsumption] = useState(-1);
    const [show, setShow] = useState(false);
    const history = useHistory();
    const [disabled, setDisabled] = useState(0);
    const [iconDisabled, setIconDisabled] = useState(1);

    const setCurrentItemConsumption = (itemConsumption) => {
        setItemConsumption(itemConsumption);
        setShow(true);

    };

    const handleClose = () => setShow(false);

    const deleteItemConsumption = () => {
        setDisabled(1);
        setIconDisabled(0);
        axios.post(
            API_URL + 'item-consumption/' + itemConsumption + '/delete'
        ).then((response) => {
            if (response.data.status) {
                history.go(0);
                setShow(false);
                alertify.success(response.data.message);
            } else {
                setDisabled(0);
                setIconDisabled(1);
                alertify.error(response.data.message);
                return null;
            }
        }).catch((error) => {
            setDisabled(0);
            setIconDisabled(1);
            alertify.error("Something went wrong. Try again!");
        });
    }

    useEffect(() => {

        axios.get(API_URL + 'item-consumption/'+props.match.params.type).then((response) => {
            if (response.data.status) {

                setItemConsumptions(response.data.data);

                 $("#itemConsumption_table").DataTable();
            } else {
                alertify.error(response.data.message);
                return null;
            }
        }).catch((error) => {
            alertify.error("Something went wrong. Try again!");
        });

    }, []);


    return (
        <>
            <Row>
                <Col md={12}>
                    <Card>
                        <CardHeader title={`${consumptionType} List`}>
                            <CardHeaderToolbar>
                                <Link to={`/item-consumption/create/${props.match.params.type}`} className="btn btn-primary btn-sm mx-3">
                                    {t(`Add New ${consumptionType}`)}
                                </Link>
                            </CardHeaderToolbar>
                        </CardHeader>
                        <CardBody style={{overflow:'auto'}}>

                            <table id="itemConsumption_table" className="stratprop_datatable table table-bordered table-striped">
                                <thead>
                                <tr>
                                    <th>SN.</th>
                                    <th>{t("Name")}</th>
                                    <th>{t("Date")}</th>
                                    <th>{t("Lot Number")}</th>
                                    <th>{t("Quantity")}</th>
                                    <th>{t("Notes")}</th>
                                    <th>{t("Action")}</th>
                                </tr>
                                </thead>
                                <tbody>
                                {itemConsumptions.length > 0 ? (
                                    itemConsumptions.map((itemConsumption, index) => (
                                        <tr key={itemConsumption.id}>
                                            <td>{index + 1}</td>
                                            <td>{itemConsumption.item.name}</td>
                                            <td>{itemConsumption.date}</td>
                                            <td>{itemConsumption.lot_number}</td>
                                            <td>{itemConsumption.quantity}</td>
                                            <td>{itemConsumption.notes}</td>
                                            <td>
                                                <a
                                                    onClick={() => setCurrentItemConsumption(itemConsumption.id)}
                                                    key={itemConsumption.id}
                                                    className="btn btn-icon btn-light btn-hover-danger btn-sm">
                                                    <span className="svg-icon svg-icon-md svg-icon-danger">
                                                      <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")}/>
                                                    </span>
                                                </a>

                                            </td>

                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan={7}>{t("No Record Found")}</td>
                                    </tr>
                                )}
                                </tbody>
                            </table>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Recrod</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to Delete?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={deleteItemConsumption} disabled={disabled}>
                        Confirm
                        <span
                            dangerouslySetInnerHTML={{__html: disabled ? `<i class='fas fa-spinner fa-spin'></i>` : ``}}/>
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}