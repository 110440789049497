import React, {useEffect, useState, useRef} from "react";
import axios from "axios";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import alertify from "alertifyjs";
import {API_URL} from "../constants.js";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'


import {
    Card,
    CardBody,
    CardHeader,
    CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {Link} from "react-router-dom";

export function EditSupplier(props) {
    const name = useRef();
    const phone_no = useRef();
    const tax_no = useRef();
    const address = useRef();
    const notes = useRef();

    const [disabled, setDisabled] = useState(0);
    const [iconDisabled, setIconDisabled] = useState(1);
    const [supplieres, setSuppliers] = useState({});
    const id = props.match.params.id;

    useEffect(() => {
        axios.get(API_URL + 'supplier/' + id).then((response) => {
            if (response.data.status) {
                setSuppliers(response.data.data);

            } else {
                alertify.error(response.data.message);
                return null;
            }
        })
    }, []);

    const updateSuppliersHandler = (e) => {
        e.preventDefault();
        setDisabled(1);
        setIconDisabled(0);
        axios.post(API_URL + "supplier/update",
            {
                id: supplieres.id,
                name: name.current.value,
                phone_no: phone_no.current.value,
                tax_no: tax_no.current.value,
                address: address.current.value,
                notes: notes.current.value
            }
        ).then((response) => {
            setDisabled(0);
            setIconDisabled(1);
            if (response.data.status) {
                alertify.success(response.data.message);

                props.history.push("/supplier/list");
            } else {
                alertify.error(response.data.message);
                return null;
            }
        }).catch((error) => {
            setDisabled(0);
            setIconDisabled(1);
            if (error.message === 'Request failed with status code 401') {
                //props.logout();
            }
        });
    }
    return (
        <Row>
            <Col md={12}>
                <Card>
                    <CardHeader title="Edit Supplier">
                        <CardHeaderToolbar>
                            <Link to="/supplier/list" className="btn btn-primary btn-sm mx-3">
                                Supplier List
                            </Link>
                        </CardHeaderToolbar>
                    </CardHeader>
                    <CardBody>

                        <div className="mt-5">
                            <Form onSubmit={updateSuppliersHandler}>
                                <Form.Row>
                                    <Form.Group as={Col}>
                                        <Form.Label>Name</Form.Label>
                                        <Form.Control ref={name} placeholder="Enter Supplier Name"
                                                      defaultValue={supplieres.name}/>
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>Phone No</Form.Label>
                                        <Form.Control  ref={phone_no}  placeholder="Enter Phone No"
                                                       defaultValue={supplieres.phone_no}/>
                                    </Form.Group>


                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col}>
                                        <Form.Label>Tax No</Form.Label>
                                        <Form.Control  ref={tax_no}  placeholder="Enter Tax No"
                                                       defaultValue={supplieres.tax_no}/>
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>Address</Form.Label>
                                        <Form.Control as="text" ref={address} placeholder="Enter Address"
                                                      defaultValue={supplieres.address}/>
                                    </Form.Group>

                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col}>
                                        <Form.Label>Notes</Form.Label>
                                        <Form.Control as={'textarea'}  ref={notes} rows={5} placeholder="Enter Notes"
                                                       defaultValue={supplieres.notes}/>
                                    </Form.Group>

                                </Form.Row>
                                <Button variant="primary" className="float-right" type="submit" disabled={disabled}>
                                    Save
                                    <span
                                        dangerouslySetInnerHTML={{__html: disabled ? `<i class='fas fa-spinner fa-spin'></i>` : ``}}/>
                                </Button>
                            </Form>

                        </div>

                    </CardBody>
                </Card>
            </Col>
        </Row>
    );
}