/*Accounts Manamgement*/
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import {useLocation} from "react-router";
import {NavLink} from "react-router-dom";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl, checkIsActive} from "../../../../_helpers";
import {makeStyles} from '@material-ui/core/styles';
import AirportShuttleIcon from '@material-ui/icons/AirportShuttle';
import ExtensionIcon from '@material-ui/icons/Extension';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import PeopleIcon from '@material-ui/icons/People';
import HelpIcon from '@material-ui/icons/Help';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import RedeemIcon from '@material-ui/icons/Redeem'; //Income
import CreditCardIcon from '@material-ui/icons/CreditCard'; //Expense
import LoyaltyIcon from '@material-ui/icons/Loyalty'; //Category
import {check_role} from '../../../../../app/pages/Common/RoleMenuCheck';


const useStyles = makeStyles(theme => ({
    root: {
        color: theme.palette.text.primary,
    },
    icon: {
        margin: theme.spacing(1),
        fontSize: 32,
    },
}));

export function AsideMenuAccountsList({layoutProps}) {
    const classes = useStyles();
    const location = useLocation();
    const getMenuItemActive = (url, hasSubmenu = false) => {
        return checkIsActive(location, url)
            ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
            : "";
    };

    return (
        <>
            {/* begin::Menu Nav */}
            <ul className={`menu-nav ${layoutProps.ulClasses}`}>

                {/* Accounts */}
                {/* begin::section */}
                <li className="menu-section ">
                    <h4 className="menu-text">Income </h4>
                    <i className="menu-icon flaticon-more-v2"></i>
                </li>
                {/* end:: section */}

                {/* Agents Type Pages */}
                {/*begin::1 Level*/}
                {check_role(['transaction-category/create/income', 'transaction-category/list/income']) ? (
                    <li
                        className={`menu-item menu-item-submenu ${getMenuItemActive(
                            "/transaction-category", true
                        )}`}
                        aria-haspopup="true"
                        data-menu-toggle="hover"
                    >
                        <NavLink className="menu-link menu-toggle" to="/transaction-category">
            <span className="svg-icon menu-icon">
              <LoyaltyIcon className={classes.icon}/>
            </span>
                            <span className="menu-text">Income Category</span>
                            <i className="menu-arrow"/>
                        </NavLink>
                        <div className="menu-submenu ">
                            <i className="menu-arrow"/>
                            <ul className="menu-subnav">
                                <li className="menu-item  menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">Income Category</span>
                </span>
                                </li>

                                {/*begin::2 Level*/}
                                {check_role(['transaction-category/create/income']) ? (
                                    <li
                                        className={`menu-item ${getMenuItemActive("/transaction-category/create/income")}`}
                                        aria-haspopup="true"
                                    >
                                        <NavLink className="menu-link" to="/transaction-category/create/income">
                                            <i className="menu-bullet menu-bullet-dot">
                                                <span/>
                                            </i>
                                            <span className="menu-text">Add Income Category</span>
                                        </NavLink>
                                    </li>
                                ) : ''}
                                {check_role(['transaction-category/list/income']) ? (
                                    <li
                                        className={`menu-item ${getMenuItemActive("/transaction-category/list/income")}`}
                                        aria-haspopup="true"
                                    >
                                        <NavLink className="menu-link" to="/transaction-category/list/income">
                                            <i className="menu-bullet menu-bullet-dot">
                                                <span/>
                                            </i>
                                            <span className="menu-text">Income Category List</span>
                                        </NavLink>
                                    </li>
                                ) : ''}
                                {/*end::2 Level*/}
                            </ul>
                        </div>
                    </li>
                ) : ''}
                {/*end::1 Level*/}

                {/*begin::1 Level*/}
                {check_role(['transaction/create/income', 'transaction/list/income']) ? (
                    <li
                        className={`menu-item menu-item-submenu ${getMenuItemActive(
                            "/transaction", true
                        )}`}
                        aria-haspopup="true"
                        data-menu-toggle="hover"
                    >
                        <NavLink className="menu-link menu-toggle" to="/transaction/">
            <span className="svg-icon menu-icon">
              <RedeemIcon className={classes.icon}/>
            </span>
                            <span className="menu-text">Income</span>
                            <i className="menu-arrow"/>
                        </NavLink>
                        <div className="menu-submenu ">
                            <i className="menu-arrow"/>
                            <ul className="menu-subnav">
                                <li className="menu-item  menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">Income</span>
                </span>
                                </li>

                                {/*begin::2 Level*/}
                                {check_role(['transaction/create/income']) ? (
                                    <li
                                        className={`menu-item ${getMenuItemActive("/transaction/create/income")}`}
                                        aria-haspopup="true"
                                    >
                                        <NavLink className="menu-link" to="/transaction/create/income">
                                            <i className="menu-bullet menu-bullet-dot">
                                                <span/>
                                            </i>
                                            <span className="menu-text">Add Income</span>
                                        </NavLink>
                                    </li>
                                ) : ''}
                                {check_role(['transaction/list/income']) ? (
                                    <li
                                        className={`menu-item ${getMenuItemActive("/transaction/list/income")}`}
                                        aria-haspopup="true"
                                    >
                                        <NavLink className="menu-link" to="/transaction/list/income">
                                            <i className="menu-bullet menu-bullet-dot">
                                                <span/>
                                            </i>
                                            <span className="menu-text">Income List</span>
                                        </NavLink>
                                    </li>
                                ) : ''}
                                {/*end::2 Level*/}
                            </ul>
                        </div>
                    </li>
                ) : ''}
                {/*end::1 Level*/}

                {/* begin::section */}
                <li className="menu-section ">
                    <h4 className="menu-text">Expense </h4>
                    <i className="menu-icon flaticon-more-v2"></i>
                </li>
                {/* end:: section */}

                {/* Agents Type Pages */}
                {/*begin::1 Level*/}
                {check_role(['transaction-category/create/expense', 'transaction-category/list/expense']) ? (
                    <li
                        className={`menu-item menu-item-submenu ${getMenuItemActive(
                            "/transaction-category", true
                        )}`}
                        aria-haspopup="true"
                        data-menu-toggle="hover"
                    >
                        <NavLink className="menu-link menu-toggle" to="/transaction-category">
            <span className="svg-icon menu-icon">
              <LoyaltyIcon className={classes.icon}/>
            </span>
                            <span className="menu-text">Expense Category</span>
                            <i className="menu-arrow"/>
                        </NavLink>
                        <div className="menu-submenu ">
                            <i className="menu-arrow"/>
                            <ul className="menu-subnav">
                                <li className="menu-item  menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">Expense Category</span>
                </span>
                                </li>

                                {/*begin::2 Level*/}
                                {check_role(['transaction-category/create/expense']) ? (
                                    <li
                                        className={`menu-item ${getMenuItemActive("/transaction-category/create/expense")}`}
                                        aria-haspopup="true"
                                    >
                                        <NavLink className="menu-link" to="/transaction-category/create/expense">
                                            <i className="menu-bullet menu-bullet-dot">
                                                <span/>
                                            </i>
                                            <span className="menu-text">Add Expense Category</span>
                                        </NavLink>
                                    </li>
                                ) : ''}
                                {check_role(['transaction-category/list/expense']) ? (
                                    <li
                                        className={`menu-item ${getMenuItemActive("/transaction-category/list/expense")}`}
                                        aria-haspopup="true"
                                    >
                                        <NavLink className="menu-link" to="/transaction-category/list/expense">
                                            <i className="menu-bullet menu-bullet-dot">
                                                <span/>
                                            </i>
                                            <span className="menu-text">Expense Category List</span>
                                        </NavLink>
                                    </li>
                                ) : ''}
                                {/*end::2 Level*/}
                            </ul>
                        </div>
                    </li>
                ) : ''}
                {/*end::1 Level*/}

                {/*begin::1 Level*/}
                {check_role(['transaction/create/expense', 'transaction/list/expense']) ? (
                    <li
                        className={`menu-item menu-item-submenu ${getMenuItemActive(
                            "/transaction", true
                        )}`}
                        aria-haspopup="true"
                        data-menu-toggle="hover"
                    >
                        <NavLink className="menu-link menu-toggle" to="/transaction">
            <span className="svg-icon menu-icon">
              <CreditCardIcon className={classes.icon}/>
            </span>
                            <span className="menu-text">Expense</span>
                            <i className="menu-arrow"/>
                        </NavLink>
                        <div className="menu-submenu ">
                            <i className="menu-arrow"/>
                            <ul className="menu-subnav">
                                <li className="menu-item  menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">Expense</span>
                </span>
                                </li>

                                {/*begin::2 Level*/}
                                {check_role(['transaction/create/expense']) ? (
                                    <li
                                        className={`menu-item ${getMenuItemActive("/transaction/create/expense")}`}
                                        aria-haspopup="true"
                                    >
                                        <NavLink className="menu-link" to="/transaction/create/expense">
                                            <i className="menu-bullet menu-bullet-dot">
                                                <span/>
                                            </i>
                                            <span className="menu-text">Add Expense</span>
                                        </NavLink>
                                    </li>
                                ) : ''}
                                {check_role(['transaction/list/expense']) ? (
                                    <li
                                        className={`menu-item ${getMenuItemActive("/transaction/list/expense")}`}
                                        aria-haspopup="true"
                                    >
                                        <NavLink className="menu-link" to="/transaction/list/expense">
                                            <i className="menu-bullet menu-bullet-dot">
                                                <span/>
                                            </i>
                                            <span className="menu-text">Expense List</span>
                                        </NavLink>
                                    </li>
                                ) : ''}
                                {/*end::2 Level*/}
                            </ul>
                        </div>
                    </li>
                ) : ''}
                {/*end::1 Level*/}

            </ul>

            {/* end::Menu Nav */}
        </>
    );
}
