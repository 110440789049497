import React, {useEffect, useState, useRef} from "react";
import axios from "axios";
import $ from "jquery";
import DataTable from "datatables.net";
import {Link} from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import {useHistory} from 'react-router';
import {toAbsoluteUrl} from "../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import alertify from "alertifyjs";
import {date_format, datetime_format} from "../Common/DateFormat";

import {API_URL} from '../constants.js';

import {
    Card,
    CardBody,
    CardHeader,
    CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {useTranslation} from "react-i18next";

export function FaqList(props) {
    const { t } = useTranslation();
    const [faqs, setFaqs] = useState([]);
    const [faq, setFaq] = useState(-1);
    const [show, setShow] = useState(false);
    const history = useHistory();
    const [disabled, setDisabled] = useState(0);
    const [iconDisabled, setIconDisabled] = useState(1);

    const setCurrentFaq = (faq) => {
        setFaq(faq);
        setShow(true);

    };

    const handleClose = () => setShow(false);

    const deleteFaq = () => {
        setDisabled(1);
        setIconDisabled(0);
        axios.post(
            API_URL + 'faq/' + faq.id + '/delete'
        ).then((response) => {
            if (response.data.status) {
                history.go(0);
                setShow(false);
                alertify.success(response.data.message);
            } else {
                setDisabled(0);
                setIconDisabled(1);
                alertify.error(response.data.message);
                return null;
            }
        }).catch((error) => {
            setDisabled(0);
            setIconDisabled(1);
            alertify.error("Something went wrong. Try again!");
        });
    }

    useEffect(() => {

        axios.get(API_URL + 'faq').then((response) => {
            if (response.data.status) {

                setFaqs(response.data.data);

                $("#faq_table").DataTable();
            } else {
                alertify.error(response.data.message);
                return null;
            }
        }).catch((error) => {
            alertify.error("Something went wrong. Try again!");
        });

    }, []);


    return (
        <>
            <Row>
                <Col md={12}>
                    <Card>
                        <CardHeader title={t("Faqs List")}>
                            <CardHeaderToolbar>
                                <Link to="/faq/create" className="btn btn-primary btn-sm mx-3">
                                    {t("Create Faq")}
                                </Link>
                            </CardHeaderToolbar>
                        </CardHeader>
                        <CardBody style={{overflow:'auto'}}>

                            <table id="faq_table" className="stratprop_datatable table table-bordered table-striped">
                                <thead>
                                <tr>
                                    <th>{t("SN.")}</th>
                                    <th>{t("Name")}</th>
                                    <th>{t("Notes")}</th>
                                    <th>{t("Actions")}</th>
                                </tr>
                                </thead>
                                <tbody>
                                {faqs.length > 0 ? (
                                    faqs.map((faq, index) => (
                                        <tr key={faq.id}>
                                            <td>{index + 1}</td>
                                            <td>{faq.name}</td>
                                            <td>{faq.notes}</td>
                                            <td>
                                                <Link to={`/faq/edit/${faq.id}`}
                                                      className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3">
                                                    <span className="svg-icon svg-icon-md svg-icon-primary">
                                                          <SVG
                                                              src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
                                                          />
                                                    </span>
                                                </Link>
                                                <a
                                                    onClick={() => setCurrentFaq(faq)}
                                                    key={faq.id}
                                                    className="btn btn-icon btn-light btn-hover-danger btn-sm">
                                                    <span className="svg-icon svg-icon-md svg-icon-danger">
                                                      <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")}/>
                                                    </span>
                                                </a>

                                            </td>

                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan={4}>{t("No Faq")}</td>
                                    </tr>
                                )}
                                </tbody>
                            </table>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Faq</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to Delete?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={deleteFaq} disabled={disabled}>
                        Confirm
                        <span
                            dangerouslySetInnerHTML={{__html: disabled ? `<i class='fas fa-spinner fa-spin'></i>` : ``}}/>
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}