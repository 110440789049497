import React, {useEffect, useState, useRef} from "react";
import ReactPDF from '@react-pdf/renderer';
import ReactDOM from 'react-dom';
import { PDFViewer } from '@react-pdf/renderer';
import YourPdf from './YourPdf';


export function Mypdf(props) {
    const App = () => (
        <PDFViewer>
            <YourPdf />
        </PDFViewer>
    );

    return (
        <>
            ReactDOM.render(<App />, document.getElementById('kt_content'));
        </>
    )
}