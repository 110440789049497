import React, {useEffect, useState, useRef} from "react";
import axios from "axios";
import $ from "jquery";
import DataTable from "datatables.net";
import {Link} from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import {useHistory} from 'react-router';
import {toAbsoluteUrl} from "../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import alertify from "alertifyjs";
import {date_format, datetime_format} from "../Common/DateFormat";
import {API_URL, BACKEND_URL} from '../constants.js';
import PrintIcon from '@material-ui/icons/Print';
import DescriptionIcon from '@material-ui/icons/Description';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import EditIcon from '@material-ui/icons/Edit';
import BeachAccessIcon from '@material-ui/icons/BeachAccess';
import Form from "react-bootstrap/Form";

import {
    Card,
    CardBody,
    CardHeader,
    CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import {useTranslation} from "react-i18next";
import {forEach} from "react-bootstrap/cjs/ElementChildren";

export function PatientsReportBulkUpdate(props) {
    const {t} = useTranslation();
    const sample_ids_textarea = useRef();
    const [patients, setPatients] = useState([]);
    const [patient, setPatient] = useState(null);
    const [show, setShow] = useState(false);
    const [action, setAction] = useState('patient');
    const [checkedItems, setCheckedItems] = useState({});
    const [barcodeFile, setBarcodeFile] = useState('');

    const [disabled, setDisabled] = useState(0);
    const [iconDisabled, setIconDisabled] = useState(1);

    const history = useHistory()

    const setCurrentPatient = (patient, action) => {
        setPatient(patient);
        setAction(action);
        setShow(true);
    };

    const handleClose = () => setShow(false);

    const deletePatient = () => {
        axios.post(
            API_URL + 'patient/delete',
            {
                id: patient,
                action: action
            }
        ).then((response) => {
            if (response.data.status) {
                history.go(0);
                setShow(false);
                alertify.success(response.data.message);
            } else {
                alertify.error(response.data.message);
                return null;
            }
        })
    }

    const handleCheckboxChange = (event, id) => {
        setCheckedItems({
            ...checkedItems,
            [id]: event.target.checked
        });

    };

    const createBulkReports = () => {
        let mr_arr = [];
        for (let i in checkedItems) {
            if (checkedItems[i] == true) {
                mr_arr.push(i);
            }
        }
        history.push(`/report-update/` + mr_arr.join(','));
    };

    const deleteBulkPatients = () => {
        setDisabled(1);
        setIconDisabled(0);
        let mr_arr = [];
        for (let i in checkedItems) {
            if (checkedItems[i] == true) {
                mr_arr.push(i);
            }
        }
        axios.post(API_URL + "patient/delete-bulk-patients",
            {
                "mrs": mr_arr,
            }
        ).then((response) => {
            setDisabled(0);
            setIconDisabled(1);
            if (response.data.status == true) {
                alertify.success(response.data.message);
                window.location.reload(`patients-without-report`);
            } else if (response.data.status == false) {
                alertify.error(response.data.message);
                return null;
            }
        }).catch((error) => {
            setDisabled(0);
            setIconDisabled(1);
            if (error.message === 'Request failed with status code 401') {
                //props.logout();
            }
        });


    };

    useEffect(() => {
        console.log("items: ", checkedItems);
    }, [checkedItems]);

    const uploadSampleIdsList = (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('sample_ids_textarea', sample_ids_textarea.current.value);

        axios.post(API_URL + "patient/upload-bulk-update-barcode-list",
            formData).then((response) => {
            if (response.data.status) {
                if (response.data.data.length > 0) {
                    history.push(`/report-update/` + response.data.data.join(','));
                }
            } else {
                alertify.error(response.data.message);
                return null;
            }
        }).catch((error) => {
            if (error.message === 'Request failed with status code 401') {
                //props.logout();
            }
        });
    }

    const uploadBarcodeList = (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('barcode_file', barcodeFile, 'sample_ids_textarea', sample_ids_textarea);

        axios.post(API_URL + "patient/upload-bulk-update-barcode-list",
            formData, {
                'content-type': 'multipart/form-data'
            }).then((response) => {
            if (response.data.status) {
                if (response.data.data.length > 0) {
                    history.push(`/report-update/` + response.data.data.join(','));
                }
            } else {
                alertify.error(response.data.message);
                return null;
            }
        }).catch((error) => {
            if (error.message === 'Request failed with status code 401') {
                //props.logout();
            }
        });
    }

    const handleBarcodeFile = (e) => {
        setBarcodeFile(e.target.files[0]);
    }

    return (
        <>
            <Row>
                <Col md={12}>
                    <Card>
                        <CardHeader title={t("Patients To Update Report")}>
                            <CardHeaderToolbar>

                            </CardHeaderToolbar>
                        </CardHeader>
                        <CardBody style={{overflow: 'auto'}}>
                            <Form onSubmit={uploadBarcodeList}>
                                <Form.Row>
                                    <Form.Group as={Col}>
                                        <Form.Label>{t("Barcode List")}</Form.Label>
                                        <Form.Control type={"file"} onChange={handleBarcodeFile}/>
                                    </Form.Group>
                                    <Form.Group as={Col}>

                                    </Form.Group>
                                </Form.Row>
                                <Button variant="primary" className="float-right" type="submit"
                                        disabled={disabled}>
                                    {t("Save")}
                                    <span
                                        dangerouslySetInnerHTML={{__html: disabled ? `<i class='fas fa-spinner fa-spin'></i>` : ``}}/>
                                </Button>

                            </Form>
                        </CardBody>
                    </Card>

                </Col>
                <Col md={12}>
                    <Card>
                        <CardHeader title={t("Patients To Update Report with Sample IDs List")}>
                        </CardHeader>
                        <CardBody style={{overflow: 'auto'}}>
                            <Form onSubmit={uploadSampleIdsList}>
                                <Form.Row>
                                    <Form.Group as={Col}>
                                        <Form.Label>{t("Sample IDs")}</Form.Label>
                                        <Form.Control as={'textarea'} rows={10} ref={sample_ids_textarea}
                                                      placeholder="Enter sample IDs (One ID in one line)"/>
                                    </Form.Group>
                                </Form.Row>
                                <Button variant="primary" className="float-right" type="submit"
                                        disabled={disabled}>
                                    {t("Save")}
                                    <span
                                        dangerouslySetInnerHTML={{__html: disabled ? `<i class='fas fa-spinner fa-spin'></i>` : ``}}/>
                                </Button>

                            </Form>
                        </CardBody>
                    </Card>

                </Col>
            </Row>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{t("Delete Patient / Report")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{t("Are you sure you want to Delete?")}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {t("Close")}
                    </Button>
                    <Button variant="primary" onClick={deletePatient}>
                        {t("Confirm")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

