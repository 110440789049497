import React, {useEffect, useState, useRef} from "react";
import axios from "axios";
import $ from "jquery";
import DataTable from "datatables.net";
import {Link} from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import {useHistory} from 'react-router';
import {toAbsoluteUrl} from "../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import alertify from "alertifyjs";
import {date_format, datetime_format} from "../Common/DateFormat";
import PrintIcon from '@material-ui/icons/Print';
import DescriptionIcon from '@material-ui/icons/Description';
import {API_URL, BACKEND_URL} from '../constants.js';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import BeachAccessIcon from '@material-ui/icons/BeachAccess';
import EmailIcon from '@material-ui/icons/Email';

import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import {useTranslation} from "react-i18next";
import {shallowEqual, useSelector} from "react-redux";

export function PatientFilter(props) {
  const {t} = useTranslation();
  const mr = useRef();
  const sample_id = useRef();
  const test_id = useRef();
  const name = useRef();
  const phone_no = useRef();
  const collection_center_id = useRef();
  const booking = useRef();
  const status = useRef();
  const from_date = useRef();
  const to_date = useRef();
  const changedStatus = useRef();
  const status_created_at = useRef();
  const [reportStatuses, setReportStatuses] = useState([]);
  const notes = useRef();

  const [disabled, setDisabled] = useState(0);
  const [iconDisabled, setIconDisabled] = useState(1);
  const [patients, setPatients] = useState([]);
  const [collectionCenters, setCollectionCenters] = useState([]);
  const [tests, setTests] = useState([]);
  const [patient, setPatient] = useState(null);
  const [show, setShow] = useState(false);
  const [statusModalShow, setStatusModalShow] = useState(false);
  const [selectedPatientStatus, setSelectedPatientStatus] = useState("0");
  const history = useHistory();
  const {authUser} = useSelector(
    ({auth}) => ({
      authUser: auth.user?.data,

    }),
    shallowEqual
  );

  const setCurrentPatient = (patient_id, action) => {
    setPatient(patient_id);
    if (action == 'delete') {
      setShow(true);
    } else if (action == 'status') {
      let patient = patients.find(patient => patient.id == patient_id);
      setSelectedPatientStatus(patient.status);
      setStatusModalShow(true);
    } else if (action == 'mail') {
      setDisabled(1);
      axios.post(
        API_URL + 'patient/send-mail',
        {id: patient_id}
      ).then((response) => {
        setDisabled(0);
        if (response.data.status) {
          alertify.success(response.data.message);
        } else {
          alertify.error(response.data.message);
          return null;
        }
      });
    }
  };

  const handleClose = () => {
    setStatusModalShow(false);
    setShow(false);
  }

  const deletePatient = (e) => {
    e.preventDefault();
    axios.post(
      API_URL + 'patient/' + patient + '/delete',
      {id: patient}
    ).then((response) => {
      if (response.data.status) {
        history.go(0);
        setShow(false);
        alertify.success(response.data.message);
      } else {
        alertify.error(response.data.message);
        return null;
      }
    })
  }

  const patientFilterHandler = (e) => {
    e.preventDefault();
    setDisabled(1);
    setIconDisabled(0);
    axios.post(
      API_URL + 'patient/filter',
      {
        mr: mr.current.value,
        sample_id: sample_id.current.value,
        test_id: test_id.current.value,
        name: name.current.value,
        phone_no: phone_no.current.value,
        from_date: from_date.current.value,
        to_date: to_date.current.value,
        collection_center_id: collection_center_id.current.value,
        booking: booking.current.value,
        report_status_id: status.current.value
      }
    ).then((response) => {
      setDisabled(0);
      setIconDisabled(1);
      console.log(response.data.data.patients);
      if (response.data.status) {
        setPatients(response.data.data.patients);
      } else {
        return null;
      }
    })
  }

  const changeStatusHandler = (e) => {
    e.preventDefault();
    setDisabled(1);
    setIconDisabled(0);
    axios.post(
      API_URL + 'patient/change-status',
      {
        id: patient,
        report_status_id: changedStatus.current.value,
        status_created_at: status_created_at.current.value,
        notes: notes.current.value
      }
    ).then((response) => {
      setDisabled(0);
      setIconDisabled(1);
      if (response.data.status) {
        history.go(0);
        setShow(false);
        setStatusModalShow(false);
        alertify.success(response.data.message);
      } else {
        alertify.error(response.data.message);
        return null;
      }
    })
  }

  const selectedPatientStatusHandler = (e) => {
    setSelectedPatientStatus(e.target.value);
  }

  useEffect(() => {
    axios.get(API_URL + 'patient').then((response) => {
      if (response.data.status) {
        setPatients(response.data.data.patients);
        setCollectionCenters(response.data.data.collectioncenters);
        setTests(response.data.data.tests);
        // $("#patient_table").DataTable();
      } else {
        alertify.error(response.data.message);
        return null;
      }
    })
      .catch((error) => {

        if (error.message === 'Request failed with status code 401') {
          //props.logout();
        }
      });

  }, []);

  useEffect(() => {
    axios.get(API_URL + 'report-status').then((response) => {
      if (response.data.status) {
        setReportStatuses(response.data.data);
      }
    });
  }, []);


  return (
    <>
      <Row>
        <Col md={12}>
          <Card>
            <CardHeader title="Patients List">
              <CardHeaderToolbar>
                <Link to="/patient/create" className="btn btn-primary btn-sm mx-3">
                  Create Patient
                </Link>
              </CardHeaderToolbar>
            </CardHeader>
            <div className={"mt-5"}>
              <Form onSubmit={patientFilterHandler}>
                <Row className={"ml-5  mr-5 mb-10 "}>
                  <Col xs={12} md={4}>
                    <Form.Label>Mr.No</Form.Label>
                    <Form.Control ref={mr} placeholder="Search By Mr.No"/>
                  </Col>
                  <Col xs={12} md={4}>
                    <Form.Label>Collection Center</Form.Label>
                    <Form.Control as="select" ref={collection_center_id}>
                      <option value="0" key={'collection_center_0'}>Choose...</option>

                      {collectionCenters.map((collection_center, index) => (
                        <option key={`collection_center_` + index}
                                value={collection_center.id}>{collection_center.name}</option>
                      ))}

                    </Form.Control>
                  </Col>
                  <Col xs={12} md={4}>
                    <Form.Label>Test Type</Form.Label>
                    <Form.Control as="select" ref={test_id}>
                      <option value="0" key={'test_0'}>Choose...</option>

                      {tests.map((test, index) => (
                        <option key={'test_' + index}
                                value={test.id}>{test.name}</option>
                      ))}

                    </Form.Control>
                  </Col>
                  <Col xs={12} md={4}>
                    <Form.Label>Name</Form.Label>
                    <Form.Control ref={name} placeholder="Search by Name"/>
                  </Col>
                  <Col xs={12} md={4}>
                    <Form.Label>Phone No</Form.Label>
                    <Form.Control ref={phone_no} placeholder="Search by Phone"/>
                  </Col>
                  <Col xs={12} md={4}>
                    <Form.Label>Booking</Form.Label>
                    <Form.Control as="select" ref={booking}>
                      <option value="0" key={"booking"}>Choose...</option>
                      <option value="inlab" key={"inlab"}>Inlab</option>
                      <option value="online" key={"online"}>Online</option>
                    </Form.Control>
                  </Col>
                  <Col xs={12} md={4}>
                    <Form.Label>Status</Form.Label>
                    <Form.Control as="select" ref={status}>
                      <option value="0" key={"status_0"}>Choose...</option>
                      {
                        reportStatuses.map(status => (
                          <option value={status.id} key={'status_' + status.id}>{status.name}</option>
                        ))
                      }

                    </Form.Control>
                  </Col>
                  <Col xs={12} md={4}>
                    <Form.Label>From Date</Form.Label>
                    <Form.Control type={"date"} ref={from_date} placeholder="Search by From Date"/>
                  </Col>
                  <Col xs={12} md={4}>
                    <Form.Label>To Date</Form.Label>
                    <Form.Control type={"date"} ref={to_date} placeholder="Search by To Date"/>
                  </Col>
                  <Col xs={12} md={4}>
                    <Form.Label>Sample ID</Form.Label>
                    <Form.Control ref={sample_id} placeholder="Search By Sample ID"/>
                  </Col>

                </Row>
                <Row>
                  <Col xs={3}></Col>
                  <Col xs={6}>
                    <Button variant="primary" size="lg" className="float-right btn-block"
                            type="submit"
                            disabled={disabled}>
                      Filter
                      <span
                        dangerouslySetInnerHTML={{__html: disabled ? `<i class='fas fa-spinner fa-spin'></i>` : ``}}/>
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>
            <CardBody style={{overflow: 'auto'}}>
              <table id="patient_table"
                     className="stratprop_datatable patient_datatable table table-striped table-bordered">
                <thead>
                <tr>
                  <th style={{width: '40px'}}>SN.</th>
                  <th style={{width: '100px'}}>Actions</th>
                  <th style={{width: '200px'}}>Status</th>
                  <th style={{width: '240px'}}>Patient</th>
                  <th style={{width: '200px'}}>Test Type</th>
                  <th style={{width: '150px'}}>DOB / Gender</th>
                </tr>
                </thead>
                <tbody>
                {patients.length > 0 ? (
                  patients.map((patient, index) => (
                    <tr key={`patient_${index + 1}`}>
                      <td>{index + 1}</td>
                      <td>
                        {authUser?.role_id == 4 ? (

                          <div>
                            <Link to={`/patient/edit/${patient.id}`}
                                  className="btn-icon btn-light btn-hover-primary mx-3"
                                  title={'Edit Patient'}>
                              <EditIcon color={'primary'}></EditIcon>
                            </Link>
                            <a href={`${BACKEND_URL}patient-detail/${patient.mr}`}
                               target={'_blank'}
                               className="btn-icon btn-hover-warning mx-3"
                               title={"View Patient Detail"}>
                              <BeachAccessIcon color="primary"></BeachAccessIcon>
                            </a>
                            <a href={`${BACKEND_URL}invoice-print/${patient.mr}`}
                               target={'_blank'}
                               className="btn-icon btn-hover-warning mx-3"
                               title={"Print Invoice"}>
                              <PrintIcon color="primary"></PrintIcon>
                            </a>


                          </div>
                        ) : (

                          <div>
                            <Link to={`/patient/edit/${patient.id}`}
                                  className="btn-icon btn-light btn-hover-primary mx-3"
                                  title={'Edit Patient'}>
                              <EditIcon color={'primary'}></EditIcon>
                            </Link>
                            <a href={`${BACKEND_URL}patient-detail/${patient.mr}`}
                               target={'_blank'}
                               className="btn-icon btn-hover-warning mx-3"
                               title={"View Patient Detail"}>
                              <BeachAccessIcon color="primary"></BeachAccessIcon>
                            </a>
                            <a href={`${BACKEND_URL}invoice-print/${patient.mr}`}
                               target={'_blank'}
                               className="btn-icon btn-hover-warning mx-3"
                               title={"Print Invoice"}>
                              <PrintIcon color="primary"></PrintIcon>
                            </a>
                            <a href={`${BACKEND_URL}report-print/${patient.mr}`}
                               target={'_blank'}
                               className="btn-icon btn-hover-warning mx-3"
                               title={"Print Report"}>
                              <DescriptionIcon color="primary"></DescriptionIcon>
                            </a>

                            <a
                              onClick={() => setCurrentPatient(patient.id, 'status')}
                              key={index + "del"}
                              className="btn-icon btn-hover-info mx-3"
                              title={'Change Status'}>
                              <AutorenewIcon color={'secondary'}></AutorenewIcon>
                            </a>
                            <a
                              onClick={() => setCurrentPatient(patient.id, 'delete')}
                              key={"delete_" + index}
                              className="btn-icon btn-light btn-hover-danger mx-3"
                              title={'Delete Patient'}>
                              <DeleteForeverIcon color={'action'}></DeleteForeverIcon>
                            </a>
                            <a
                              disabled={disabled}
                              title={"Send Email"}
                              onClick={() => setCurrentPatient(patient.id, 'mail')}
                              key={"send_mail_" + index}
                              className="btn-icon btn-light btn-hover-danger mx-3">
                              <EmailIcon color="secondary"></EmailIcon>
                              <span
                                dangerouslySetInnerHTML={{__html: disabled ? `<i class='fas fa-spinner fa-spin'></i>` : ``}}/>
                            </a>
                            <a href={`${BACKEND_URL}new-report-print/${patient.mr}`}
                               target={'_blank'}
                               className="btn-icon btn-hover-warning mx-3"
                               title={"Print Report New"}>
                              <DescriptionIcon color="primary"></DescriptionIcon>
                            </a>
                          </div>
                        )}

                      </td>
                      {/*<td>{patient.mr}</td>*/}
                      <td>
                        {patient.registered_at ? (
                          <>
                          <span className="badge badge-warning patient-badge text-white patient-badge">
                            Registered @ {(patient.registered_at) ? datetime_format(patient.registered_at) : ""}
                          </span>

                            <br/>
                          </>

                        ) : (<></>)}

                        {patient.sample_received_at ? (
                          <>
                          <span className="badge badge-info patient-badge text-white">
                            Received  @ {(patient.sample_received_at) ? datetime_format(patient.sample_received_at) : ""}
                          </span>
                            <br/>
                          </>

                        ) : (<></>)}

                        {
                          patient.report_status_log
                            .filter((status_log) => status_log.report_status_id == 5)
                            .reverse()
                            .slice(0, 1) // This ensures only the first match is returned
                            .map((status_log) => (
                              <span className="badge badge-reported patient-badge text-white" key={status_log.id}>
                                Reported @ {status_log.created_at_formatted ? datetime_format(status_log.created_at_formatted) : ''}
                              </span>
                            ))
                        }

                        {patient.royal_mail_tracking_no ? (
                          <p className={`badge badge-light patient-badge`}>Tracking# {patient.royal_mail_tracking_no}</p>
                        ) : (<></>)
                        }
                      </td>
                      <td>
                        <span className={`badge badge-dark patient-badge`}>{patient.sample_id}</span>
                        <br/>
                        <span className={'font-bold'}> {patient.first_name}</span>
                        <br/>
                        <span>{patient.last_name}</span>
                        <br/>
                        <a className={`font-bold`} href={`mailto:${patient.email}`}>{patient.email}</a>
                        <br/>
                        <a className={`font-bold text-dark`} href={`tel:${patient.phone_no}`}>{patient.phone_no}</a>
                      </td>
                      <td>
                        <ul>
                          {patient.patient_tests.length > 0 ? (
                            patient.patient_tests.map((patient_test, index) => (
                              <li>{patient_test.test?.name}</li>
                            ))
                          ) : ''
                          }
                        </ul>
                      </td>
                      <td>
                        <span className={`font-bold`}>{patient.gender}</span>
                        <br/>
                        <span>{date_format(patient.dob)}</span>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={13}>No Patient</td>
                  </tr>
                )}
                </tbody>
              </table>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <
        Modal
        show={show}
        onHide={handleClose}>
        < Modal.Header
          closeButton>
          < Modal.Title> Delete
            Patient < /Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to Delete?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={deletePatient}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={statusModalShow} onHide={handleClose}>
        <Form onSubmit={changeStatusHandler}>
          <Modal.Header closeButton>
            <Modal.Title>Change Status</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className={"mt-5"}>
              <Row className={"mb-10 "}>
                <Col xs={12}>
                  <Form.Label>Status</Form.Label>
                  <Form.Control as="select" ref={changedStatus} value={selectedPatientStatus}
                                onChange={selectedPatientStatusHandler}>
                    <option value="0" key={"0"}>Choose...</option>
                    {
                      reportStatuses.map(status => (
                        <option value={status.id} key={"status_" + status.id}>{status.name}</option>
                      ))
                    }

                  </Form.Control>

                  <Form.Label>{t("Action Performed At")}</Form.Label>
                  <Form.Control type={"datetime-local"} ref={status_created_at}
                                placeholder={t("Action Performed At")}/>

                </Col>
                <Col xs={12}>
                  <Form.Label>Notes</Form.Label>
                  <Form.Control as="textarea" ref={notes}></Form.Control>

                </Col>
              </Row>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" disabled={disabled} type="submit">
              Change Status
              <span
                dangerouslySetInnerHTML={{__html: disabled ? `<i class='fas fa-spinner fa-spin'></i>` : ``}}/>
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
)
}