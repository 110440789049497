import React, {useEffect, useState, useRef} from "react";
import axios from "axios";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import alertify from "alertifyjs";
import {API_URL} from "../constants.js";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {
    Card,
    CardBody,
    CardHeader,
    CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

export function MenuRoleEdit(props) {
    const {t} = useTranslation();
    const role_id = useRef();
    const [roles, setRoles] = useState([]);
    const [menuRoles, setMenuRoles] = useState([]);
    const [disabled, setDisabled] = useState(0);
    const [iconDisabled, setIconDisabled] = useState(1);

    const handleRoleOnChange = (e) => {
        axios.get(API_URL + e + '/menu_roles').then((response) => {
            //console.log(response.data.references);
            if (response.data.status) {
                //console.log(response.data.menu_roles);
                setMenuRoles(Object.values(response.data.menu_roles));

            } else {
                alertify.error(response.data.message);
                return null;
            }
        })
    };

    const handleRoleCheckOnChange = (e,f) => {
    console.log(e,f, menuRoles[e][f]['title']);

       const mr = [...menuRoles];
       mr[e][f]['enabled'] = !mr[e][f]['enabled'];
       setMenuRoles(mr);
    }

    useEffect(() => {
        axios.get(API_URL + 'role').then((response) => {
            //console.log(response.data.references);
            if (response.data.status) {
                setRoles(response.data.data);

            } else {
                alertify.error(response.data.message);
                return null;
            }
        })

    }, []);

    useEffect(() => {
        console.log(menuRoles);
    }, [menuRoles]);

    const updateRoleMenuHandler = (e) => {
        e.preventDefault();
        setDisabled(1);
        setIconDisabled(0);
        axios.post(API_URL + "menu_roles/update",
            {
                role_id: role_id.current.value,
                menu_roles: menuRoles
            }
        ).then((response) => {
            setDisabled(0);
            setIconDisabled(1);
            if (response.data.status) {
                alertify.success(response.data.message);

                //props.history.push("/faq/list");
            } else {
                alertify.error(response.data.message);
                return null;
            }
        }).catch((error) => {
            setDisabled(0);
            setIconDisabled(1);
            if (error.message === 'Request failed with status code 401') {
                //props.logout();
            }
        });
    }

    return (
        <>
            <Row>
                <Col md={12}>
                    <Card>
                        <CardHeader title={t("User Role Setup")}>
                            <CardHeaderToolbar>
                                <Link to="/menu/create" className="btn btn-primary btn-sm mx-3">
                                    {t("Create Menu")}
                                </Link>
                            </CardHeaderToolbar>
                        </CardHeader>
                        <CardBody>

                            <div className="mt-5">
                                <Form onSubmit={updateRoleMenuHandler}>
                                    <Form.Row>
                                        <Form.Group as={Col}>
                                            <Form.Label>{t("Roles")}</Form.Label>
                                            <Form.Control as="select" ref={role_id}
                                                          onChange={(e) => handleRoleOnChange(e.target.value)}>
                                                <option key={'0'} value={'0'}>Select a role</option>
                                                {roles.length > 0 ? (
                                                    roles.map((role, index) => (
                                                        <option key={index} value={role.id}>{role.name}</option>
                                                    ))
                                                ) : (
                                                    <option value={"0"}>{t("No Role")}</option>
                                                )}
                                            </Form.Control>
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                        </Form.Group>
                                    </Form.Row>

                                    {
                                        menuRoles.map((menus, index) =>
                                            <Form.Row key={index} style={{margin:'15px 0'}}>
                                                <Col md={'12'}>
                                                    <h5>{menus[0].grand_parent.toUpperCase()}</h5>
                                                </Col>
                                                {
                                                    menus.map((menu, key) =>
                                                        <Col md={'3'} key={key}>
                                                            <Form.Check
                                                                type={"checkbox"}
                                                                label={`${menu.title}`}
                                                                checked={menu.enabled}
                                                                onChange={(e) => handleRoleCheckOnChange(index,key)}
                                                            />
                                                        </Col>
                                                    )
                                                }
                                            </Form.Row>
                                        )
                                    }


                                    <Button variant="primary" className="float-right" type="submit" disabled={disabled}>
                                        {t("Save")}
                                        <span
                                            dangerouslySetInnerHTML={{__html: disabled ? `<i class='fas fa-spinner fa-spin'></i>` : ``}}/>
                                    </Button>
                                </Form>

                            </div>

                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    );
}