import React, {useEffect, useState, useRef} from "react";
import {toAbsoluteUrl} from "../../../_metronic/_helpers";
import axios from "axios";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import alertify from "alertifyjs";
import {API_URL, BACKEND_URL} from "../constants.js";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {
    Card,
    CardBody,
    CardHeader,
    CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import {Link} from "react-router-dom";

export function Patient(props) {
    const from_date = useRef();
    const to_date = useRef();
    const reference_id = useRef();
    const user_id = useRef();
    const collection_center_id = useRef();
    const remarks = useRef();

    const [references, setReferences] = useState([]);
    const [users, setUsers] = useState([]);
    const [collectionCenters, setCollectionCenter] = useState([]);
    const [sortBy, setSortBy] = useState('date');

    const [disabled, setDisabled] = useState(0);
    const [iconDisabled, setIconDisabled] = useState(1);

    const generateReportHandler = (e) => {
        e.preventDefault();
        var queryString = '?from_date=' + from_date.current.value + '&to_date=' + to_date.current.value + '&reference_id=' + reference_id.current.value + '&collection_center_id=' + collection_center_id.current.value+'&user_id='+user_id.current.id +'&sortby='+sortBy+'&remarks='+remarks.current.value;
        window.open(`${BACKEND_URL}patient-summary${queryString}`);
    }

    useEffect(() => {
        axios.get(API_URL + 'reference').then((response) => {
            if (response.data.status) {
                setReferences(response.data.data);
            }
        });
    }, []);

    useEffect(() => {
        axios.get(API_URL + 'user').then((response) => {
            if (response.data.status) {
                setUsers(response.data.data);
            }
        });
    }, []);
    useEffect(() => {
        axios.get(API_URL + 'collection-center').then((response) => {
            if (response.data.status) {
                setCollectionCenter(response.data.data);
            }
        });
    }, []);

    //to test
    useEffect(() => {
        console.log(sortBy);
    }, [sortBy]);

    return (
        <>
            <Row>
                <Col md={12}>
                    <Card>
                        <CardHeader title="Patient Wise Summary">
                            <CardHeaderToolbar>
                            </CardHeaderToolbar>
                        </CardHeader>
                        <CardBody>

                            <div className="mt-5">
                                <Form onSubmit={generateReportHandler}>
                                    <Form.Row>
                                        <Col md={5}>
                                            <Form.Label>From Date</Form.Label>
                                            <Form.Control type={'date'} ref={from_date} placeholder="Enter From Date"/>
                                        </Col>
                                        <Col md={5}>
                                            <Form.Label>To Date</Form.Label>
                                            <Form.Control type={'date'} ref={to_date} placeholder="Enter To Date"/>
                                        </Col>
                                    </Form.Row>

                                    <Form.Row>
                                        <Col md={5}>
                                            <Form.Label>Reference</Form.Label>
                                            <Form.Control as="select" ref={reference_id}>
                                                <option value="">Select Reference....</option>
                                                {
                                                    references.map(reference => (
                                                        <option key={reference.id}
                                                                value={reference.id}>{reference.name}</option>
                                                    ))
                                                }
                                            </Form.Control>
                                        </Col>
                                        <Col md={5}>
                                            <Form.Label>Employee</Form.Label>
                                            <Form.Control as="select" ref={user_id}>
                                                <option value="">Select Employee....</option>
                                                {
                                                    users.map(user => (
                                                        <option key={user.id} value={user.id}>{user.name}</option>
                                                    ))
                                                }
                                            </Form.Control>
                                        </Col>
                                    </Form.Row>

                                    <Form.Row>
                                        <Col md={5}>
                                            <Form.Label>Collection Center</Form.Label>
                                            <Form.Control as="select" ref={collection_center_id}>
                                                <option value="">Select Collection Center....</option>
                                                {
                                                    collectionCenters.map(collectioncenter => (
                                                        <option key={collectioncenter.id}
                                                                value={collectioncenter.id}>{collectioncenter.name}</option>
                                                    ))
                                                }
                                            </Form.Control>
                                        </Col>
                                    </Form.Row>
                                    <Form.Row>
                                        <Col md={5}>
                                            <Form.Label>Remarks</Form.Label>
                                            <Form.Control as={'textarea'} ref={remarks} placeholder="Enter remarks to be printed on report (Optional)"/>
                                        </Col>
                                    </Form.Row>
                                    <hr/>
                                    <Form.Row>
                                        <Col md={5}>
                                            <Form.Label>Sort Report By</Form.Label>
                                            <Form.Check
                                                type={'radio'}
                                                name={'sortby'}
                                                label={`Date Wise Summary`}
                                                defaultChecked={true}
                                                onClick={(e)=>{setSortBy('date')}}
                                            />
                                            <Form.Check
                                                type={'radio'}
                                                name={'sortby'}
                                                label={`Reference Wise Summary`}
                                                onClick={(e)=>{setSortBy('reference')}}
                                            />
                                            <Form.Check
                                                type={'radio'}
                                                name={'sortby'}
                                                label={`Collection Center Wise Summary`}
                                                onClick={(e)=>{setSortBy('collection_center')}}
                                            />
                                            <Form.Check
                                                type={'radio'}
                                                name={'sortby'}
                                                label={`Employee Wise Summary`}
                                                onClick={(e)=>{setSortBy('employee')}}
                                            />
                                        </Col>
                                    </Form.Row>
                                    <Button variant="primary" className="float-right" type="submit" disabled={disabled}>
                                        Populate Report
                                        <span
                                            dangerouslySetInnerHTML={{__html: disabled ? `<i class='fas fa-spinner fa-spin'></i>` : ``}}/>
                                    </Button>
                                </Form>

                            </div>

                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    );
}