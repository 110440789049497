/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useMemo, useState} from "react";
import objectPath from "object-path";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import SVG from "react-inlinesvg";
import {useHtmlClassService} from "../../_core/MetronicLayout";
import {toAbsoluteUrl} from "../../../_helpers";
import {AsideSearch} from "./AsideSearch";
import {AsideMenuDashboard} from "./aside-menu/AsideMenuDashboard";
import {AsideMenuSetup} from "./aside-menu/AsideMenuSetup";
import {AsideMenuInventory} from "./aside-menu/AsideMenuInventory";
import {AsideMenuPatient} from "./aside-menu/AsideMenuPatient";
import {AsideMenuUser} from "./aside-menu/AsideMenuUser";
import {AsideMenuAccounts} from "./aside-menu/AsideMenuAccounts";
import {AsideMenuMiscellaneous} from "./aside-menu/AsideMenuMiscellaneous";
import {AsideMenuReport} from "./aside-menu/AsideMenuReport";
import {LanguageSelectorDropdown} from "../extras/dropdowns/LanguageSelectorDropdown";
import {QuickUserToggler} from "../extras/QuickUserToggler";
import {Brand} from "../brand/Brand";
import {KTUtil} from "./../../../_assets/js/components/util";
import {Link} from "react-router-dom";

import {makeStyles} from '@material-ui/core/styles';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import DirectionsBusIcon from '@material-ui/icons/DirectionsBus';
import PeopleIcon from '@material-ui/icons/People';
import PaymentIcon from '@material-ui/icons/Payment';
import AcUnitIcon from '@material-ui/icons/AcUnit';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import SettingsIcon from '@material-ui/icons/Settings';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart'; //Purchase
import AccessibleIcon from '@material-ui/icons/Accessible';// Patient
import PagesIcon from '@material-ui/icons/Pages';
import CreditCardIcon from '@material-ui/icons/CreditCard'; //Transaction
import PieChartIcon from '@material-ui/icons/PieChart'; //Reports


const useStyles = makeStyles(theme => ({
    root: {
        color: theme.palette.text.primary,
    },
    icon: {
        margin: theme.spacing(1),
        fontSize: 32,
    },
}));




export function Aside() {
    const classes = useStyles();
    const uiService = useHtmlClassService();

    const layoutProps = useMemo(() => {
        return {
            asideClassesFromConfig: uiService.getClasses("aside", true),
            asideSecondaryDisplay: objectPath.get(
                uiService.config,
                "aside.secondary.display"
            ),
            asideSelfMinimizeToggle: objectPath.get(
                uiService.config,
                "aside.self.minimize.toggle"
            ),
            extrasSearchDisplay: objectPath.get(
                uiService.config,
                "extras.search.display"
            ),
            extrasNotificationsDisplay: objectPath.get(
                uiService.config,
                "extras.notifications.display"
            ),
            extrasQuickActionsDisplay: objectPath.get(
                uiService.config,
                "extras.quick-actions.display"
            ),
            extrasQuickPanelDisplay: objectPath.get(
                uiService.config,
                "extras.quick-panel.display"
            ),
            extrasLanguagesDisplay: objectPath.get(
                uiService.config,
                "extras.languages.display"
            ),
            extrasUserDisplay: objectPath.get(
                uiService.config,
                "extras.user.display"
            ),
        };
    }, [uiService]);

    const tabs = {
        tabId0: "kt_aside_tab_0",
        tabId1: "kt_aside_tab_1",
        tabId2: "kt_aside_tab_2",
        tabId3: "kt_aside_tab_3",
        tabId4: "kt_aside_tab_4",
        tabId5: "kt_aside_tab_5",
        tabId6: "kt_aside_tab_6",
        tabId7: "kt_aside_tab_7",
        tabId888: "kt_aside_tab_888",
    };
    const [activeTab, setActiveTab] = useState(tabs.tabId0);
    const handleTabChange = (id) => {
        setActiveTab(id);
        const asideWorkspace = KTUtil.find(
            document.getElementById("kt_aside"),
            ".aside-secondary .aside-workspace"
        );
        if (asideWorkspace) {
            KTUtil.scrollUpdate(asideWorkspace);
        }
    };

    return (
        <>
            {/* begin::Aside */}
            <div
                id="kt_aside"
                className={`aside aside-left d-flex ${layoutProps.asideClassesFromConfig}`}
            >
                {/* begin::Primary */}
                <div className="aside-primary d-flex flex-column align-items-center flex-row-auto">
                    <Brand/>
                    {/* begin::Nav Wrapper */}
                    <div
                        className="aside-nav d-flex flex-column align-items-center flex-column-fluid py-5 scroll scroll-pull">
                        {/* begin::Nav */}
                        <ul className="list-unstyled flex-column" role="tablist">


                            {/* begin::Item */}
                            <li
                                className="nav-item mb-3"
                                data-toggle="tooltip"
                                data-placement="rigth"
                                data-container="body"
                                data-boundary="window"
                                title="Latest Reports"
                            >
                                <OverlayTrigger
                                    placement="right"
                                    overlay={
                                        <Tooltip id="latest-reports">Dashboard</Tooltip>
                                    }
                                >
                                    <a
                                        href="#"
                                        className={`nav-link btn btn-icon btn-clean btn-lg ${activeTab ===
                                        tabs.tabId0 && "active"}`}
                                        data-toggle="tab"
                                        data-target={`#${tabs.tabId0}`}
                                        onClick={() => handleTabChange(tabs.tabId0)}
                                        role="tab"
                                    >
                                    <span className="svg-icon svg-icon-lg">
                                      <SVG
                                          src={toAbsoluteUrl(
                                              "/media/svg/icons/Media/Equalizer.svg"
                                          )}
                                      />
                                    </span>
                                    </a>
                                </OverlayTrigger>
                            </li>
                            {/* end::Item */}

                            {/* begin::Item */}
                            <li
                                className="nav-item mb-3"
                                data-toggle="tooltip"
                                data-placement="rigth"
                                data-container="body"
                                data-boundary="window"
                                title="Setup"
                            >
                                <OverlayTrigger
                                    placement="right"
                                    overlay={
                                        <Tooltip id="latest-reports">Setup</Tooltip>
                                    }
                                >
                                    <a
                                        href="#"
                                        className={`nav-link btn btn-icon btn-clean btn-lg ${activeTab ===
                                        tabs.tabId1 && "active"}`}
                                        data-toggle="tab"
                                        data-target={`#${tabs.tabId1}`}
                                        onClick={() => handleTabChange(tabs.tabId1)}
                                        role="tab"
                                    >
                                    <span className="svg-icon svg-icon-lg">
                                      <SettingsIcon className={classes.icon}/>
                                    </span>
                                    </a>
                                </OverlayTrigger>
                            </li>
                            {/* end::Item */}

                            {/* begin::Item */}
                            <li
                                className="nav-item mb-3"
                                data-toggle="tooltip"
                                data-placement="rigth"
                                data-container="body"
                                data-boundary="window"
                                title="Patient Management"
                            >
                                <OverlayTrigger
                                    placement="right"
                                    overlay={
                                        <Tooltip id="latest-reports">Patient Management</Tooltip>
                                    }
                                >
                                    <a
                                        href="#"
                                        className={`nav-link btn btn-icon btn-clean btn-lg ${activeTab ===
                                        tabs.tabId3 && "active"}`}
                                        data-toggle="tab"
                                        data-target={`#${tabs.tabId3}`}
                                        onClick={() => handleTabChange(tabs.tabId3)}
                                        role="tab"
                                    >
                                    <span className="svg-icon svg-icon-lg">
                                      <PeopleIcon className={classes.icon}/>
                                    </span>
                                    </a>
                                </OverlayTrigger>
                            </li>
                            {/* end::Item */}

                            {/* begin::Item */}
                            <li
                                className="nav-item mb-3"
                                data-toggle="tooltip"
                                data-placement="rigth"
                                data-container="body"
                                data-boundary="window"
                                title="Accounts Management"
                            >
                                <OverlayTrigger
                                    placement="right"
                                    overlay={
                                        <Tooltip id="latest-reports">Accounts Management</Tooltip>
                                    }
                                >
                                    <a
                                        href="#"
                                        className={`nav-link btn btn-icon btn-clean btn-lg ${activeTab ===
                                        tabs.tabId6 && "active"}`}
                                        data-toggle="tab"
                                        data-target={`#${tabs.tabId6}`}
                                        onClick={() => handleTabChange(tabs.tabId6)}
                                        role="tab"
                                    >
                                    <span className="svg-icon svg-icon-lg">
                                      <CreditCardIcon className={classes.icon}/>
                                    </span>
                                    </a>
                                </OverlayTrigger>
                            </li>
                            {/* end::Item */}

                            {/* begin::Item */}
                            <li
                                className="nav-item mb-3"
                                data-toggle="tooltip"
                                data-placement="rigth"
                                data-container="body"
                                data-boundary="window"
                                title="Inventory Management"
                            >
                                <OverlayTrigger
                                    placement="right"
                                    overlay={
                                        <Tooltip id="latest-reports">Inventory Management</Tooltip>
                                    }
                                >
                                    <a
                                        href="#"
                                        className={`nav-link btn btn-icon btn-clean btn-lg ${activeTab ===
                                        tabs.tabId2 && "active"}`}
                                        data-toggle="tab"
                                        data-target={`#${tabs.tabId2}`}
                                        onClick={() => handleTabChange(tabs.tabId2)}
                                        role="tab"
                                    >
                                    <span className="svg-icon svg-icon-lg">
                                      <AddShoppingCartIcon className={classes.icon}/>
                                    </span>
                                    </a>
                                </OverlayTrigger>
                            </li>
                            {/* end::Item */}

                            {/* begin::Item */}
                            <li
                                className="nav-item mb-3"
                                data-toggle="tooltip"
                                data-placement="rigth"
                                data-container="body"
                                data-boundary="window"
                                title="User Management"
                            >
                                <OverlayTrigger
                                    placement="right"
                                    overlay={
                                        <Tooltip id="latest-reports">User Management</Tooltip>
                                    }
                                >
                                    <a
                                        href="#"
                                        className={`nav-link btn btn-icon btn-clean btn-lg ${activeTab ===
                                        tabs.tabId4 && "active"}`}
                                        data-toggle="tab"
                                        data-target={`#${tabs.tabId4}`}
                                        onClick={() => handleTabChange(tabs.tabId4)}
                                        role="tab"
                                    >
                    <span className="svg-icon svg-icon-lg">
                      <PeopleIcon className={classes.icon}/>
                    </span>
                                    </a>
                                </OverlayTrigger>
                            </li>
                            {/* end::Item */}

                            {/* begin::Item */}
                            <li
                                className="nav-item mb-3"
                                data-toggle="tooltip"
                                data-placement="rigth"
                                data-container="body"
                                data-boundary="window"
                                title="MIscellaneous"
                            >
                                <OverlayTrigger
                                    placement="right"
                                    overlay={
                                        <Tooltip id="latest-reports">Miscellaneous</Tooltip>
                                    }
                                >
                                    <a
                                        href="#"
                                        className={`nav-link btn btn-icon btn-clean btn-lg ${activeTab ===
                                        tabs.tabId5 && "active"}`}
                                        data-toggle="tab"
                                        data-target={`#${tabs.tabId5}`}
                                        onClick={() => handleTabChange(tabs.tabId5)}
                                        role="tab"
                                    >
                    <span className="svg-icon svg-icon-lg">
                      <AcUnitIcon className={classes.icon}/>
                    </span>
                                    </a>
                                </OverlayTrigger>
                            </li>
                            {/* end::Item */}

                            {/* begin::Item */}
                            <li
                                className="nav-item mb-3"
                                data-toggle="tooltip"
                                data-placement="rigth"
                                data-container="body"
                                data-boundary="window"
                                title="Reports"
                            >
                                <OverlayTrigger
                                    placement="right"
                                    overlay={
                                        <Tooltip id="latest-reports">Reports</Tooltip>
                                    }
                                >
                                    <a
                                        href="#"
                                        className={`nav-link btn btn-icon btn-clean btn-lg ${activeTab ===
                                        tabs.tabId7 && "active"}`}
                                        data-toggle="tab"
                                        data-target={`#${tabs.tabId7}`}
                                        onClick={() => handleTabChange(tabs.tabId7)}
                                        role="tab"
                                    >
                                    <span className="svg-icon svg-icon-lg">
                                      <PieChartIcon className={classes.icon}/>
                                    </span>
                                    </a>
                                </OverlayTrigger>
                            </li>
                            {/* end::Item */}

                        </ul>
                        {/* end::Nav */}
                    </div>
                    {/* end::Nav Wrapper */}

                    {/* begin::Footer */}
                    <div className="aside-footer d-flex flex-column align-items-center flex-column-auto py-4 py-lg-10">
                        {/* begin::Aside Toggle */}
                        {layoutProps.asideSecondaryDisplay &&
                        layoutProps.asideSelfMinimizeToggle && (
                            <>
                                <OverlayTrigger
                                    placement="right"
                                    overlay={<Tooltip id="toggle-aside">Toggle Aside</Tooltip>}
                                >
                    <span
                        className="aside-toggle btn btn-icon btn-primary btn-hover-primary aside-toggle-active shadow-sm"
                        id="kt_aside_toggle"
                    >
                      <i className="ki ki-bold-arrow-back icon-sm"/>
                    </span>
                                </OverlayTrigger>
                            </>
                        )}
                        {/* end::Aside Toggle */}

                        {/* begin::Search }
            {layoutProps.extrasSearchDisplay && (
              <OverlayTrigger
                placement="right"
                overlay={<Tooltip id="toggle-search">Quick Search</Tooltip>}
              >
                <a
                  href="#"
                  className="btn btn-icon btn-clean btn-lg mb-1"
                  id="kt_quick_search_toggle"
                >
                  <span className="svg-icon svg-icon-lg">
                    <SVG
                      src={toAbsoluteUrl("/media/svg/icons/General/Search.svg")}
                    />
                  </span>
                </a>
              </OverlayTrigger>
            )}
            {/* end::Search */}

                        {/* begin::Notifications */}
                        {layoutProps.extrasNotificationsDisplay && (
                            <OverlayTrigger
                                placement="right"
                                overlay={
                                    <Tooltip id="toggle-notifications">Notifications</Tooltip>
                                }
                            >
                                <a
                                    href="#"
                                    className="btn btn-icon btn-clean btn-lg mb-1 position-relative"
                                    id="kt_quick_notifications_toggle"
                                    data-placement="right"
                                    data-container="body"
                                    data-boundary="window"
                                >
                  <span className="svg-icon svg-icon-lg">
                    <SVG
                        src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")}
                    />
                  </span>
                                </a>
                            </OverlayTrigger>
                        )}
                        {/* end::Notifications */}

                        {/* begin::Quick Actions }
            {layoutProps.extrasQuickActionsDisplay && (
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip id="toggle-quick-actions">Quick Actions</Tooltip>
                }
              >
                <a
                  href="#"
                  className="btn btn-icon btn-clean btn-lg mb-1"
                  id="kt_quick_actions_toggle"
                >
                  <span className="svg-icon svg-icon-lg">
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/Media/Equalizer.svg"
                      )}
                    />
                  </span>
                </a>
              </OverlayTrigger>
            )}
            {/* end::Quick Actions */}

                        {/* begin::Quick Panel }
            {layoutProps.extrasQuickPanelDisplay && (
              <OverlayTrigger
                placement="right"
                overlay={<Tooltip id="toggle-quick-panel">Quick Panel</Tooltip>}
              >
                <a
                  href="#"
                  className="btn btn-icon btn-clean btn-lg mb-1 position-relative"
                  id="kt_quick_panel_toggle"
                  data-placement="right"
                  data-container="body"
                  data-boundary="window"
                >
                  <span className="svg-icon svg-icon-lg">
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/Layout/Layout-4-blocks.svg"
                      )}
                    />
                  </span>
                  <span className="label label-sm label-light-danger label-rounded font-weight-bolder position-absolute top-0 right-0 mt-1 mr-1">
                    3
                  </span>
                </a>
              </OverlayTrigger>
            )}
            {/* end::Quick Panel */}

                        {/* begin::Languages*/}
                        {layoutProps.extrasLanguagesDisplay && <LanguageSelectorDropdown/>}
                        {/* end::Languages */}

                        {/* begin::User*/}
                        {layoutProps.extrasUserDisplay && <QuickUserToggler/>}
                        {/* end::User */}
                    </div>
                    {/* end::Footer */}
                </div>
                {/* end::Primary */}

                {layoutProps.asideSecondaryDisplay && (
                    <>
                        {/* begin::Secondary */}
                        <div className="aside-secondary d-flex flex-row-fluid">
                            {/* begin::Workspace */}
                            <div className="aside-workspace scroll scroll-push my-2">
                                <div className="tab-content">
                                    <AsideMenuDashboard isActive={activeTab === tabs.tabId0}/>
                                    <AsideMenuSetup isActive={activeTab === tabs.tabId1}/>
                                    <AsideMenuInventory isActive={activeTab === tabs.tabId2}/>
                                    <AsideMenuPatient isActive={activeTab === tabs.tabId3}/>
                                    <AsideMenuAccounts isActive={activeTab === tabs.tabId6}/>
                                    <AsideMenuUser isActive={activeTab === tabs.tabId4}/>
                                    <AsideMenuMiscellaneous isActive={activeTab === tabs.tabId5}/>
                                    <AsideMenuReport isActive={activeTab === tabs.tabId7}/>

                                </div>
                            </div>
                            {/* end::Workspace */}
                        </div>
                        {/* end::Secondary */}
                    </>
                )}
            </div>
            {/* end::Aside */}
        </>
    );
}
