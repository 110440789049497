import React, {useEffect, useState, useRef} from "react";
import axios from "axios";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import alertify from "alertifyjs";
import {API_URL} from "../constants.js";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

import {
    Card,
    CardBody,
    CardHeader,
    CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

export function EditReportRemarks(props) {
    const {t} = useTranslation();
    const name = useRef();
    const notes = useRef();
    const [disabled, setDisabled] = useState(0);
    const [iconDisabled, setIconDisabled] = useState(1);
    const [reportRemarks, setReportRemarks] = useState({});
    const [notesData, setNotesData] = useState('');
    const id = props.match.params.id;

    useEffect(() => {
        axios.get(API_URL + 'report-remarks/' + id).then((response) => {
            if (response.data.status) {
                setReportRemarks(response.data.data);

            } else {
                alertify.error(response.data.message);
                return null;
            }
        })
    }, []);

    const updateReportRemarksHandler = (e) => {
        e.preventDefault();
        setDisabled(1);
        setIconDisabled(0);
        axios.post(API_URL + "report-remarks/update",
            {
                id: reportRemarks.id,
                name: name.current.value,
                notes: notesData,
            }
        ).then((response) => {
            setDisabled(0);
            setIconDisabled(1);
            if (response.data.status) {
                alertify.success(response.data.message);

                props.history.push("/report-remarks/list");
            } else {
                alertify.error(response.data.message);
                return null;
            }
        }).catch((error) => {
            setDisabled(0);
            setIconDisabled(1);
            if (error.message === 'Request failed with status code 401') {
                //props.logout();
            }
        });
    }
    return (
        <Row>
            <Col md={12}>
                <Card>
                    <CardHeader title={t("Edit Report Remarks")}>
                        <CardHeaderToolbar>
                            <Link to="/report-remarks/list" className="btn btn-primary btn-sm mx-3">
                                {t("Report Remarks List")}
                            </Link>
                        </CardHeaderToolbar>
                    </CardHeader>
                    <CardBody>

                        <div className="mt-5">
                            <Form onSubmit={updateReportRemarksHandler}>
                                <Form.Row>
                                    <Form.Group className={'col-12 col-md-6'}>
                                        <Form.Label>{t("Name")}</Form.Label>
                                        <Form.Control ref={name} placeholder={t("Enter Report Remarks")}
                                                      defaultValue={reportRemarks.name}/>
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group className={'col-12 col-md-6'}>
                                        <Form.Label>{t("Notes")}</Form.Label>
                                        {/*<Form.Control as="textarea" ref={notes} placeholder={t("Enter Notes")}*/}
                                        {/*              rows={5}*/}
                                        {/*              defaultValue={reportRemarks.notes}/>*/}

                                        <CKEditor
                                            editor={ClassicEditor}
                                            data={reportRemarks.notes}
                                            onChange={(event, editor) => {
                                                setNotesData(editor.getData());
                                            }}
                                            onReady={editor => {
                                                setNotesData(editor.getData());
                                            }}
                                        />
                                    </Form.Group>
                                </Form.Row>
                                <Button variant="primary" className="float-right" type="submit" disabled={disabled}>
                                    {t("Save")}
                                    <span
                                        dangerouslySetInnerHTML={{__html: disabled ? `<i class='fas fa-spinner fa-spin'></i>` : ``}}/>
                                </Button>
                            </Form>

                        </div>

                    </CardBody>
                </Card>
            </Col>
        </Row>
    );
}