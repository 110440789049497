import React, {useEffect, useState, useRef} from "react";
import axios from "axios";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import alertify from "alertifyjs";
import {API_URL} from "../constants.js";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {
    Card,
    CardBody,
    CardHeader,
    CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

export function CreateCollectionCenter(props) {
    const { t } = useTranslation();
    const name = useRef();
    const phone_no1 = useRef();
    const phone_no2 = useRef();
    const address = useRef();
    const latitude = useRef();
    const longitude = useRef();
    const lab_id = useRef();
    const [disabled, setDisabled] = useState(0);
    const [iconDisabled, setIconDisabled] = useState(1);
    const [labs, setLabs] = useState([]);

    useEffect(() => {
        axios.get(API_URL + 'lab').then((response) => {

            if (response.data.status) {
                setLabs(response.data.data);
            } else if (response.data.code === 404) {
                alertify.error(response.data.message);
                return null;
            }
        });
    }, []);


    const createCollectionCenterHandler = (e) => {
        e.preventDefault();
        setDisabled(1);
        setIconDisabled(0);
        axios.post(API_URL + "collection-center/create",
            {
                name: name.current.value,
                phone_no1: phone_no1.current.value,
                phone_no2: phone_no2.current.value,
                address: address.current.value,
                latitude: latitude.current.value,
                longitude: longitude.current.value,
                lab_id: lab_id.current.value
            }
        ).then((response) => {
            setDisabled(0);
            setIconDisabled(1);
            if (response.data.status) {
                alertify.success(response.data.message);

                props.history.push("/collection-center/list");
            } else {
                alertify.error(response.data.message);
                return null;
            }
        }).catch((error) => {
            setDisabled(0);
            setIconDisabled(1);
            if (error.message === 'Request failed with status code 401') {
                //props.logout();
            }
        });
    }
    return (
        <>
            <Row>
                <Col md={12}>
                    <Card>
                        <CardHeader title={t("Collection Center List")}>
                            <CardHeaderToolbar>
                                <Link to="/collection-center/list" className="btn btn-primary btn-sm mx-3">
                                    {t("Collection Center List")}
                                </Link>
                            </CardHeaderToolbar>
                        </CardHeader>
                        <CardBody>

                            <div className="mt-5">
                                <Form onSubmit={createCollectionCenterHandler}>
                                    <Form.Row>
                                        <Form.Group as={Col}>
                                            <Form.Label>{t("Name")}</Form.Label>
                                            <Form.Control ref={name} placeholder={t("Enter Collection Center Name")}/>
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Form.Label>{t("Phone NO 1")}</Form.Label>
                                            <Form.Control ref={phone_no1} rows={1} placeholder={t("Enter Phone No 1")}/>
                                        </Form.Group>

                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col}>
                                            <Form.Label>{t("Phone No 2")}</Form.Label>
                                            <Form.Control ref={phone_no2} placeholder={t("Enter Phone No 2")}/>
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Form.Label>{t("Address")}</Form.Label>
                                            <Form.Control ref={address} rows={1} placeholder={t("Enter Address")}/>
                                        </Form.Group>

                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col}>
                                            <Form.Label>{t("Latitude")}</Form.Label>
                                            <Form.Control ref={latitude} placeholder="19.2.3.4"/>
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Form.Label>{t("Longitude")}</Form.Label>
                                            <Form.Control ref={longitude} rows={1} placeholder="20.3.4.02"/>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Control as="select" ref={lab_id}>
                                            <option value="">{t("Select Lab")}</option>
                                            {labs.map((lab, key) => (
                                                <option key={lab.id} value={lab.id}>
                                                    {lab.name}
                                                </option>
                                            ))}
                                        </Form.Control>
                                    </Form.Row>
                                    <Button variant="primary" className="float-right" type="submit" disabled={disabled}>
                                        {t("Save")}
                                        <span
                                            dangerouslySetInnerHTML={{__html: disabled ? `<i class='fas fa-spinner fa-spin'></i>` : ``}}/>
                                    </Button>
                                </Form>

                            </div>

                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    );
}