/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import {useLocation} from "react-router";
import {NavLink} from "react-router-dom";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl, checkIsActive} from "../../../../_helpers";
import {makeStyles} from '@material-ui/core/styles';
import AirportShuttleIcon from '@material-ui/icons/AirportShuttle';
import ExtensionIcon from '@material-ui/icons/Extension';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import PeopleIcon from '@material-ui/icons/People';
import HelpIcon from '@material-ui/icons/Help';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import LoyaltyIcon from '@material-ui/icons/Loyalty';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance'; //LAB
import CommentIcon from '@material-ui/icons/Comment';//Report Remarks
import ApartmentIcon from '@material-ui/icons/Apartment'; //Collection Center
import BorderColorIcon from '@material-ui/icons/BorderColor'; //Sample Type
import BorderInnerIcon from '@material-ui/icons/BorderInner'; //Test Category
import BeenhereIcon from '@material-ui/icons/Beenhere'; //Test
import PeopleAltIcon from '@material-ui/icons/PeopleAlt'; //Reference
import GroupAddIcon from '@material-ui/icons/GroupAdd'; //Supplier
import LabelIcon from '@material-ui/icons/Label'; //Item Unit
import LabelImportantIcon from '@material-ui/icons/LabelImportant'; //Item Type
import {check_role} from '../../../../../app/pages/Common/RoleMenuCheck';


const useStyles = makeStyles(theme => ({
    root: {
        color: theme.palette.text.primary,
    },
    icon: {
        margin: theme.spacing(1),
        fontSize: 32,
    },
}));

export function AsideMenuSetupList({layoutProps}) {
    const classes = useStyles();
    const location = useLocation();
    const getMenuItemActive = (url, hasSubmenu = false) => {
        return checkIsActive(location, url)
            ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
            : "";
    };

    return (
        <>
            {/* begin::Menu Nav */}
            <ul className={`menu-nav ${layoutProps.ulClasses}`}>

                {/* Routes Management */}
                {/* begin::section */}
                <li className="menu-section ">
                    <h4 className="menu-text">Test Setup</h4>
                    <i className="menu-icon flaticon-more-v2"></i>
                </li>
                {/* end:: section */}
                {/* City Pages */}

                {/*begin::1 Level*/}
                {check_role(['unit/create', 'unit/list']) ? (
                    <li
                        className={`menu-item menu-item-submenu ${getMenuItemActive(
                            "/", true
                        )}`}
                        aria-haspopup="true"
                        data-menu-toggle="hover"
                    >
                        <NavLink className="menu-link menu-toggle" to="/">
                     <span className="svg-icon svg-icon-lg">
                          <LoyaltyIcon className={classes.icon}/>
                     </span>
                            <span className="menu-text">Unit</span>
                            <i className="menu-arrow"/>
                        </NavLink>
                        <div className="menu-submenu ">
                            <i className="menu-arrow"/>
                            <ul className="menu-subnav">
                                <li className="menu-item  menu-item-parent" aria-haspopup="true">
                                <span className="menu-link">
                                  <span className="menu-text">Unit</span>
                                </span>
                                </li>

                                {/*begin::2 Level*/}
                                {check_role(['unit/create']) ? (
                                    <li
                                        className={`menu-item ${getMenuItemActive("/unit/create")}`}
                                        aria-haspopup="true"
                                    >
                                        <NavLink className="menu-link" to="/unit/create">
                                            <i className="menu-bullet menu-bullet-dot">
                                                <span/>
                                            </i>
                                            <span className="menu-text">Add New</span>
                                        </NavLink>
                                    </li>
                                ) : ''}
                                {/*end::2 Level*/}

                                {/*begin::2 Level*/}
                                {check_role(['unit/list']) ? (
                                    <li
                                        className={`menu-item ${getMenuItemActive("/unit/list")}`}
                                        aria-haspopup="true"
                                    >
                                        <NavLink className="menu-link" to="/unit/list">
                                            <i className="menu-bullet menu-bullet-dot">
                                                <span/>
                                            </i>
                                            <span className="menu-text">List</span>
                                        </NavLink>
                                    </li>
                                ) : ''}
                                {/*end::2 Level*/}

                            </ul>
                        </div>
                    </li>
                ) : ''}
                {/*end::1 Level*/}

                {/*begin::1 Level*/}
                {check_role(['sample-type/create', 'sample-type/list']) ? (
                    <li
                        className={`menu-item menu-item-submenu ${getMenuItemActive(
                            "/sample-type", true
                        )}`}
                        aria-haspopup="true"
                        data-menu-toggle="hover"
                    >
                        <NavLink className="menu-link menu-toggle" to="/sample-type">
                    <span className="svg-icon svg-icon-lg">
                          <BorderColorIcon className={classes.icon}/>
                     </span>
                            <span className="menu-text">Sample Type</span>
                            <i className="menu-arrow"/>
                        </NavLink>
                        <div className="menu-submenu ">
                            <i className="menu-arrow"/>
                            <ul className="menu-subnav">
                                <li className="menu-item  menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">Sample Type</span>
                </span>
                                </li>

                                {/*begin::2 Level*/}
                                {check_role(['sample-type/create']) ? (
                                    <li
                                        className={`menu-item ${getMenuItemActive("/sample-type/create")}`}
                                        aria-haspopup="true"
                                    >
                                        <NavLink className="menu-link" to="/sample-type/create">
                                            <i className="menu-bullet menu-bullet-dot">
                                                <span/>
                                            </i>
                                            <span className="menu-text">Add New</span>
                                        </NavLink>
                                    </li>
                                ) : ''}
                                {/*end::2 Level*/}

                                {/*begin::2 Level*/}
                                {check_role(['sample-type/list']) ? (
                                    <li
                                        className={`menu-item ${getMenuItemActive("/sample-type/list")}`}
                                        aria-haspopup="true"
                                    >
                                        <NavLink className="menu-link" to="/sample-type/list">
                                            <i className="menu-bullet menu-bullet-dot">
                                                <span/>
                                            </i>
                                            <span className="menu-text">List</span>
                                        </NavLink>
                                    </li>
                                ) : ''}
                                {/*end::2 Level*/}

                            </ul>
                        </div>
                    </li>
                ) : ''}
                {/*end::1 Level*/}

                {/* Route Pages */}
                {/*begin::1 Level*/}
                {check_role(['test-category/create','test-category/list']) ? (
                <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                        "/test-category", true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                >
                    <NavLink className="menu-link menu-toggle" to="/test-category">
                    <span className="svg-icon svg-icon-lg">
                    <BorderInnerIcon className={classes.icon}/>
                    </span>
                        <span className="menu-text">Test Category</span>
                        <i className="menu-arrow"/>
                    </NavLink>
                    <div className="menu-submenu ">
                        <i className="menu-arrow"/>
                        <ul className="menu-subnav">
                            <li className="menu-item  menu-item-parent" aria-haspopup="true">
                    <span className="menu-link">
                    <span className="menu-text">Test Category</span>
                    </span>
                            </li>

                            {/*begin::2 Level*/}
                            {check_role(['test-category/create']) ? (
                            <li
                                className={`menu-item ${getMenuItemActive("/test-category/create")}`}
                                aria-haspopup="true"
                            >
                                <NavLink className="menu-link" to="/test-category/create">
                                    <i className="menu-bullet menu-bullet-dot">
                                        <span/>
                                    </i>
                                    <span className="menu-text">Add New</span>
                                </NavLink>
                            </li>
                            ) : ''}
                            {/*end::2 Level*/}

                            {/*begin::2 Level*/}
                            {check_role(['test-category/list']) ? (
                            <li
                                className={`menu-item ${getMenuItemActive("/test-category/list")}`}
                                aria-haspopup="true"
                            >
                                <NavLink className="menu-link" to="/test-category/list">
                                    <i className="menu-bullet menu-bullet-dot">
                                        <span/>
                                    </i>
                                    <span className="menu-text">List</span>
                                </NavLink>
                            </li>
                            ) : ''}
                            {/*end::2 Level*/}

                        </ul>
                    </div>
                </li>
                ) : ''}
                {/*end::1 Level*/}


                {/*begin::1 Level*/}
                {check_role(['report-remarks/create','report-remarks/list']) ? (
                <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                        "/report-remarks", true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                >
                    <NavLink className="menu-link menu-toggle" to="/report-remarks">
                    <span className="svg-icon svg-icon-lg">
                    <CommentIcon className={classes.icon}/>
                    </span>
                        <span className="menu-text">Report Remarks</span>
                        <i className="menu-arrow"/>
                    </NavLink>
                    <div className="menu-submenu ">
                        <i className="menu-arrow"/>
                        <ul className="menu-subnav">
                            <li className="menu-item  menu-item-parent" aria-haspopup="true">
                    <span className="menu-link">
                    <span className="menu-text">Report Remarks</span>
                    </span>
                            </li>

                            {/*begin::2 Level*/}
                            {check_role(['report-remarks/create']) ? (
                            <li
                                className={`menu-item ${getMenuItemActive("/report-remarks/create")}`}
                                aria-haspopup="true"
                            >
                                <NavLink className="menu-link" to="/report-remarks/create">
                                    <i className="menu-bullet menu-bullet-dot">
                                        <span/>
                                    </i>
                                    <span className="menu-text">Add New</span>
                                </NavLink>
                            </li>
                            ) : ''}
                            {/*end::2 Level*/}

                            {/*begin::2 Level*/}
                            {check_role(['report-remarks/list']) ? (
                            <li
                                className={`menu-item ${getMenuItemActive("/report-remarks/list")}`}
                                aria-haspopup="true"
                            >
                                <NavLink className="menu-link" to="/report-remarks/list">
                                    <i className="menu-bullet menu-bullet-dot">
                                        <span/>
                                    </i>
                                    <span className="menu-text">List</span>
                                </NavLink>
                            </li>
                            ) : ''}
                            {/*end::2 Level*/}

                        </ul>
                    </div>
                </li>
                ) : ''}
                {/*end::1 Level*/}

                {/*begin::1 Level*/}
                {check_role(['test/create','test/list']) ? (
                <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                        "/test", true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                >
                    <NavLink className="menu-link menu-toggle" to="/test">
                    <span className="svg-icon svg-icon-lg">
                    <BeenhereIcon className={classes.icon}/>
                    </span>
                        <span className="menu-text">Test</span>
                        <i className="menu-arrow"/>
                    </NavLink>
                    <div className="menu-submenu ">
                        <i className="menu-arrow"/>
                        <ul className="menu-subnav">
                            <li className="menu-item  menu-item-parent" aria-haspopup="true">
                    <span className="menu-link">
                    <span className="menu-text">Test</span>
                    </span>
                            </li>

                            {/*begin::2 Level*/}
                            {check_role(['test/create']) ? (
                            <li
                                className={`menu-item ${getMenuItemActive("/test/create")}`}
                                aria-haspopup="true"
                            >
                                <NavLink className="menu-link" to="/test/create">
                                    <i className="menu-bullet menu-bullet-dot">
                                        <span/>
                                    </i>
                                    <span className="menu-text">Add New</span>
                                </NavLink>
                            </li>
                            ) : ''}
                            {/*end::2 Level*/}

                            {/*begin::2 Level*/}
                            {check_role(['test/list']) ? (
                            <li
                                className={`menu-item ${getMenuItemActive("/test/list")}`}
                                aria-haspopup="true"
                            >
                                <NavLink className="menu-link" to="/test/list">
                                    <i className="menu-bullet menu-bullet-dot">
                                        <span/>
                                    </i>
                                    <span className="menu-text">List</span>
                                </NavLink>
                            </li>
                            ) : ''}
                            {/*end::2 Level*/}

                        </ul>
                    </div>
                </li>
                ) : ''}
                {/*end::1 Level*/}

                {/*begin::1 Level*/}

                <li className="menu-section ">
                    <h4 className="menu-text">Lab Setup</h4>
                    <i className="menu-icon flaticon-more-v2"></i>
                </li>
                {check_role(['lab/edit']) ? (
                <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                        "/supplier", true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                >
                    <NavLink className="menu-link menu-toggle" to="/lab">
                    <span className="svg-icon svg-icon-lg">
                    <AccountBalanceIcon className={classes.icon}/>
                    </span>
                        <span className="menu-text">Lab</span>
                        <i className="menu-arrow"/>
                    </NavLink>
                    <div className="menu-submenu ">
                        <i className="menu-arrow"/>
                        <ul className="menu-subnav">
                            <li className="menu-item  menu-item-parent" aria-haspopup="true">
                    <span className="menu-link">
                    <span className="menu-text">Lab</span>
                    </span>
                            </li>

                            {/*begin::2 Level*/}
                            <li
                                className={`menu-item ${getMenuItemActive("/lab/edit/1")}`}
                                aria-haspopup="true"
                            >
                                <NavLink className="menu-link" to="/lab/edit/1">
                                    <i className="menu-bullet menu-bullet-dot">
                                        <span/>
                                    </i>
                                    <span className="menu-text">Setup Organization Data</span>
                                </NavLink>
                            </li>
                            {/*end::2 Level*/}

                        </ul>
                    </div>
                </li>
                ) : ''}
                {/*end::1 Level*/}

                {/*begin::1 Level*/}
                {check_role(['collection-center/create','collection-center/list']) ? (
                <li

                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                        "/collection-center", true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                >
                    <NavLink className="menu-link menu-toggle" to="/collection-center">
                    <span className="svg-icon svg-icon-lg">
                    <ApartmentIcon className={classes.icon}/>
                    </span>
                        <span className="menu-text">Collection Center</span>
                        <i className="menu-arrow"/>
                    </NavLink>
                    <div className="menu-submenu ">
                        <i className="menu-arrow"/>
                        <ul className="menu-subnav">
                            <li className="menu-item  menu-item-parent" aria-haspopup="true">
                    <span className="menu-link">
                    <span className="menu-text">Collection Center</span>
                    </span>
                            </li>

                            {/*begin::2 Level*/}
                            {check_role(['collection-center/create']) ? (
                            <li
                                className={`menu-item ${getMenuItemActive("/collection-center/create")}`}
                                aria-haspopup="true"
                            >
                                <NavLink className="menu-link" to="/collection-center/create">
                                    <i className="menu-bullet menu-bullet-dot">
                                        <span/>
                                    </i>
                                    <span className="menu-text">Add New</span>
                                </NavLink>
                            </li>
                            ) : ''}
                            {/*end::2 Level*/}

                            {/*begin::2 Level*/}
                            {check_role(['collection-center/list']) ? (
                            <li
                                className={`menu-item ${getMenuItemActive("/collection-center/list")}`}
                                aria-haspopup="true"
                            >
                                <NavLink className="menu-link" to="/collection-center/list">
                                    <i className="menu-bullet menu-bullet-dot">
                                        <span/>
                                    </i>
                                    <span className="menu-text">List</span>
                                </NavLink>
                            </li>
                                ) : ''}
                            {/*end::2 Level*/}

                        </ul>
                    </div>
                </li>
                ) : ''}
                {/*end::1 Level*/}

                {/*begin::1 Level*/}
                {check_role(['reference/create','reference/list']) ? (
                <li

                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                        "/reference", true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                >
                    <NavLink className="menu-link menu-toggle" to="/reference">
                    <span className="svg-icon svg-icon-lg">
                    <PeopleAltIcon className={classes.icon}/>
                    </span>
                        <span className="menu-text">Reference</span>
                        <i className="menu-arrow"/>
                    </NavLink>
                    <div className="menu-submenu ">
                        <i className="menu-arrow"/>
                        <ul className="menu-subnav">
                            <li className="menu-item  menu-item-parent" aria-haspopup="true">
                    <span className="menu-link">
                    <span className="menu-text">Reference</span>
                    </span>
                            </li>

                            {/*begin::2 Level*/}
                            {check_role(['reference/create']) ? (
                            <li
                                className={`menu-item ${getMenuItemActive("/reference/create")}`}
                                aria-haspopup="true"
                            >
                                <NavLink className="menu-link" to="/reference/create">
                                    <i className="menu-bullet menu-bullet-dot">
                                        <span/>
                                    </i>
                                    <span className="menu-text">Add New</span>
                                </NavLink>
                            </li>
                            ) : ''}
                            {/*end::2 Level*/}

                            {/*begin::2 Level*/}
                            {check_role(['reference/list']) ? (
                            <li
                                className={`menu-item ${getMenuItemActive("/reference/list")}`}
                                aria-haspopup="true"
                            >
                                <NavLink className="menu-link" to="/reference/list">
                                    <i className="menu-bullet menu-bullet-dot">
                                        <span/>
                                    </i>
                                    <span className="menu-text">List</span>
                                </NavLink>
                            </li>
                                ) : ''}
                            {/*end::2 Level*/}

                        </ul>
                    </div>
                </li>
                ) : ''}
                {/*end::1 Level*/}

            </ul>

            {/* end::Menu Nav */}
        </>
    );
}
